
export default {

    setFinanceiroStore (state, payload){
        state.coach.financeiro = payload
    },
    setConfigStore (state, payload){
        state.coach.config = payload
    },
    setAlertasStore (state, payload){
        state.coach.alertas = payload
    },
    setMensagensStore (state, payload){
        state.coach.mensagens = payload
    },
    setComplementarStore (state, payload){
        state.coach.complementar = payload
    },
    setExperienciasStore (state, payload){
        state.coach.experiencias = payload
    },
    setFormacaoStore (state, payload){
        state.coach.formacao = payload
    }

    
}