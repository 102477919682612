<template>
  <div>
    <ComponentLoadingPage v-bind:isLoading="isLoading" />
    <ComponentNavBar />
    <!--  BEGIN NAVBAR  -->
    <ComponentNavBar2 :local="local" />
    <!--  END NAVBAR  -->

    <!--  BEGIN MAIN CONTAINER  -->
    <div class="main-container" id="container">
      <div class="overlay"></div>
      <div class="search-overlay"></div>

      <ComponentMenu />

      <!--  BEGIN CONTENT AREA  -->
      <div id="content" class="main-content">
        <div class="layout-px-spacing">
          <div class="row" id="cancel-row">
            <div class="col-lg-12 layout-spacing layout-top-spacing">
              <div class="row mb-3">
                <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                  <div class="widget widget-card-four">
                    <div class="widget-content">
                      <div class="w-content">
                        <div class="">
                          <div class="w-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                              class="feather feather-user-check">
                              <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                              <circle cx="8.5" cy="7" r="4"></circle>
                              <polyline points="17 11 19 13 23 9"></polyline>
                            </svg>
                          </div>
                        </div>
                        <div class="w-info">
                          <h6 class="value">{{ indicadores.ativos }}</h6>
                          <p class="">Cliente(s)</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                  <div class="widget widget-card-four">
                    <div class="widget-content">
                      <div class="w-content">
                        <div class="">
                          <div class="w-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                              class="feather feather-refresh-cw">
                              <polyline points="23 4 23 10 17 10"></polyline>
                              <polyline points="1 20 1 14 7 14"></polyline>
                              <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
                            </svg>
                          </div>
                        </div>
                        <div class="w-info">
                          <h6 class="value">0</h6>
                          <p class="">Em processo</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                  <div class="widget widget-card-four">
                    <div class="widget-content">
                      <div class="w-content">
                        <div class="">
                          <div class="w-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                              stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                              class="feather feather-check-circle">
                              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                              <polyline points="22 4 12 14.01 9 11.01"></polyline>
                            </svg>
                          </div>
                        </div>
                        <div class="w-info">
                          <h6 class="value">0</h6>
                          <p class="">Atendidos</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                  <div class="widget widget-card-four">
                    <div class="widget-content">
                      <div class="w-content">
                        <div class="">
                          <div class="w-icon">
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                              stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                              class="feather feather-users">
                              <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                              <circle cx="9" cy="7" r="4"></circle>
                              <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                              <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                            </svg>
                          </div>
                        </div>
                        <div class="w-info">
                          <h6 class="value">0</h6>
                          <p class="">Em grupo</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="statbox widget box box-shadow">
                <div style="padding-left: 15px; padding-right: 15px" class="widget-header">
                  <div class="row">
                    <div class="col-12 mb-2">
                      <h4 style="
                          padding: 0px;
                          margin-left: 20px;
                          margin-top: 20px;
                        ">
                        Meus Clientes
                      </h4>
                      <hr style="margin-bottom: 0px" />
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-12 mb-2">
                      <button style="margin-top: 7px; margin-right: 10px" @click="newCliente" class="btn btn-primary">
                        <i class="fa fa-user-plus" aria-hidden="true"></i> Novo
                        Cliente
                      </button>
                      <button style="margin-top: 7px; margin-right: 10px" @click="openModalLocalizar"
                        class="btn btn-info">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        Localizar
                      </button>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <input type="text" maxlength="250" placeholder="Localizar na tabela..."
                        class="form-control form-control-sm input-search-dataTable" v-model="search_value_table" />
                      <div class="table-responsive mb-4">
                        <easy-data-table :language="customLanguage" :search-value="search_value_table"
                          :search-field="searchField" :headers="headers" :items="lista_cliente"
                          :key="lista_cliente.codigo" buttons-pagination>
                          <template #item-operation="item">
                            <div class="operation-wrapper">
                              <i style="cursor: pointer" @click="deleteExperiencia(item)"
                                class="fas fa-paperclip fa-md text-info" v-tippy="'Perfil'"></i>
                              <i style="cursor: pointer" @click="deleteExperiencia(item)"
                                class="fas fa-trash fa-md text-danger" v-tippy="'Excluir'"></i>
                              <i style="cursor: pointer" @click="showAcesso(item)" class="fas fa-key fa-md text-danger"
                                v-tippy="'Bloquear/Desbloquear Acesso'"></i>
                            </div>
                          </template>
                          <template #item-avisos="item">
                            <div class="operation-wrapper">
                              <i v-if="item.ind_ativo == 0" class="fas fa-exclamation-circle fa-md text-warning"
                                v-tippy="'Bloqueado'"></i>
                            </div>
                          </template>
                        </easy-data-table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="card"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br /><br /><br />
        <ComponentFooter />
      </div>

      <div class="modal" role="dialog" id="novoCliente" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">Novo Cliente</h5>
            </div>
            <div class="modal-body">
              <form-wizard back-button-text="Voltar" ref="formWizard" @on-complete="onComplete" @on-change="nextStep"
                next-button-text="Próximo" finish-button-text="Finalizar" color="#1b55e2">
                <tab-content title="Tipo">
                  <div class="row mb-3">
                    <div class="col-6">
                      <div class="infobox-3" style="width: 95%">
                        <div class="info-icon" style="background-color: #4f5055">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-user">
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                          </svg>
                        </div>
                        <div class="n-chk">
                          <label class="new-control new-radio radio-success">
                            <input type="radio" class="new-control-input" value="pf" name="tipo_cliente"
                              :id="tipo_cliente" checked />
                            <span class="new-control-indicator"></span>
                            <h5 class="info-heading">Pessoa Física</h5>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="infobox-3" style="width: 95%">
                        <div class="info-icon" style="background-color: #4f5055">
                          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                            class="feather feather-briefcase">
                            <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
                            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                          </svg>
                        </div>

                        <div class="n-chk">
                          <label class="new-control new-radio radio-success">
                            <input type="radio" class="new-control-input" value="pj" name="tipo_cliente"
                              :id="tipo_cliente" />
                            <span class="new-control-indicator"></span>
                            <h5 class="info-heading">Pessoa Juridica</h5>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </tab-content>
                <tab-content title="Informações Adicionais">
                  <div class="row">
                    <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label v-if="cliente.tipo == 'pf'">Nome Completo
                          <small class="text-danger">*</small></label>
                        <label v-else>Nome Fantasia
                          <small class="text-danger">*</small></label>
                        <input type="text" maxlength="250" class="form-control form-control-sm" v-model="cliente.nome" />
                        <ComponenteValidacao :errorsValidation="v$.cliente.nome.$errors" />
                      </div>
                    </div>
                    <div v-if="cliente.tipo == 'pj'" class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label>Razão Social
                          <small class="text-danger">*</small></label>

                        <input type="text" maxlength="250" class="form-control form-control-sm"
                          v-model="cliente.razao_social" />
                        <ComponenteValidacao :errorsValidation="v$.cliente.razao_social.$errors" />
                      </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label v-if="cliente.tipo == 'pf'">CPF <small class="text-danger">*</small></label>
                        <label v-else>CNPJ <small class="text-danger">*</small></label>
                        <input v-if="cliente.tipo == 'pf'" type="text" maxlength="14" v-mask="'000.000.000-00'"
                          class="form-control form-control-sm" v-model="cliente.cpf_cnpj" />
                        <input v-else type="text" maxlength="18" v-mask="'00.000.000/0000-00'"
                          class="form-control form-control-sm" v-model="cliente.cpf_cnpj" />
                        <ComponenteValidacao :errorsValidation="v$.cliente.cpf_cnpj.$errors" />
                      </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label>E-mail <small class="text-danger">*</small></label>
                        <input type="email" class="form-control form-control-sm" v-model="cliente.email" />
                        <ComponenteValidacao :errorsValidation="v$.cliente.email.$errors" />
                      </div>
                    </div>

                    <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label>Senha <small class="text-danger">*</small></label>
                        <br />Momentaneamente, a senha será o
                        {{ cliente.tipo == "pj" ? "CNPJ" : "CPF" }}
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label>Telefone <small class="text-danger">*</small></label>
                        <input type="text" class="form-control form-control-sm" v-model="cliente.telefone" maxlength="16"
                          placeholder="( )    -    " v-mask="'(00) 00000-0000'" />
                        <ComponenteValidacao :errorsValidation="v$.cliente.telefone.$errors" />
                      </div>
                    </div>
                    <div v-if="cliente.tipo == 'pf'" class="col-xl-8 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label>Empresa
                          <i class="fa fa-info-circle text-info" v-tippy="'As empresas são os cadastros dos seus clientes PJ, que podem ser vinculados aos clientes PF. Essa relação poderá ser utilizada quando você criar um processo em grupo.'
                            "></i></label>
                        <Select2 :class="empresa" ref="select2_empresa" id="empresa" :settings="settingsSelectEmpresa"
                          v-model="cliente.empresa" :options="lista_empresas" />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label><b>Atenção!</b> As informações do Cliente só poderão
                        ser alteradas pelo Cliente, através do seu
                        Perfil.</label>
                    </div>
                  </div>
                </tab-content>
              </form-wizard>
            </div>
            <div class="modal-footer">
              <button class="btn" data-dismiss="modal">
                <i class="flaticon-cancel-12"></i> Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="modal  fade" id="modalFiltro" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-right modal-dialog  modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Localizar Clientes
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label>Nome ou Razão Social</label>
                    <input type="text" class="form-control form-control-sm" v-model="localizar.nome_razao" />
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <label>E-mail</label>
                    <input type="email" class="form-control form-control-sm" v-model="localizar.email" />
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group">
                    <label>CPF ou CNPJ</label>
                    <input type="text" maxlength="15" v-mask="'00000000000000'" class="form-control form-control-sm"
                      v-model="localizar.cpf_cnpj" />
                    <small>Sem pontuação</small>
                  </div>
                </div>
              </div>
            </div>
          <div class="modal-footer">
            <button @click="newLocalizar" class="btn btn-primary btn-block">
              <i class="flaticon-cancel-12"></i> Localizar
            </button>
            <button @click="clearFiltro" class="btn  btn-block">
              <i class="flaticon-cancel-12"></i> Limpar
            </button>
          </div>
        </div>
      </div>
    </div>

     
    </div>
  </div>
</template>

<style scoped></style>

<script>
import ComponentMenu from "@/modules/coach/components/menu/ComponentMenu.vue";
import ComponentNavBar from "@/modules/coach/components/navbar/ComponentNavBar.vue";
import ComponentNavBar2 from "@/modules/coach/components/navbar/ComponentNavBar2.vue";
import ComponentFooter from "@/modules/coach/components/footer/ComponentFooter.vue";

import moment from "moment";
//import Select2 from "vue3-select2-component";

// Validação
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, email } from "@vuelidate/validators";
import ComponenteValidacao from "@/components/validacaoInput/ComponentValidacao";

// Loading
import ComponentLoadingPage from "@/components/loading/ComponentLoadingPage.vue";

import mensagem from "@/components/validacaoInput/mensagem";
import Swal from "sweetalert2";
import { toast, alert } from "@/components/sweetAlert/index";

import {
  store,
  getCliente,
  getIndicadorCliente,
  updateAcesso,
} from "../clienteController";

import("@/assets/scss/app.css");

import { FormWizard, TabContent } from "vue3-form-wizard";
import "vue3-form-wizard/dist/style.css";

import("../css/index.css");

export default {
  name: "CadastroClienteView",

  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  validations: {
    cliente: {
      razao_social: {
        required: helpers.withMessage(mensagem.required, required),
      },
      nome: {
        required: helpers.withMessage(mensagem.required, required),
      },
      email: {
        required: helpers.withMessage(mensagem.required, required),
        email: helpers.withMessage(mensagem.email, email),
      },
      cpf_cnpj: {
        required: helpers.withMessage(mensagem.required, required),
      },
      telefone: {
        required: helpers.withMessage(mensagem.required, required),
      },
    },
  },

  mounted() { },

  created() {
    this.init();
    this.localizarInicial = { ...this.localizar };
 
  },

  methods: {
    onComplete() {
      this.store();
    },
    nextStep(prevIndex, nextIndex) {
      if (nextIndex == 1) {
        // Verifica qual tipo de cliente
        var radioButtons = document.getElementsByName("tipo_cliente");
        var tipo = null;
        for (var i = 0; i < radioButtons.length; i++) {
          if (radioButtons[i].checked) {
            tipo = radioButtons[i].value;
            break;
          }
        }

        if (tipo == "pj") {
          this.v$.cliente.$reset();
          this.cliente = Object.assign({}, this.cliente_base);
          this.cliente.tipo = tipo;
        } else {
          this.v$.cliente.$reset();
          this.cliente = Object.assign({}, this.cliente_base);
          this.cliente.tipo = tipo;
          this.cliente.razao_social = "nenhum";
        }
      } else {
        this.cliente = Object.assign({}, this.cliente_base);
      }
    },

    optionExists(options, searchTerm) {
      for (let i = 0; i < options.length; i++) {
        if (options[i].text.toLowerCase().includes(searchTerm.toLowerCase())) {
          return true; // A opção já existe
        }
      }
      return false; // A opção não existe
    },

    newCliente() {
      this.$refs.formWizard.reset();
      this.cliente = Object.assign({}, this.cliente_base);
      // eslint-disable-next-line
      $("#novoCliente").modal("show");
    },

    clearFiltro(){
      this.localizar = { ...this.localizarInicial };
    },

    openModalLocalizar() {
      // eslint-disable-next-line
      $("#modalFiltro").modal("show");
    },

    async newLocalizar() {
      // eslint-disable-next-line
      $("#modalFiltro").modal("hide");
      await this.getCliente(this.localizar)
    },

    showAcesso(cliente) {
      var action = "Bloquear";
      if (cliente.ind_ativo == 0) {
        action = "Desbloquear";
      }

      Swal.fire({
        title: "Deseja " + action + " esse Cliente?",
        text: "\n\n\n\n",
        icon: "info",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#3085d6",
        reverseButtons: true,
        confirmButtonText: action,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.isLoading = true;
          const response = await updateAcesso(cliente);
          if (response && response.response && response.response.data) {
            toast(response.response.data.message, "error");
            this.isLoading = false;
            return;
          }
          alert(response.message, "\n\n\n", "success");
          cliente.ind_ativo = !cliente.ind_ativo;
          this.isLoading = false;

          var cliente_update = this.lista_cliente.find((x) => {
            return x.codigo == cliente.codigo;
          });

          if (cliente_update) {
            cliente_update.ind_ativo = !cliente_update.ind_ativo;
          }
        }
      });
    },

    async init() {
      var filtro = {
        limit : 300,
      }
      this.getCliente(filtro);
      this.getIndicadorCliente();
    },

    async getIndicadorCliente() {
      this.isLoading = true;
      const response = await getIndicadorCliente();
      if (response && response.response && response.response.data) {
        this.isLoading = false;

        return;
      }
      this.indicadores.ativos = response.data.ativos;
    },

    async getCliente(filtro = null) {
     
      this.isLoading = true;
      const response = await getCliente(filtro);

      if (response && response.response && response.response.data) {
        toast(response.response.data.message, "error");
        this.isLoading = false;
        return;
      }

      this.isLoading = false;
      this.lista_cliente = response.data.cliente;
      this.setListaEmpresa(this.lista_cliente);
    },

    setListaEmpresa(lista_cliente) {
      if (!lista_cliente) {
        return;
      }

      const empresas = lista_cliente.filter(function (objeto) {
        return objeto.tipo_cliente === "J";
      });

      this.lista_empresas = [];
      this.lista_empresas.push({
        id: "0",
        text: "Nenhuma",
      });
      empresas.forEach((empresa) => {
        this.lista_empresas.push({
          id: empresa.id,
          text: empresa.razao_social,
        });
      });
    },

    async store() {
      const result = await this.v$.cliente.$validate();
      if (!result) {
        return;
      }

      this.isLoading = true;
      const response = await store(this.cliente);

      if (response && response.response && response.response.data) {
        toast(response.response.data.message, "error");
        this.isLoading = false;
        return;
      }

      this.isLoading = false;
      // eslint-disable-next-line
      $("#novoCliente").modal("hide");

      Swal.fire({
        title: "Cliente cadastrado com sucesso",
        text: "\n\n\n\n",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Continuar",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.getCliente({limit : 300});
          this.getIndicadorCliente();
          return true;
        }
      });
    },
  },

  computed: {},
  data() {
    const vm = this;
    return {
      settingsSelectEmpresa: {
        width: "100%",
        selectionCssClass: "select_empresa",
        ajax: {
          type: "POST",
          delay: 400,
          dataType: "json",
          data: (params) => {
            return {
              filtro: {
                nome_razao: params.term,
                tipo: "J",
              },
            };
          },
          processResults: function (data) {
            var result = [];
            if (data.data.cliente) {
              data.data.cliente.forEach((cliente) => {
                result.push({
                  id: cliente.id,
                  text: cliente.razao_social || cliente.text,
                });
              });
            }
            return {
              results: result,
            };
          },
          transport: async function (params, success, failure) {
            if (!params.data.filtro.nome_razao) {
              var cliente = JSON.parse(JSON.stringify(vm.lista_empresas));
              var data = {
                cliente: cliente,
              };
              success({
                data: data,
              });
            } else {
              await getCliente(params.data.filtro).then(success).catch(failure);
            }
          },
        },
      },

      search_value_table: "",
      searchField: ["nome", "email", "cpf_cnpj", "razao_social"],
      headers: [
        { text: "", value: "operation", width: 70 },
        { text: "Alertas", value: "avisos", width: 70 },
        { text: "Nome", value: "nome", sortable: true },
        { text: "Razão Social", value: "razao_social", sortable: true },
        { text: "CPF/CNPJ", value: "cpf_cnpj", sortable: true },
        { text: "Tipo", value: "tipo_cliente", sortable: true },
        { text: "E-mail", value: "email", sortable: true },
        { text: "Telefone", value: "telefone", sortable: true },
        { text: "Empresa", value: "nome_empresa", sortable: true },
      ],
      empresa: null,
      lista_cliente: [],
      lista_empresas: [],
      local: "Coach | Meus Clientes",
      moment: moment,
      isLoading: false,
      indicadores: {
        ativos: 0,
        no_processo: 0,
        atendidos: 0,
      },
      cliente_base: {
        empresa: null,
        razao_social: "",
        nome: "",
        email: "",
        cpf_cnpj: "",
        telefone: "",
      },
      cliente: {
        empresa: null,
        razao_social: "",
        nome: "",
        email: "",
        cpf_cnpj: "",
        telefone: "",
      },

      localizar: {
        nome_razao: "",
        cpf_cnpj: "",
        email: "",
        tipo: null,
      },
    };
  },

  components: {
    ComponentMenu,
    ComponentNavBar,
    ComponentNavBar2,
    ComponentFooter,
    // Select2,
    ComponentLoadingPage,
    ComponenteValidacao,
    FormWizard,
    TabContent,
  },
};
</script>
  