<template>
  <ComponentLoadingPage v-bind:isLoading="isLoading" />
  <div style="background-color: white;" class="form-container">
    <div class="form-form">
      <div class="form-form-wrap">
        <div class="form-container">
          <div style="margin-top: 80px;" class="form-content">
            <h6 class="">Plataforma </h6>
            <h1 class="">TecnoCoaching <a href=""></a></h1>
            <br>

            <div class="form mb-3 text-left">

              <b>Que bom ver você por aqui!</b><br><br>
              Estamos felizes que você irá se juntar aos milhares de Coaches que utilizam
              nossa plataforma.
              <br> <br>
              Saiba que você irá ganhar <b> 7 dias de acesso grátis</b>, assim você conhece tudo o que
              ela tem a oferecer, sem compromisso e letras miúdas. <br> Temos certeza que você irá amar.
              <br> <br>

              Para continuar com o seu cadastro, clique no botão abaixo e siga as instruções.
              <br> É sem complicação. Fácil e rápido.

            </div>
            <div class="field-wrapper text-center">
              <button @click="openModalCoach()" class="btn btn-primary">Iniciar meu
                cadastro</button>
            </div>

            <br><br>
          </div>
        </div>
      </div>

      <div class="modal" id="novoCoach" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">

            <div class="modal-body">
              <form-wizard back-button-text="Voltar" ref="formWizard" @on-complete="onComplete" @on-change="nextStep"
                next-button-text="Próximo!" finish-button-text="Finalizar" color="#1b55e2">
                <tab-content title="Tipo">
                  <div class="row mb-3">
                    <div class="col-6">
                      <div class="infobox-3" style="width: 95%;">
                        <div class="info-icon" style="background-color: #4f5055;"><svg xmlns="http://www.w3.org/2000/svg"
                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                          </svg></div>
                        <div class="n-chk">
                          <label class="new-control new-radio radio-success">
                            <input type="radio" class="new-control-input" value="pf" name="tipo_coach" :id="tipo_coach"
                              checked>
                            <span class="new-control-indicator"></span>
                            <h5 class="info-heading"> Pessoa Física</h5>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="infobox-3" style="width: 95%;">
                        <div class="info-icon" style="background-color: #4f5055;"><svg xmlns="http://www.w3.org/2000/svg"
                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round" class="feather feather-briefcase">
                            <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
                            <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                          </svg></div>

                        <div class="n-chk">
                          <label class="new-control new-radio radio-success">
                            <input type="radio" class="new-control-input" value="pj" name="tipo_coach" :id="tipo_coach">
                            <span class="new-control-indicator"></span>
                            <h5 class="info-heading"> Pessoa Juridica</h5>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                </tab-content>
                <tab-content title="Informações Adicionais">
                  <div class="row">
                    <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label v-if="coach.tipo == 'pf'">Nome Completo <small class="text-danger">*</small></label>
                        <label v-else>Nome Fantasia <small class="text-danger">*</small></label>
                        <input type="text" maxlength="250" class="form-control form-control-sm" v-model="coach.nome" />
                        <ComponenteValidacao :errorsValidation="v$.coach.nome.$errors" />
                      </div>
                    </div>
                    <div v-if="coach.tipo == 'pj'" class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label>Razão Social <small class="text-danger">*</small></label>
                        <input type="text" maxlength="250" class="form-control form-control-sm"
                          v-model="coach.razao_social" />
                        <ComponenteValidacao :errorsValidation="v$.coach.razao_social.$errors" />
                      </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label v-if="coach.tipo == 'pf'">CPF <small class="text-danger">*</small></label>
                        <label v-else>CNPJ <small class="text-danger">*</small></label>
                        <input v-if="coach.tipo == 'pf'" type="text" maxlength="14" v-mask="'000.000.000-00'"
                          class="form-control form-control-sm" v-model="coach.cpf_cnpj" />
                        <input v-else type="text" maxlength="18" v-mask="'00.000.000/0000-00'"
                          class="form-control form-control-sm" v-model="coach.cpf_cnpj" />
                        <ComponenteValidacao :errorsValidation="v$.coach.cpf_cnpj.$errors" />
                      </div>
                    </div>
                    <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">

                      <div class="form-group">
                        <label>E-mail <small class="text-danger">*</small></label>
                        <input type="email" class="form-control form-control-sm" v-model="coach.email" />
                        <ComponenteValidacao :errorsValidation="v$.coach.email.$errors" />
                      </div>
                    </div>

                    <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label>Senha <small class="text-danger">*</small></label>
                        <input type="password" class="form-control form-control-sm" v-model="coach.password" maxlength="" />
                        <ComponenteValidacao :errorsValidation="v$.coach.password.$errors" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label>Telefone <small class="text-danger">*</small></label>
                        <input type="text" class="form-control form-control-sm" v-model="coach.telefone" maxlength="16"
                          placeholder="( )    -    " v-mask="'(00) 00000-0000'" />
                        <ComponenteValidacao :errorsValidation="v$.coach.telefone.$errors" />
                      </div>
                    </div>
                    <div class="col-xl-4 col-md-6 col-sm-6 col-xs-12">
                      <div class="form-group">
                        <label>Código Afiliado </label>
                        <input type="numer" class="form-control form-control-sm" v-model="coach.codigo_afiliado"
                          maxlength="8" v-mask="'00000000'" />

                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label><b>Atenção!</b> As informações fiscais não poderão ser alteradas posteriormente. Verifique se
                        as informações fornecidas estão corretas.
                      </label>
                    </div>
                  </div>
                </tab-content>
              </form-wizard>
            </div>
            <div class="modal-footer">
              <button class="btn" data-dismiss="modal">
                <i class="flaticon-cancel-12"></i> Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-image">
      <img style="margin-top: 70px; max-width:1000px" src="assets/img/new_account.jpg">
    </div>
  </div>
</template>
<script>

import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";

import ComponenteValidacao from "@/components/validacaoInput/ComponentValidacao";
import mensagem from "@/components/validacaoInput/mensagem";

// Loading
import ComponentLoadingPage from '@/components/loading/ComponentLoadingPage.vue';

import { FormWizard, TabContent } from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'

import { newCoach } from '../coachController';

import { toast } from "@/components/sweetAlert/index";
import Swal from "sweetalert2";

import router from "@/router";

import '../css/cadastro.css';

export default {
  name: "CadastroView",

  setup() {
    return { v$: useVuelidate() };
  },

  validations: {

    coach: {
      email: {
        required: helpers.withMessage(mensagem.required, required),
        email: helpers.withMessage(mensagem.email, email),
      },
      password: {
        required: helpers.withMessage(mensagem.required, required),
      },
      nome: {
        required: helpers.withMessage(mensagem.required, required),
      },
      razao_social: {
        required: helpers.withMessage(mensagem.required, required),
      },
      telefone: {
        required: helpers.withMessage(mensagem.required, required),
      },
      cpf_cnpj: {
        required: helpers.withMessage(mensagem.required, required),
      },

    }

  },

  methods: {
    onComplete() {
       this.newCoach();
    },

    nextStep(prevIndex, nextIndex) {

      if (nextIndex == 1) {
        // Verifica qual tipo de cliente
        var radioButtons = document.getElementsByName("tipo_coach");
        var tipo = null;
        for (var i = 0; i < radioButtons.length; i++) {
          if (radioButtons[i].checked) {
            tipo = radioButtons[i].value;
            break
          }
        }

        if (tipo == 'pj') {
          this.v$.coach.$reset();
          this.coach = Object.assign({}, this.coach_base);
          this.coach.tipo = tipo;
        } else {
          this.v$.coach.$reset();
          this.coach = Object.assign({}, this.coach_base);
          this.coach.tipo = tipo;
          this.coach.razao_social = 'nenhum';
        }
      } else {
        this.coach = Object.assign({}, this.coach_base);
      }
    },


    openModalCoach() {

      this.$refs.formWizard.reset();
      this.coach = Object.assign({}, this.coach_base);
      // eslint-disable-next-line
      $("#novoCoach").modal("show");
    },

    async newCoach() {
      const result = await this.v$.coach.$validate();
      if (!result) {
          return;
      }
      this.isLoading = true;
      const response = await newCoach(this.coach);
      if (response && response.response && response.response.data) {
        toast(response.response.data.message, 'error');
        this.isLoading = false;
        return
      }
      this.isLoading = false;
      // eslint-disable-next-line
      $("#novoCoach").modal("hide");

      Swal.fire({
        title: 'Eba!',
        text: "Seu cadastro foi realizado com sucesso.",
        icon: "success",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Realizar Login",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          router.push('/coach/login');
          return true;
        }
      });
    }
  },

  data() {
    return {

      coach: {
        email: "",
        password: "",
        nome: "",
        razao_social: "",
        telefone: "",
        codigo_afiliado: 0,
      },
      coach_base: {
        email: "",
        password: "",
        nome: "",
        razao_social: "",
        telefone: "",
        codigo_afiliado: 0,
      },

      isLoading: false
    };
  },

  mounted() {


  },

  components: {
    ComponenteValidacao,
    ComponentLoadingPage,
    FormWizard,
    TabContent
  },

};
</script>
