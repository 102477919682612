<template>
    <div>
        <ComponentLoadingPage :message="message_loading" v-bind:isLoading="isLoading" />
        <div class="modal" role="dialog" id="novoProcesso" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Novo Processo</h5>
                    </div>
                    <div class="modal-body">
                        <form-wizard back-button-text="Voltar" ref="formWizard" @on-load="onLoad" @on-validate="onValidate"
                            @on-complete="onComplete" next-button-text="Próximo" @on-change="nextStep"
                            finish-button-text="Finalizar" color="#1b55e2">
                            <tab-content title="Tipo do processo" ::before-change="validarStep1">
                                <div class="row mb-3">
                                    <h5>Qual o tipo de processo você deseja criar?</h5>
                                </div>
                                <div class="row mb-3">

                                    <div class="col-xl-6 col-md-6 col-sm-12 mb-2">
                                        <div class="infobox-3" style="width: 95%">
                                            <div class="info-icon" style="background-color:rgb(36, 98, 190)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-user">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg>
                                            </div>
                                            <div class="n-chk">
                                                <label class="new-control new-radio radio-success">
                                                    <input type="radio" @click="changeTipoProcesso" v-model="processo.tipo"
                                                        class="new-control-input" value="I" :id="tipo" checked />
                                                    <span class="new-control-indicator"></span>
                                                    <h5 class="info-heading">Individual <i class="fa fa-info-circle "
                                                            v-tippy="'Processo individuais será específico para apenas 1 cliente. Seja ela pessoa Jurídica ou Física.'
                                                                "></i></h5>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                   <div class="col-xl-6 col-md-6 col-sm-12 mb-2">
                                        <div class="infobox-3" style="width: 95%">
                                            <div class="info-icon" style="background-color: rgb(36, 98, 190)">
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                    stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                    stroke-linejoin="round" class="feather feather-users">
                                                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="9" cy="7" r="4"></circle>
                                                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                                                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                                </svg>
                                            </div>

                                            <div class="n-chk">
                                                <label class="new-control new-radio radio-success">
                                                    <input type="radio" @click="changeTipoProcesso" v-model="processo.tipo"
                                                        class="new-control-input" value="G" :id="tipo" />
                                                    <span class="new-control-indicator"></span>
                                                    <h5 class="info-heading">Em Grupo <i class="fa fa-info-circle" v-tippy="'Processo em grupo será o mesmo processo para vários clientes. Você poderá selecionar os clientes individualmente ou selecionar uma empresa. Assim todos os clientes associados a ela (ou parte dela) participarão do processo.'
                                                        "></i></h5>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </tab-content>
                            <tab-content title="Informações Adicionais" :before-change="validarStep2">
                                <div class="row mb-3">
                                    <label> Tipo de processo escolhido: <h v-if="processo.tipo == 'I'">Individual</h>
                                        <h v-else>Em grupo</h>
                                    </label>
                                </div>
                                <div v-if="processo.tipo == 'G'" class="row mb-3">
                                     <div class="col-xl-6 col-md-6 col-sm-12 mb-2">
                                        <div class="infobox-3" style="width: 95%">
                                            <div class="info-icon" style="background-color: rgb(36, 98, 190)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-user">
                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                    <circle cx="12" cy="7" r="4"></circle>
                                                </svg>
                                            </div>
                                            <div class="n-chk">
                                                <label class="new-control new-radio radio-success">
                                                    <input type="radio" @click="selectTipoGrupoProcesso"
                                                        v-model="processo.tipo_grupo" class="new-control-input" value="C" />
                                                    <span class="new-control-indicator"></span>
                                                    <h5 class="info-heading">Grupo de Clientes <i class="fa fa-info-circle "
                                                            v-tippy="'Você poderá adicionar qualquer cliente'
                                                                "></i></h5>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                     <div class="col-xl-6 col-md-6 col-sm-12 mb-2">
                                        <div class="infobox-3" style="width: 95%">
                                            <div class="info-icon" style="background-color: rgb(36, 98, 190)">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                    viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                    stroke-linecap="round" stroke-linejoin="round"
                                                    class="feather feather-briefcase">
                                                    <rect x="2" y="7" width="20" height="14" rx="2" ry="2"></rect>
                                                    <path d="M16 21V5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v16"></path>
                                                </svg>
                                            </div>
                                            <div class="n-chk">
                                                <label class="new-control new-radio radio-success">
                                                    <input type="radio" @click="selectTipoGrupoProcesso"
                                                        v-model="processo.tipo_grupo" class="new-control-input" value="E" />
                                                    <span class="new-control-indicator"></span>
                                                    <h5 class="info-heading">Por Empresa <i class="fa fa-info-circle"
                                                            v-tippy="'Você só poderá adicionar os clientes associados a uma determinada empresa.'
                                                                "></i></h5>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <label><b>Importante!</b> Processos em grupo ou individuais, as
                                        ferramentas, sessões (e seus horários) e tarefas serão igualmente para todos os
                                        clientes
                                        selecionados.</label>
                                </div>
                            </tab-content>
                            <tab-content title="Finalizar">

                                <div class="row">
                                    <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label>Nome
                                                <small class="text-danger">*</small></label>
                                            <input type="text" maxlength="250" class="form-control form-control-sm"
                                                v-model="processo.nome" />
                                            <ComponenteValidacao :errorsValidation="v$.processo.nome.$errors" />
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-md-4 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label>Data Início
                                                <small class="text-danger">*</small></label>
                                            <input type="datetime-local" class="form-control form-control-sm"
                                                v-model="processo.data_inicio" />
                                            <ComponenteValidacao :errorsValidation="v$.processo.data_inicio.$errors" />
                                        </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">

                                        <div class="form-group">
                                            <label>Frequência
                                                <small class="text-danger">*</small></label>
                                            <Select2 :settings="settingsSelectEmpresa" ref="frequencia"
                                                v-model="processo.frequencia" :options="lista_frequencia" />
                                            <ComponenteValidacao :errorsValidation="v$.processo.frequencia.$errors" />

                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-md-4 col-sm-6 col-xs-12">
                                        <div class="form-group">
                                            <label>Sessões
                                                <small class="text-danger">*</small></label>
                                            <input type="text" v-mask="'00'" maxlength="2" class="form-control form-control-sm"
                                                v-model.number="processo.sessoes" />
                                            <ComponenteValidacao :errorsValidation="v$.processo.sessoes.$errors" />
                                        </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Objetivo
                                                <small class="text-danger">*</small></label>
                                            <input type="text" maxlength="250" class="form-control form-control-sm"
                                                v-model="processo.objetivo" />
                                            <ComponenteValidacao :errorsValidation="v$.processo.objetivo.$errors" />
                                        </div>
                                    </div>
                                </div>
                                <div v-if="processo.tipo == 'G' && processo.tipo_grupo == 'E'" class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Empresa
                                                <small class="text-danger">*</small></label>
                                            <Select2 @select="getClientesEmpresa" :settings="settingsSelectEmpresa"
                                                ref="empresa" v-model="processo.empresa" :options="lista_empresas" />
                                            <ComponenteValidacao :errorsValidation="v$.processo.empresa.$errors" />

                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Cliente(s)
                                                <small class="text-danger">*</small></label>
                                            <Select2 :settings="settingsSelectClientes" @select="selectClienteSelect"
                                                ref="cliente" v-model="processo.clientes" :options="lista_clientes" />
                                            <ComponenteValidacao :errorsValidation="v$.processo.clientes.$errors" />
                                        </div>
                                    </div>
                                </div>
                            </tab-content>
                        </form-wizard>
                    </div>
                    <div class="modal-footer">
                        <button @click="save" class="btn" data-dismiss="modal">
                            <i class="flaticon-cancel-12"></i> Cancelar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

// Loading
import ComponentLoadingPage from "@/components/loading/ComponentLoadingPage.vue";

import { toast, alert } from "@/components/sweetAlert/index";

//Wizard
import { FormWizard, TabContent } from "vue3-form-wizard";
import "vue3-form-wizard/dist/style.css";
// Validação
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, requiredIf, between, integer } from "@vuelidate/validators";
import ComponenteValidacao from "@/components/validacaoInput/ComponentValidacao";
import mensagem from "@/components/validacaoInput/mensagem";

import { getCliente, getFrequencia, storeProcesso } from "../../processoController"

export default {
    name: "ComponentModalNovo",

    props: {
        processoProp: {
            type: Object,
            required: true
        },
    },
    setup() {
        return {
            v$: useVuelidate(),
        };
    },

 
    validations: {
        processo: {
            clientes: {
                required: helpers.withMessage(mensagem.required, required),
            },
            nome: {
                required: helpers.withMessage(mensagem.required, required),
            },
            objetivo: {
                required: helpers.withMessage(mensagem.required, required),
            },
            sessoes: {
                required: helpers.withMessage(mensagem.required, required),
                integer: helpers.withMessage('Sessões devem ser um número inteiro.', integer),
                between: helpers.withMessage('Sessões devem estar entre 1 e 99.', between(1, 99)),
            },
            
            data_inicio: {
                required: helpers.withMessage(mensagem.required, required),
            },
            frequencia: {
                required: helpers.withMessage(mensagem.required, required),
            },
            empresa: {
                required: helpers.withMessage(mensagem.required,
                    requiredIf(function () {
                        return (this.tipo == 'G' && this.tipo_grupo != 'C')
                    })
                )
            },
        },
    },

    mounted() { },

    created() {
        this.init();
    },

    methods: {

        async store() {
            const result = await this.v$.processo.$validate();
            if (!result) {
                return;
            }
            let dados = this.processo;
            this.isLoading = true;
            const response = await storeProcesso(dados);
            if ((response && response.data && response.data.erro) || response.status == 'error' || (response && response.response && response.status != 200)) {
                toast(response.response.data.message || response.message, "error");
                this.isLoading = false;
                return;
            }
            this.isLoading = false;
            dados = null;
            alert(response.message, '', 'success');
            this.$emit('atualizarTabela');
            this.$emit('atualizarIndicador');
            // eslint-disable-next-line
            $("#novoProcesso").modal("hide");

        },

        onComplete() {
            this.store();
        },

        validarStep2() {
            if (this.processo.tipo == 'G' && this.processo.tipo_grupo == '') {
                toast('Infome o tipo do Grupo', 'error')
                return false
            } else if ((this.processo.tipo == 'G' && this.processo.tipo_grupo == 'C') || this.processo.tipo == 'I') {
                this.lista_clientes = this.lista_clientes_bk;
                return true
            } else {
                this.lista_clientes = [];
                return true
            }

        },
        validarStep1() {
            if (this.processo.tipo == '') {
                toast('Informe o tipo do processo', 'error')
                return false
            } else {
                return true
            }
        },

        changeTipoProcesso() {
            this.processo = { ...this.processo_base };
            this.$refs.formWizard.reset();
            this.v$.processo.$reset();
        },

        nextStep(prevIndex, nextIndex) {
            // Se for individual, ele acaba removendo qualquer informação do tipo do grupo
            if (nextIndex == 1 && this.processo.tipo == 'I') {
                this.processo.tipo_grupo == ''
            }
        },

        async getClientesEmpresa({ id }) {
            var filtro = {
                ativo: 1,
                tipo_query: 'select',
                empresa: id
            }
            // Pega apenas os clientes ativos
            this.isLoading = true;
            this.message_loading = 'Localizando Clientes...'
            const response = await getCliente(filtro);

            if (response && response.response && response.response.data) {
                toast(response.response.data.message, "error");
                this.isLoading = false;
                return;
            }
            this.message_loading = 'Aguarde...'
            this.isLoading = false;
            this.lista_clientes = response.data.cliente;
        },

        novoProcesso() {
            this.processo = { ...this.processo_base };
            this.$refs.formWizard.reset();
            this.v$.processo.$reset();
            // eslint-disable-next-line
            $("#novoProcesso").modal("show");

        },

        selectTipoGrupoProcesso() {
            this.processo.clientes = [];
            this.processo.empresa = 0;
            this.v$.processo.$reset();
        },

        selectClienteSelect() {
            if (this.processo.tipo == 'I' && this.processo.clientes.length >= 2) {
                alert('Processo Individual', 'No processo individual só é permitido adicionar 1 Cliente.', 'error')
                this.processo.clientes.splice(1, 1);
            }
        },

        async init() {
            this.isLoading = true;
            var filtro = {
                ativo: 1,
                tipo_query: 'select',
            }
            // Pega apenas os clientes ativos
            this.getCliente(filtro);
            filtro = null;
            filtro = {
                ativo: 1,
                tipo: 'J',
                tipo_query: 'select',
            }
            // Pega apenas os clientes ativos e Juridica
            this.getEmpresa(filtro);
        },

        async getCliente(filtro = null) {
            this.isLoading = true;
            const response = await getCliente(filtro);
            if (response && response.response && response.response.data) {
                toast(response.response.data.message, "error");
                this.isLoading = false;
                return;
            }
            this.isLoading = false;
            this.lista_clientes = response.data.cliente;
            this.lista_clientes_bk = response.data.cliente;
        },

        async getEmpresa(filtro = null) {
            this.isLoading = true;
            const response = await getCliente(filtro);
            if (response && response.response && response.response.data) {
                toast(response.response.data.message, "error");
                this.isLoading = false;
                return;
            }
            this.isLoading = false;
            this.lista_empresas = response.data.cliente;
        },

    },

    data() {

        return {
            message_loading: 'Aguarde...',
            processo: this.processoProp,
            processo_base: this.processoProp,
            lista_clientes: [],
            lista_empresas: [],
            lista_clientes_bk: [],
            settingsSelectClientes: {
                width: '100%',
                multiple: true
            },
            settingsSelectEmpresa: {
                width: '100%',

            },
            isLoading: false,
            lista_frequencia: getFrequencia()
        }

    },
    components: {
        FormWizard,
        TabContent,
        ComponentLoadingPage,
        ComponenteValidacao
    }

}



</script>