import state from './state';

export function getFinanceiro() {
    return state.coach.financeiro;
}

export function getConfig() {
    return state.coach.config;
}

export const getAlertas = () => {
    return state.coach.alertas;
}

/* export function getAlertas(){
    return state.coach.alertas;
} */

export function getMensagens() {
    return state.coach.mensagens;
}

export function getComplementar() {
    return state.coach.complementar;
}

export function getExperiencias() {
    return state.coach.experiencias;
}

export function getFormacao() {
    return state.coach.formacao;
}

export function geCoach() {
    return state.coach;
}