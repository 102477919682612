<template>
  <div>
    <ComponentLoadingPage v-bind:isLoading="isLoading" />
    <ComponentNavBar />
    <!--  BEGIN NAVBAR  -->
    <ComponentNavBar2 :local="local" />
    <!--  END NAVBAR  -->

    <!--  BEGIN MAIN CONTAINER  -->
    <div class="main-container" id="container">
      <div class="overlay"></div>
      <div class="search-overlay"></div>

      <ComponentMenu />

      <!--  BEGIN CONTENT AREA  -->
      <div id="content" class="main-content">
        <div class="layout-px-spacing">
          <div class="row" id="cancel-row">
            <div class="col-lg-12 layout-spacing layout-top-spacing">
              <div class="row mb-3">
                
                <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                  <div class="widget widget-card-four">
                    <div class="widget-content">
                      <div class="w-content">
                        <div class="">
                          <div class="w-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-settings"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                          </div>
                        </div>
                        <div class="w-info">
                          <h6 class="value">{{ indicador_total }}</h6>
                          <p class="">Ferrramentas</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="statbox widget box box-shadow">
                <div style="padding-left: 15px; padding-right: 15px" class="widget-header">
                  <div class="row">
                    <div class="col-12 mb-2">
                      <h4 style="
                          padding: 0px;
                          margin-left: 20px;
                          margin-top: 20px;
                        ">
                        Minhas Ferramentas
                      </h4>
                      <hr style="margin-bottom: 0px" />
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 mb-2">
                      <ComponentTableSearch :itensProp="this.lista_ferramenta" />
                    </div>
                  </div>


                  <div class="row mb-2">
                    <div class="col-12 mb-2">
                     
                    </div>
                  </div>

                 
                </div>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="card"></div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <br /><br /><br />
        <ComponentFooter />
      </div>

    </div>
  </div>
</template>


<script>
import ComponentMenu from "@/modules/coach/components/menu/ComponentMenu.vue";
import ComponentNavBar from "@/modules/coach/components/navbar/ComponentNavBar.vue";
import ComponentNavBar2 from "@/modules/coach/components/navbar/ComponentNavBar2.vue";
import ComponentFooter from "@/modules/coach/components/footer/ComponentFooter.vue";

import moment from "moment";

import ComponentTableSearch from "@/components/tableSearch/ComponetTableSearch.vue";

// Loading
import ComponentLoadingPage from "@/components/loading/ComponentLoadingPage.vue";
import {getFerramentas, getIndicadorFerramenta} from "../ferramentaController"

import("@/assets/scss/app.css");

//import { FormWizard, TabContent } from "vue3-form-wizard";
import "vue3-form-wizard/dist/style.css";
import { toast } from "@/components/sweetAlert/index";
import('../css/index.css')


export default {
  name: "FerramentaView",


  created() {
    this.init();
  },

  methods: {

    async init() {
     
      this.getFerramenta();
      this.getIndicadorFerramenta();
    },

    async getFerramenta() {
     
     this.isLoading = true;
     const response = await getFerramentas();

     if (response && response.response && response.response.data) {
       toast(response.response.data.message, "error");
       this.isLoading = false;
       return;
     }
     this.isLoading = false;
     this.lista_ferramenta = response.data.ferramenta;
    
   },

   async getIndicadorFerramenta() {
      this.isLoading = true;
      const response = await getIndicadorFerramenta();
      if (response && response.response && response.response.data) {
        this.isLoading = false;

        return;
      }
      this.indicador_total = response.data.total;
    },


  },

  computed: {},
  data() {
    return {
      local: "Coach | Minhas Ferramentas",
      moment: moment,
      isLoading: false,
      lista_ferramenta: [],
      indicador_total: 0
     
      
    }
  },

  components: {
    ComponentMenu,
    ComponentNavBar,
    ComponentNavBar2,
    ComponentFooter,
    ComponentLoadingPage,
    ComponentTableSearch
  },
};
</script>
  