import http from '@/components/http';

function getFrequencia() {
    return [
        { id: 1, text: '1 - Diário' },
        { id: 2, text: '2 - Semanal' },
        { id: 3, text: '3 - Quinzenal' },
        { id: 4, text: '4 - Mensal' },
        { id: 5, text: '5 - Trimestral' },
        { id: 6, text: '6 - Semestral' },
        { id: 7, text: '7 - Anual' },
    ]
}

async function getCliente(filtro) {
    try {
        const response = await http.post('/coach/cliente', filtro);
        if (response && response.data && response.status == 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

async function storeProcesso(data) {
    try {
        const response = await http.post('/processo', data)
        if (response && response.data && response.status == 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

async function getProcesso(filtro) {
    try {
        const response = await http.post('/processo/getProcesso', filtro)
        if (response && response.data && response.status == 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

async function getIndicador() {
    try {
        const response = await http.get('/processo/indicador')
        if (response && response.data && response.status == 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

async function getShowProcesso(codigo) {
    try {
        const response = await http.post('/processo/show', codigo)
        if (response && response.data && response.status == 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}


async function updateProcesso(update) {
    try {
        const response = await http.post('/processo/update', update)
        if (response && response.data && response.status == 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

async function removerCliente(dados) {
    try {
        const response = await http.post('/processo/remove-cliente', dados)
        if (response && response.data && response.status == 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

async function adicionarCliente(dados) {
    try {
        const response = await http.post('/processo/adicionar-cliente', dados)
        if (response && response.data && response.status == 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}


async function adicionarLembrete(dados) {
    try {
        const response = await http.post('/processo/lembrete/adicionar', dados)
        if (response && response.data && response.status == 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}


async function removerLembrete(dados) {
    try {
        const response = await http.post('/processo/lembrete/remover', dados)
        if (response && response.data && response.status == 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}



export {
    getCliente,
    getFrequencia,
    storeProcesso,
    getProcesso,
    getIndicador,
    getShowProcesso,
    updateProcesso,
    removerCliente,
    adicionarCliente,
    adicionarLembrete,
    removerLembrete
}