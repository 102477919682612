<template>
  <ComponentLoadingPage :message="messageLoading"  v-bind:isLoading="isLoading" />
  <div style="background-color: white;" class="form-container">
    <div class="form-form">
      <div class="form-form-wrap">
        <div class="form-container">
          <div style="margin-top: 80px;" class="form-content">
            <h6 class="">Plataforma </h6>
            <h1 class="">TecnoCoaching <a href=""></a></h1>
            <br><br>
            <form  class="text-left">
              <div class="form">
                <div id="username-field" class="field-wrapper input">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-user">
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                  <input v-model="email" type="e-mail" class="form-control" id="email" placeholder="E-mail" />
                  <ComponenteValidacao :errorsValidation="v$.email.$errors" />
                </div>

                <div id="password-field" class="field-wrapper input mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-lock">
                    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
                    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                  </svg>
                  <input type="password" class="form-control" v-model="password" id="password" placeholder="Senha" />
                  <ComponenteValidacao :errorsValidation="v$.password.$errors" />
                </div>
                <div class="d-sm-flex justify-content-between mb-4">
                  
                  <div class="field-wrapper">
                    <button type="submit" @click.prevent="submitForm" class="btn btn-primary" value="">Acessar</button>
                  </div>
                </div>
                <br>
                <div class="field-wrapper">
                 <a href="auth_pass_recovery.html" class="forgot-pass-link">Esqueceu sua senha?</a>
                 <br>
                  <p class="signup-link">Novo por aqui?  <router-link :to="{ path: '/coach/cadastro' }">Criar minha conta</router-link></p>
                </div>
                
                
              </div>
            </form>
            <br><br>
          </div>
        </div>
      </div>
    </div>
    <div class="form-image">
      <img style="margin-top: 70px;" src="assets/img/login.png">
    </div>
  </div>
</template>
<script>

import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";

import ComponenteValidacao from "@/components/validacaoInput/ComponentValidacao";
import mensagem from "@/components/validacaoInput/mensagem";

import { loginCoach } from '../authController'
import { deleteLocalStorage } from "@/modules/auth/authController";

// Loading
import ComponentLoadingPage from '@/components/loading/ComponentLoadingPage.vue'

import '../../auth/style.css'

export default {
  name: "LoginCoachView",

  setup() {
    return { v$: useVuelidate() };
  },

  validations: {
    email: {
      required: helpers.withMessage(mensagem.required, required),
      email: helpers.withMessage(mensagem.email, email),
    },
    password: {
      required: helpers.withMessage(mensagem.required, required),
    },
  },

  methods: {
    async submitForm() {

      const result = await this.v$.$validate();
      if (!result) {
        // notify user form is invalid
        return;
      }

      const form = {
        email: this.email,
        password: this.password
      }

      this.isLoading = true;
      loginCoach(form).then(()=>{
        this.isLoading = false;
      })
     

    },
  },

  data() {
    return {
      email: "",
      password: "",
      isLoading: false,
      messageLoading: 'Verificando credenciais...'
    };
  },

  mounted() {

    deleteLocalStorage();
  },

  components: {
    ComponenteValidacao,
    ComponentLoadingPage
  },

};
</script>

