<template>
  <div>
    <ComponentLoadingPage :message="message_loading" v-bind:isLoading="isLoading" />
    <div class="row" id="">
      <div v-if="ferramenta.codigo" class="col-12">
        <div class="statbox widget box box-shadow">
          <div class="widget-header" style="padding-left: 15px; padding-right: 15px">
            <div class="row">
              <div class="col-12 mb-2 mt-2">
                <h4 v-if="ferramenta.tipo.length">
                  Atividades adicionadas nessa ferramenta
                </h4>
                <h4 v-else class="text-center">
                  Nenhuma atividade de ferramenta adicionada nesta ferramenta.
                </h4>
              </div>
            </div>
            <div class="col-12 mb-2">
              <div v-for="tipo in ferramenta.tipo" :key="tipo">
                <div v-if="tipo.tipo == 1 && tipo.ativo">
                  <div class="mb-2"
                    style="background-color: #fff;    /* border-radius: 6px; */    border: 1px solid #e0e6ed;    padding: 14px 26px;">
                    <div class="row">
                      <div
                        v-bind:class="{ 'col-xl-4 col-12': !['text_longo', 'range'].includes(tipo.campo.formato.tipo), 'col-12': ['text_longo', 'range'].includes(tipo.campo.formato.tipo) }">
                        <div class="form-group">
                          <label class="mb-2"><b>{{ tipo.campo.pergunta }}</b></label>
                          <input class="form-control form-control-sm" v-if="tipo.campo.formato.tipo == 'date'"
                            type="date">
                          <input class="form-control form-control-sm"
                            v-if="tipo.campo.formato.tipo == 'text' && tipo.campo.formato.id == 1" type="text">
                          <Select2 v-if="tipo.campo.formato.tipo == 'select'"
                            :settings="{ width: '100%', multiple: (tipo.campo.multiple == '1') ? true : false }"
                            :options="tipo.campo.options_object" />
                          <textarea v-if="tipo.campo.formato.tipo == 'text_longo'"
                            class="form-control form-control-sm" />
                          <div v-if="tipo.campo.formato.tipo == 'checkbox'">
                            <div v-for="option in tipo.campo.options" :key="option" class="">
                              <label class="new-control new-checkbox checkbox-primary mb-1">
                                <input type="checkbox" class="new-control-input">
                                <span class="new-control-indicator"></span>{{ option }}
                              </label>
                            </div>
                          </div>
                          <div v-if="tipo.campo.formato.tipo == 'radio'">
                            <div v-for="option in tipo.campo.options" :key="option" class="">
                              <label class="new-control new-radio new-radio-text radio-primary mb-1">
                                <input type="radio" class="new-control-input" name="custom-radio-4">
                                <span class="new-control-indicator"></span><span class="new-radio-content">{{ option
                                  }}</span>
                              </label>
                            </div>
                          </div>
                          <div v-if="tipo.campo.formato.tipo == 'range'">
                            <div class="custom-progress bottom-right progress-down" style="width: 100%">
                              <input type="range" :min="tipo.campo.config.valor_inicial"
                                :max="tipo.campo.config.valor_final" class="custom-range progress-range-counter"
                                :step="0.5" value="0">
                                                <div class="range-count"><span class="range-count-number" data-rangecountnumber="0">{{
                        tipo.campo.config.valor_inicial }}</span> <span class="range-count-unit">{{
                        tipo.campo.config.identificador }}</span></div>
                            </div>
                          </div>
                          <div v-if="tipo.campo.formato.tipo == 'monetario'">
                            <div class="input-group">
                              <div class="input-group-append">
                                <span class="input-group-text" id="basic-addon6">{{ tipo.campo.config.identificador
                                  }}</span>
                              </div>
                              <input type="number" class="form-control" aria-describedby="basic-addon2">
                            </div>
                          </div>
                          <div v-if="tipo.campo.formato.tipo == 'number'">
                            <div class="input-group">
                              <input type="number" class="form-control" aria-describedby="basic-addon2">
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-2">
                      <div class="col-12">
                        <button @click="removerAtividadeFerramenta(tipo)"
                          class="badge badge-danger btn-bagde">Remover</button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="tipo.tipo == 2 && tipo.ativo">
                  <div class="row mb-3">
                    <div class="col-12">
                      <label class="mb-2"><b>{{ tipo.config.descricao }}</b></label>
                      <apexchart width="400" type="polarArea" ref="chartPolarArea" :options="tipo.chartOptions"
                        :series="tipo.chartOptions.series"></apexchart>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col-12">
                      <button @click="removerAtividadeFerramenta(tipo)"
                        class="badge badge-danger btn-bagde">Remover</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3"></div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<style scoped>
.btn-bagde {
  border: 0;
}
</style>

<script>
// Loading
import ComponentLoadingPage from "@/components/loading/ComponentLoadingPage.vue";
import "vue3-form-wizard/dist/style.css";
// Validação
//import { useVuelidate } from "@vuelidate/core";
//import { required, helpers, requiredIf, between, integer } from "@vuelidate/validators";
//import ComponenteValidacao from "@/components/validacaoInput/ComponentValidacao";
//import mensagem from "@/components/validacaoInput/mensagem";
import moment from "moment";
import { Apexchart } from 'vue3-apexcharts';
import Swal from "sweetalert2";
import { toast } from "@/components/sweetAlert/index";
import { removeAtividadeFerramenta } from '../../ferramentaController';
export default {
  name: "ComponentLista",

  props: {
    ferramentaProp: {
      type: Object,
      required: true,
    },
  },

  created() { },

  methods: {
    removerAtividadeFerramenta(campo) {

      console.log(campo)

      if (!campo.codigo && !campo.id) {
        toast('Dados necessários não informados', "error");
      }

      Swal.fire({
        title: "Deseja remover essa atividade?",
        text: "",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#3085d6",
        reverseButtons: true,
        confirmButtonText: 'Remover',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {

          let dados = {
            codigo: campo.codigo,
            id: campo.id
          }
          this.isLoading = true;
          const response = await removeAtividadeFerramenta(dados);

          if (response && response.response && response.response.data) {
            toast(response.response.data.message, "error");
            this.isLoading = false;
            return;
          }

          campo.ativo = 0;
          this.isLoading = false;
          toast("Removido com sucesso", "success");
        }
      });
    },

  },

  data() {
    return {
      moment,
      ferramenta: this.ferramentaProp,
      isLoading: false,

    };
  },
  components: {
    ComponentLoadingPage,
    apexchart: Apexchart
  },
};
</script>
