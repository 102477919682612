<template>
    <div>
        <ComponentLoadingPage v-bind:isLoading="isLoading" />
        <div class="widget-content searchable-container list">
            <div class="row">
                <div class="col-xl-4 col-lg-5 col-md-5 col-sm-7 filtered-list-search layout-spacing align-self-center">
                    <form class="form-inline my-2 my-lg-0">
                        <div class="">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-search">
                                <circle cx="11" cy="11" r="8"></circle>
                                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                            </svg>
                            <input @input="search" type="text" class="form-control product-search" v-model="text_search"
                                placeholder="Localizar Ferramenta">
                        </div>
                    </form>
                </div>
                <div
                    class="col-xl-8 col-lg-7 col-md-7 col-sm-5 text-sm-right text-center layout-spacing align-self-center">
                    <div class="d-flex justify-content-sm-end justify-content-center">
                        <button style="margin-top: 7px; margin-right: 10px" class="btn btn-primary">
                            <i class="fa fa-cogs" aria-hidden="true"></i><router-link style="color:white"
                                :to="{ path: '/coach/ferramenta/cadastro' }">
                                Nova Ferramenta
                            </router-link>
                        </button>
                    </div>
                </div>
            </div>

            <div class="searchable-items list">
                <div class="items items-header-section">
                </div>
                <div class="items" v-for="(item, index) in itens" :key="index">
                    <div v-if="item.ativo == 1" class="item-content">
                        <div class="col-4">
                            <div class="user-profile">

                                <div style="align-items: center;" class="w-icon mr-2 d-flex"><svg
                                        xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-settings">
                                        <circle cx="12" cy="12" r="3"></circle>
                                        <path
                                            d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
                                        </path>
                                    </svg></div>
                                <div class="user-meta-info">
                                    <p class="user-name" data-name="">{{ item.titulo }}</p>
                                    <p class="user-work" data-occupation="">{{ item.subtitulo }}</p>
                                </div>
                            </div>
                        </div>
                        <div class="user-meta-info col-4">
                            <p class="user-work">{{ item.descricao }}</p>
                        </div>
                        <div class="user-meta-info col-1">
                            <p class="user-work">{{ item.qtd_atividade }} Ativ.</p>
                        </div>
                        <div class="user-meta-info col-2">
                            <p class="user-work">{{ item.tipo }}</p>
                        </div>
                        <div class="action-btn">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-edit-2 edit">
                                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                            </svg>
                            <svg @click="remover(item)"  xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                stroke-linejoin="round" class="feather feather-user-minus delete">
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path
                                    d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2">
                                </path>
                            </svg>
                           
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import ComponentLoadingPage from "@/components/loading/ComponentLoadingPage.vue";
import('../tableSearch/style.css');
import Swal from "sweetalert2";
import { removeFerramenta } from '../../modules/coach/ferramenta/ferramentaController';
import { toast } from "@/components/sweetAlert/index";
export default {
    name: "ComponentTableSearch",
    watch: {
        itensProp: {
            deep: true,
            handler(newConfig) {
                this.itens = newConfig;
                this.itens_bk = newConfig;
            }
        },

    },
    props: {
        itensProp: {
            type: Array,
            required: true,
        },
        isLoadingProp: {
            type: Array,
            required: false,
        },
    },
    data() {
        return {
            text_search: '',
            itens: this.itensProp || [],
            cabecalho: this.cabecalhoProp || [],
            itens_bk: [...this.itensProp],
            isLoading: false
        }
    },

    created() {

    },

    methods: {
        search() {
            if (!this.itens_bk.length) {
                return
            }
            if (!this.text_search) {
                this.itens = this.itens_bk; return;
            }
            this.itens = this.itens_bk;
            const query = this.text_search.toLowerCase();
            this.itens = this.itens.filter(item => {
                return (
                    (item.titulo.toLowerCase().includes(query) ||
                        item.subtitulo.toLowerCase().includes(query) ||
                        item.descricao.toLowerCase().includes(query) ||
                        item.tipo.toLowerCase().includes(query)) && item.ativo == 1
                );
            });
        },

        remover(ferramenta) {
            if (!ferramenta.codigo && !ferramenta.id) {
                toast('Dados necessários não informados', "error");
                return
            }

            if (ferramenta.grupo_codigo == '2') {
                toast('Não é possível remover ferramenta do tipo MODELO', "error");
                return
            }

            Swal.fire({
                title: "Deseja remover essa Ferramenta?",
                text: "Essa ferramenta será removida e não poderá ser utilizada novamente.",
                icon: "question",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonColor: "#3085d6",
                reverseButtons: true,
                confirmButtonText: 'Remover',
                allowOutsideClick: false,
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let dados = {
                        codigo: ferramenta.codigo,
                        id: ferramenta.id
                    }
                    this.isLoading = true;
                    const response = await removeFerramenta(dados);

                    if (response && response.response && response.response.data) {
                        toast(response.response.data.message, "error");
                        this.isLoading = false;
                        return;
                    }

                    ferramenta.ativo = 0;
                    this.isLoading = false;
                    toast("Removido com sucesso", "success");
                }
            });
        },
    },

    components:{
        ComponentLoadingPage
    }
}
</script>
