<template>
    <div class="row mb-3">
        <div class="col-xl-4 col-md-6 col-sm-12 mb-2">
            <div class="widget widget-card-four">
                <div class="widget-content">
                    <h4 class="info-heading text-center">Processos</h4>
                    <div class="w-content">
                        <div class="">
                            <div class="w-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-refresh-cw">
                                    <polyline points="23 4 23 10 17 10"></polyline>
                                    <polyline points="1 20 1 14 7 14"></polyline>
                                    <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
                                </svg>
                            </div>
                        </div>
                        <div class="col-5" style="background-color: #eaeffb;border-radius: 10px ">
                            <div class="w-info text-center mt-2 mb-2">
                                <h6 class="value">{{ this.indicador.individual_aberto }}</h6>
                                <p class="">Ativos</p>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="w-info text-center mt-2 mb-2">
                                <h6 class="value">{{ this.indicador.individual_finalizado }}</h6>
                                <h class="text-success-top ">Finalizados</h>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-xl-4 col-md-6 col-sm-12 mb-2">
            <div class="widget widget-card-four">
                <div class="widget-content">
                    <h4 class="text-center info-heading">Processos em Grupo</h4>
                    <div class="w-content">
                        <div class="">
                            <div class="w-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-refresh-cw">
                                    <polyline points="23 4 23 10 17 10"></polyline>
                                    <polyline points="1 20 1 14 7 14"></polyline>
                                    <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15"></path>
                                </svg>
                            </div>
                        </div>
                        <div class="col-5" style="background-color: #eaeffb;border-radius: 10px ">
                            <div class="w-info text-center mt-2 mb-2">
                                <h6 class="value">{{ this.indicador.grupo_aberto }}</h6>
                                <p class="">Ativos</p>
                            </div>
                        </div>
                        <div class="col-5">
                            <div class="w-info text-center mt-2 mb-2">
                                <h6 class="value">{{ this.indicador.grupo_finalizado }}</h6>
                                <h class="text-success-top ">Finalizados</h>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { getIndicador } from '../../processoController'
import { toast } from "@/components/sweetAlert/index";

export default {
    name: "ComponentIndicador",

    setup() {
        return {
        };
    },
    mounted() { },

    created() {
        this.getIndicador();
    },

    methods: {
        async getIndicador() {
            let response = await getIndicador();
            if ((response && response.data && response.data.erro) || response.status == 'error' || (response && response.response && response.status != 200)) {
                toast(response.response.data.message || response.message, "error");
                this.isLoading = false;
                return;
            }
            if (response.data.indicador) {
                this.indicador = response.data.indicador[0];
            }
        }
    },

    data() {
        return {
            indicador: {
                grupo_aberto: 0,
                grupo_finalizado: 0,
                individual_aberto: 0,
                individual_finalizado: 0
            }
        }
    }
}

</script>