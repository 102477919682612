import axios from 'axios';

var production = true;

const http = axios.create({
    baseURL: (production) ? 'https://servicestecnocoaching.com.br/index.php/api/v1/' : 'http://127.0.0.1:8000/api/v1/',
 
});

http.interceptors.request.use(function(config) {
    config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('_App_Token');
    return config;
});

export default http;