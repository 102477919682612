//import store from '@/store'
import http from '@/components/http';
//import axios from 'axios';



async function store(cliente) {
    try {

        const response = await http.post('/coach/cliente/register', cliente);
        if (response && response.data && response.status == 200) {
            return response.data;
        }

    } catch (error) {
        return error;
    }
}

async function getCliente(filtro) {
    try {

        const response = await http.post('/coach/cliente', filtro);
        if (response && response.data && response.status == 200) {
            return response.data;
        }

    } catch (error) {
        return error;
    }
}

async function getIndicadorCliente() {
    try {
        const response = await http.get('/coach/cliente/indicador');
        if (response && response.data && response.status == 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

async function updateAcesso(cliente) {
    try {
        var update = {
            codigo: cliente.codigo,
            novo_indicador: (cliente.ind_ativo == false ? 1 : 0),
            email: cliente.email
        }
        
        const response = await http.post('/coach/cliente/acesso', update);
        if (response && response.data && response.status == 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}


export {

    store,
    getCliente,
    getIndicadorCliente,
    updateAcesso


}