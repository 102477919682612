<template>
  <div>
    <ComponentLoadingPage :isLoading="this.isLoading" />
    <ComponentNavBar />
    <!--  BEGIN NAVBAR  -->
    <ComponentNavBar2 :local="this.local" />
    <!--  END NAVBAR  -->

    <!--  BEGIN MAIN CONTAINER  -->
    <div class="main-container" id="container">
      <div class="overlay"></div>
      <div class="search-overlay"></div>

      <ComponentMenu />

      <!--  BEGIN CONTENT AREA  -->
      <div id="content" class="main-content">
        <div class="layout-px-spacing">
          <div class="row" id="cancel-row">
            <div class="col-lg-12 layout-spacing layout-top-spacing">
              <div class="statbox widget box box-shadow">
                <div style="padding-left: 15px; padding-right: 15px" class="widget-header">
                  <div class="row">
                    <div class="col-12">
                     
                    </div>
                  </div>
                </div>
                <br><br><br><br>
              </div>
            </div>
          </div>

        </div>


        <ComponentFooter />

      </div>
    </div>
  </div>
</template>


<script>
import ComponentMenu from "@/modules/coach/components/menu/ComponentMenu.vue";
import ComponentNavBar from "@/modules/coach/components/navbar/ComponentNavBar.vue";
import ComponentNavBar2 from "@/modules/coach/components/navbar/ComponentNavBar2.vue";
import ComponentFooter from "@/modules/coach/components/footer/ComponentFooter.vue";

import moment from "moment";

// Loading
import ComponentLoadingPage from "@/components/loading/ComponentLoadingPage.vue";


// import mensagem from "@/components/validacaoInput/mensagem";
import Swal from "sweetalert2";
import { toast } from "@/components/sweetAlert/index";
import { useRouter, useRoute } from 'vue-router'
import {getSessao} from '../sessaoController';



import("@/assets/scss/app.css");

//import { FormWizard, TabContent } from "vue3-form-wizard";
import "vue3-form-wizard/dist/style.css";


export default {
  name: "SessaoShowView",

  created() {
    
  },

  methods: {


    async getSessao() {
      if (!this.route.params.sessao_codigo && !this.route.params.processo_codigo) {
        this.$router.go(-1)
        return
      }

      this.isLoading = true;
      const response = await getSessao({ sessao_codigo: this.route.params.sessao_codigo,  processo_codigo: this.route.params.processo_codigo,  });
      if ((response && response.data && response.data.erro) || response.status == 'error' || (response && response.response && response.status != 200)) {
        toast(response.response.data.message || response.message, "error");
        this.isLoading = false;

        Swal.fire({
          title: "Sessão não localizada",
          html: "Esse processo não foi encontrada em nossa base de dados. Tente novamente",
          icon: "error",

          confirmButtonColor: "#3085d6",
          reverseButtons: true,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.$router.go(-1);
          }
        });
        return
      }

     

      this.isLoading = false;
    },

    

  },



  data() {

    return {
      local: "Coach | Visualizar Sessão ",
      moment,
      isLoading: false,
      sessao: null,
      router: useRouter(),
      route: useRoute()
    }


  },



  components: {
    ComponentMenu,
    ComponentNavBar,
    ComponentNavBar2,
    ComponentFooter,
    ComponentLoadingPage,
   
  },
};
</script>
  