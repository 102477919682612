
import http from '@/components/http';


async function storeFerramenta(ferramenta) {
    try {

        const response = await http.post('/ferramenta/', ferramenta);
        if (response && response.data && response.status == 200) {
            return response.data;
        }

    } catch (error) {
        return error;
    }
}


async function updateFerramenta(dados) {
    try {
    
        const response = await http.post('/ferramenta/update', dados);
        if (response && response.data && response.status == 200) {
            return response.data;
        }

    } catch (error) {
        return error;
    }
}

async function getFerramentas() {
    try {
        const response = await http.get('/ferramenta');
        if (response && response.data && response.status == 200) {
            return response.data;
        }

    } catch (error) {
        return error;
    }
}


async function getIndicadorFerramenta() {
    try {
        const response = await http.get('/ferramenta/get-indicador');
        if (response && response.data && response.status == 200) {
            return response.data;
        }

    } catch (error) {
        return error;
    }

}


async function removeFerramenta(dados) {
    try {
        const response = await http.post('/ferramenta/remove',dados);
        if (response && response.data && response.status == 200) {
            return response.data;
        }

    } catch (error) {
        return error;
    }
}

async function storeTipoFerramenta(dados) {
    try {
        const response = await http.post('/tipo-ferramenta', dados);
        if (response && response.data && response.status == 200) {
            return response.data;
        }

    } catch (error) {
        return error;
    }
}

async function getCamposQuestionario() {
    try {
        const response = await http.get('/tipo-ferramenta/campos-questionario');
        if (response && response.data && response.status == 200) {
            return response.data;
        }

    } catch (error) {
        return error;
    }
}

async function removeAtividadeFerramenta(dados) {
    try {
        const response = await http.post('/tipo-ferramenta/remove-atividade',dados);
        if (response && response.data && response.status == 200) {
            return response.data;
        }

    } catch (error) {
        return error;
    }
}


export {

    storeFerramenta,
    updateFerramenta,
    storeTipoFerramenta,
    getCamposQuestionario,
    removeAtividadeFerramenta,
    getFerramentas,
    getIndicadorFerramenta,
    removeFerramenta
}