<template>
  <div class="col-12 mb-3">
    <div v-if="pendencia" class="col-12">
      <br />
      <div class="alert alert-danger mb-4" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-x close" data-dismiss="alert">
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
        <strong>Atenção!</strong> Existem pendências no seu cadastro. Acesse seu
        perfil para regularizar
      </div>
    </div>
  </div>
</template>

<script>
import { getAlertasCoach } from "@/modules/coach/coach/coachController";
import { getAlertas } from "@/modules/coach/coach/store/getters";

export default {
  name: "ComponentAlertaCadastro",

  async created() {
    await getAlertasCoach();
    var alerta = getAlertas();
    this.pendencia =
      alerta && alerta.cadastro && alerta.cadastro.status
        ? alerta.cadastro.status
        : false;
  },

  mounted() { },
  computed: {},

  data() {
    return {
      pendencia: null,
    };
  },
  components: {},
};
</script>