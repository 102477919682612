<template>
  <div>
    <Loading
      :active="isLoading"
      :color="'#055DEB'"
      :width="'30'"
      :backgroundColor="'#CFC9C9'"
      :loader="loader"
    >
      <div style="z-index: 999999; border-radius: 10px" class="custom-message">
        <div style="min-width: 250px" class="card">
          <div class="card-body">
            <div class="text-center mb-3">
              <div class="text-center spinner-border text-primary"></div>
            </div>
            <div class="text-center">
              <h6>{{ message }}</h6>
            </div>
          </div>
        </div>
      </div>
    </Loading>
  </div>
</template>

<script>
// Loading
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/css/index.css";

export default {
  name: "ComponentLoadingPage",
  props: {
    isLoading: {
      type: Boolean,
    },
    loader: {
      type: String,
      default: "bars",
    },
    message: {
      type: String,
      default: "Aguarde...",
    },
  },
  data() {
    return {};
  },
  components: {
    Loading,
  },
};
</script>