<template>
    <div>
        <div v-if="sessao" class="row mb-3">
            <div class="col-6">
                <div style="padding-bottom: 10px;" class="w-numeric-value">
                    <div class="w-content">
                        <span class="w-value">Lista de Sessões </span>
                    </div>
                </div>
            </div>
            <div class="col-6 text-right">
                <button @click="openAdicionarEditarSessao(null)" class="btn btn-primary mr-1"><i class="fa fa-circle-plus">
                    </i> Nova sessão</button>
            </div>

        </div>
        <div v-else class="row">
            <div class="col-12">
                <div class="alert alert-info">Nenhuma sessão cadastrada para esse processo</div>
            </div>
        </div>
        <div class="row">
            <div v-for="sessao in sessao" :key="sessao.codigo" class="col-12 m-1">
                <div class="widget widget-card-two">
                    <div class="widget-content">

                        <div class="media">
                            <div class="widget-five">
                                <div class="w-content">
                                    <div class="">
                                        <p class="task-left mb-2">{{ sessao.numero_sessao }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="media-body">
                                <div class="row">
                                   
                                    <div class="col-xl-5 col-lg-5 col-md-6 col-sm-12 mb-2">
                                        <router-link style="cursor: pointer; font-size: 0.6rem" v-tippy="'Abrir'" :to="`/coach/sessao/${sessao.codigo}/${this.route.params.codigo}`"
                                >
                                        <h5>{{ sessao.descricao }}</h5>
                                        <p class="">{{ moment(sessao.data_inicio_sessao).format('DD/MM/YYYY') }} -
                                            {{ sessao.hora_inicio }} até {{ sessao.hora_fim }} </p>
                                        <p v-if="sessao.ind_finalizado" class="task-completed"><span
                                                class="text-success">Finalizado - </span>{{ sessao.data_finalizacao ?
                                                    moment(sessao.data_finalizacao).format('DD/MM/YYYY') : '--' }}</p>
                                        <p v-else class="task-hight-priority"><span class="text-danger">Ag. Início</span>
                                        </p>
                                    </router-link>&nbsp; &nbsp; 
                                    </div>
                               
                                    <div class="col-xl-2 col-lg-3 col-md-3 col-sm-4 col-6 mb-2">
                                        <p v-if="sessao.ind_liberado">Liberada</p>
                                        <p v-else>Sessão Bloqueada</p>
                                        <label class="switch s-icons s-outline s-outline-success ">
                                            <input type="checkbox" @change="updateStatus(sessao)"
                                                v-model="sessao.ind_liberado">
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                    <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-6 mb-2">
                                        <p>Ferramentas</p>
                                        <h5>0</h5>
                                    </div>
                                    <div
                                        class="col-xl-2 col-lg-2 col-md-3 col-sm-3 col-6 d-flex  align-items-center justify-content-center">
                                        <button @click="remove(sessao)"
                                            class="btn btn-sm rounded-pill btn-danger">Excluir</button>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal" role="dialog" id="modalNovaSessao" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class=" modal-dialog  modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Nova Sesão
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                                aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Data</label>
                                    <input type="datetime-local" class="form-control form-control-sm"
                                        v-model="this.sessao_new.data_inicio_sessao">

                                </div>
                            </div>
                            <div class="col-12">

                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button @click="this.adicionar" class="btn btn-primary btn-block">
                            <i class="flaticon-cancel-12"></i> Adicionar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.widget-card-one {
    padding: 10px 0;
}


.component-card_6 {
    width: 280px;
    padding-right: 0px
}

.component-card_6 .card-body {
    padding: 15px 15px;
}

.card .card-body p:not(:last-child) {
    margin-bottom: 0px;

}

.widget-five .w-content {
    padding: 2px 26px;
}

.widget-five {
    height: auto;
}

.widget-card-two {
    padding: 10px 0px;
    box-shadow: none;
    border-bottom: 1px dashed #e0e6ed;
}

.widget-five .w-content div .task-left {

    margin-bottom: 0;
    font-size: 22px;
    color: #1b55e2;
    background: #c2d5ff;
    font-weight: 600;
    border-radius: 50%;
    display: inline-flex;
    height: 60px;
    width: 60px;
    justify-content: center;
    padding: 12px 0px;
    border: 3px solid #fff;
    margin-bottom: 20px;
    box-shadow: 0px 0px 8px 2px #e0e6ed;

}

.widget-card-two .media {
    margin-bottom: 0px;
    padding-bottom: 0px;
    border-bottom: 0px;
}

.widget-five .w-content {
    padding: 0;
    padding-right: 15px;
}

.widget-card-two .media .media-body p {
    margin-bottom: 0;
    font-weight: 600;
    color: #888ea8;
    font-size: 13px;
}
</style>

<script>
import moment from "moment";
import { liberarBloquear, remover, adicionar } from "@/modules/coach/sessao/sessaoController";
import { toast, alert } from "@/components/sweetAlert/index";
import Swal from "sweetalert2";
import { useRoute } from 'vue-router';

export default {
    name: "ComponenteSessoes",

    methods: {
        openAdicionarEditarSessao() {
            this.sessao_new.processo_codigo = this.route.params.codigo,
                // eslint-disable-next-line
                $("#modalNovaSessao").modal("show");
        },

        ajustarAtivoInativo() {
            this.sessao.forEach((x) => {
                x.ind_liberado = (x.ind_liberado == 1) ? true : false;
            })
        },

        async adicionar() {

            this.updateIsLoading(true)

            const response = await adicionar(this.sessao_new);
            if ((response && response.data && response.data.erro) || response.status == 'error' || (response && response.response && response.status != 200)) {
                toast(response.response.data.message || response.message, "error");
                this.updateIsLoading(false)
                return
            }
            this.updateIsLoading(false);

            if (response.data && response.data[0].codigo) {
                this.sessao = response.data;
                this.ajustarAtivoInativo();
                this.$emit('atualizaIndicadores');
            }
            // eslint-disable-next-line
            $("#modalNovaSessao").modal("hide");
            toast('Sessão adicionada com sucesso', "success");

        },

        async updateStatus(sessao) {
            if (sessao) {
                this.updateIsLoading(true)
                const dados = {
                    codigo: sessao.codigo,
                    processo: sessao.processo_id,
                    ind_liberado: (sessao.ind_liberado == true) ? 1 : 0
                }
                const response = await liberarBloquear(dados);
                if ((response && response.data && response.data.erro) || response.status == 'error' || (response && response.response && response.status != 200)) {
                    toast(response.response.data.message || response.message, "error");
                    this.updateIsLoading(false)
                    return
                }
                this.updateIsLoading(false)
                toast((dados.ind_liberado == 1 ? 'Sessão Liberada' : 'Sessão Bloqueada'), "success");
            }
        },
        updateIsLoading(value) {
            this.$emit('updateIsLoading', value);
        },
        remove(sessao_remover) {
            Swal.fire({
                title: "Deseja remover essa Sessão?",
                text: "",
                icon: "question",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonColor: "#3085d6",
                reverseButtons: true,
                confirmButtonText: 'Excluir',
                allowOutsideClick: false,
            }).then(async (result) => {
                if (result.isConfirmed) {

                    this.updateIsLoading(true);
                    const dados = {
                        codigo: sessao_remover.codigo,
                        processo: sessao_remover.processo_id,

                    }
                    const response = await remover(dados);
                    if ((response && response.data && response.data.erro) || response.status == 'error' || (response && response.response && response.status != 200)) {
                        toast(response.response.data.message || response.message, "error");
                        this.updateIsLoading(false)
                        return
                    }
                    this.sessao = response.data;
                    this.ajustarAtivoInativo();
                    this.$emit('atualizaIndicadores');
                    this.updateIsLoading(false);
                    alert('Removido com sucesso', '', 'success');
                }
            });
        }



    },
    watch: {
        sessao_prop: {
            deep: true,
            handler(newSessao) {
                this.sessao = newSessao;
            }
        }
    },
    props: ['sessao_prop'],
    data() {
        return {
            moment: moment,
            sessao: this.sessao_prop,
            route: useRoute(),
            sessao_new: {
                data_inicio_sessao: null
            }

        };
    },

}

</script>