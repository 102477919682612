<template>
    <div>
        <ComponentLoadingPage v-bind:isLoading="isLoading" />
        <ComponentNavBar />
        <!--  BEGIN NAVBAR  -->
        <ComponentNavBar2 :local="local" />
        <!--  END NAVBAR  -->

        <!--  BEGIN MAIN CONTAINER  -->
        <div class="main-container" id="container">
            <div class="overlay"></div>
            <div class="search-overlay"></div>

            <ComponentMenu />

            <!--  BEGIN CONTENT AREA  -->
            <div id="content" class="main-content">
                <div class="col-md-12">
                    <div class="row">

                        <ComponentInfoCoach :coach="coach" :exibe_foto_perfil_temp="exibe_foto_perfil_temp"
                            :exibe_foto_perfil_base64="exibe_foto_perfil_base64" :perfil_base_64="perfil_base_64" />

                        <div class="col-xl-8 col-lg-6 col-md-7 col-sm-12 layout-top-spacing mb-3">
                            <div class="widget-content widget-content-area animated-underline-content">

                                <ul class="nav nav-tabs  mb-3" id="animateLine" role="tablist">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-toggle="tab" href="#tab-perfil" role="tab"
                                            aria-controls="animated-underline-profile" aria-selected="false"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" class="feather feather-user">
                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                <circle cx="12" cy="7" r="4"></circle>
                                            </svg> Perfil </a>

                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="tab" href="#tab-dados_gerais" role="tab"
                                            aria-controls="animated-underline-home" aria-selected="true"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round" class="feather feather-home">
                                                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                                                <polyline points="9 22 9 12 15 12 15 22"></polyline>
                                            </svg> Dados Gerais</a>
                                    </li>

                                    <li class="nav-item">
                                        <a class="nav-link" id="animated-underline-contact-tab" data-toggle="tab"
                                            href="#tab-formacao" role="tab" aria-controls="animated-underline-contact"
                                            aria-selected="false"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                                stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-clipboard">
                                                <path
                                                    d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2">
                                                </path>
                                                <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>

                                            </svg> Formação</a>
                                    </li>
                                </ul>

                                <div class="tab-content" id="animateLineContent-4">
                                    <div class="tab-pane fade show active" id="tab-perfil" role="tabpanel"
                                        aria-labelledby="animated-underline-home-tab">
                                        <div class="col-12">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label> Foto Perfil </label>
                                                        <input type="file" accept="image/*" :maxlength="204800"
                                                            @change="exibirImagemTempPerfil"
                                                            class="form-control form-control-sm" ref="foto_perfil">
                                                        <small>( Imagem desejada 90x90 pixels)</small>
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-xl-8 col-md-6 col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label v-if="coach.tipo == 'J'">Nome Fantasia <small
                                                                class="text-danger">*</small></label>
                                                        <label v-else>Nome Completo <small
                                                                class="text-danger">*</small></label>
                                                        <input disabled type="text" maxlength="250"
                                                            class="form-control form-control-sm" v-model="coach.nome">
                                                        <ComponenteValidacao :errorsValidation="v$.coach.nome.$errors" />
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-md-6 col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label>Data Nasc. <small class="text-danger">*</small></label>
                                                        <input  type="date" class="form-control form-control-sm"
                                                            v-model="coach.nascimento">
                                                        <ComponenteValidacao
                                                            :errorsValidation="v$.coach.nascimento.$errors" />
                                                    </div>
                                                </div>
                                                <div v-if="coach.tipo == 'J'" class="col-xl-8 col-md-6 col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label>Razão Social <small class="text-danger">*</small></label>
                                                        <input disabled type="text" maxlength="250"
                                                            class="form-control form-control-sm"
                                                            v-model="coach.razao_social" />
                                                      
                                                    </div>
                                                </div>
                                                <div class="col-xl-4 col-md-6 col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label>Telefone <small class="text-danger">*</small></label>
                                                        <input type="text" class="form-control form-control-sm"
                                                            v-model="coach.telefone" maxlength="16"
                                                            placeholder="( )    -    " v-mask="'(00) 00000-0000'">
                                                        <ComponenteValidacao
                                                            :errorsValidation="v$.coach.telefone.$errors" />
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12 ">
                                                    <div class="form-group">
                                                        <label v-if="coach.tipo == 'F'">CPF <small
                                                                class="text-danger">*</small></label>
                                                        <label v-else>CNPJ <small class="text-danger">*</small></label>
                                                        <input disabled v-if="coach.tipo == 'F'" type="text" maxlength="14"
                                                            v-mask="'000.000.000-00'" class="form-control form-control-sm"
                                                            v-model="coach.cpf_cnpj" />
                                                        <input disabled v-else type="text" maxlength="18"
                                                            v-mask="'00.000.000/0000-00'"
                                                            class="form-control form-control-sm" v-model="coach.cpf_cnpj" />
                                                        <ComponenteValidacao
                                                            :errorsValidation="v$.coach.cpf_cnpj.$errors" />
                                                    </div>
                                                </div>
                                                
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-4 col-md-4 col-sm-6 col-xs-12 ">
                                                    <div class="form-group">
                                                        <label>CEP</label>
                                                        <input type="text" @blur="getCEP()" maxlength="10"
                                                            placeholder="     -    " class="form-control form-control-sm"
                                                            v-model="coach.endereco_cep" v-mask="'00000-000'">
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-xl-8 col-md-6 col-sm-6 col-xs-12 ">
                                                    <div class="form-group">
                                                        <label>Rua <small class="text-danger">*</small></label>
                                                        <input type="text" maxlength="255"
                                                            class="form-control form-control-sm"
                                                            v-model="coach.endereco_rua">
                                                        <ComponenteValidacao
                                                            :errorsValidation="v$.coach.endereco_rua.$errors" />
                                                    </div>

                                                </div>
                                                <div class="col-xl-4 col-md-6 col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label>Número <small class="text-danger">*</small></label>
                                                        <input type="text" maxlength="5"
                                                            class="form-control form-control-sm"
                                                            v-model="coach.endereco_numero">
                                                        <ComponenteValidacao
                                                            :errorsValidation="v$.coach.endereco_numero.$errors" />
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-xl-8 col-md-6 col-sm-6 col-xs-12 ">
                                                    <div class="form-group">
                                                        <label>Bairro <small class="text-danger">*</small></label>
                                                        <input type="text" maxlength="150"
                                                            class="form-control form-control-sm"
                                                            v-model="coach.endereco_bairro">
                                                        <ComponenteValidacao
                                                            :errorsValidation="v$.coach.endereco_bairro.$errors" />
                                                    </div>

                                                </div>
                                                <div class="col-xl-4 col-md-6 col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label>UF <small class="text-danger">*</small></label>
                                                        <Select2 ref="endereco_uf" :settings="{ width: '100%' }"
                                                            maxlength="2" v-model="coach.endereco_uf" :options="lista_uf"
                                                            @select="getCidade" :debounce="500" />
                                                        <ComponenteValidacao
                                                            :errorsValidation="v$.coach.endereco_uf.$errors" />
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-xl-8 col-md-6 col-sm-6 col-xs-12 ">
                                                    <div class="form-group">
                                                        <label>Cidade <small class="text-danger">*</small></label>
                                                        <Select2 id="endereco_cidade" :settings="{ width: '100%' }"
                                                             @select="setSelectCidade"
                                                            v-model="coach.endereco_cidade_ibge" :options="lista_cidades" />
                                                        <ComponenteValidacao
                                                            :errorsValidation="v$.coach.endereco_cidade_ibge.$errors" />
                                                    </div>

                                                </div>
                                                <div class="col-xl-4 col-md-6 col-sm-6 col-xs-12">
                                                    <div class="form-group">
                                                        <label>País <small class="text-danger">*</small></label>
                                                        <Select2 id="endereco_pais" :settings="{ width: '100%' }"
                                                            maxlength="3" v-model="coach.endereco_pais"
                                                            :options="lista_pais" />
                                                        <ComponenteValidacao
                                                            :errorsValidation="v$.coach.endereco_pais.$errors" />
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="tab-pane fade" id="tab-dados_gerais" role="tabpanel"
                                        aria-labelledby="animated-underline-profile-tab">
                                        <ComponentDadosGerais :coach="coach" />
                                    </div>

                                    <div class="tab-pane fade" id="tab-formacao" role="tabpanel"
                                        aria-labelledby="animated-underline-contact-tab">
                                        <ul class="nav nav-pills mb-3 mt-3" role="tablist">
                                            <li class="nav-item dropdown">
                                                <a style="cursor:pointer; border-bottom: solid 1px;"
                                                    class="nav-link dropdown-toggle" data-toggle="dropdown" href="#"
                                                    role="button" aria-haspopup="true" aria-expanded="false">Cadastrar
                                                </a>
                                                <div class="dropdown-menu">
                                                    <a style="cursor:pointer;" class="dropdown-item"
                                                        @click="openModalExperiencia('new', null)" role="tab"
                                                        aria-controls="pills-profile" aria-selected="false">Experiência</a>
                                                    <a style="cursor:pointer;" class="dropdown-item"
                                                        @click="openModalFormacao('new', null)" role="tab"
                                                        aria-controls="pills-profile2" aria-selected="false">Formação</a>
                                                </div>
                                            </li>
                                            <li class="nav-item">
                                                <a style="border-bottom: solid 1px;" class="nav-link " id="experiencias-tab"
                                                    data-toggle="tab" href="#experiencias" role="tab"
                                                    aria-controls="experiencias" aria-selected="true">Minhas
                                                    Experiências</a>
                                            </li>
                                            <li class="nav-item">
                                                <a style="border-bottom: solid 1px;" class="nav-link" id="formacoes-tab"
                                                    data-toggle="tab" href="#formacoes" role="tab" aria-controls="formacoes"
                                                    aria-selected="false">Minhas Formações</a>
                                            </li>
                                        </ul>
                                        <div class="tab-content">
                                            <div class="tab-pane fade show active" id="experiencias" role="tabpanel"
                                                aria-labelledby="experiencias-tab">
                                                <div class="col-md-12">
                                                    <div class="row ">
                                                        <div id="toggleAccordion"
                                                            class="col-xl-12 col-md-12 col-sm-12 col-xs-12">
                                                            <div v-for="experiencia in experiencia"
                                                                :key="experiencia.codigo" class="card ">
                                                                <div class="card-header" id="headingOne1">
                                                                    <section class="mb-0 mt-0">
                                                                        <div role="menu" class="collapsed col-flex"
                                                                            data-toggle="collapse"
                                                                            :data-target="'#Accordion' + experiencia.codigo"
                                                                            aria-expanded="true"
                                                                            aria-controls="defaultAccordionOne">
                                                                            <div class="row">
                                                                                <div class="col-8">
                                                                                    {{ experiencia.empresa }}
                                                                                </div>
                                                                                <div v-if="experiencia.experiencia_atual"
                                                                                    class="col-4"><small>De
                                                                                        {{
                                                                                            moment(experiencia.periodo_inicio).format('MM/YYYY')
                                                                                        }}
                                                                                        até hoje</small></div>
                                                                                <div v-else class="col-4"><small>De
                                                                                        {{
                                                                                            moment(experiencia.periodo_inicio).format('MM/YYYY')
                                                                                        }}
                                                                                        até
                                                                                        {{
                                                                                            moment(experiencia.periodo_fim).format('MM/YYYY')
                                                                                        }}</small>
                                                                                </div>

                                                                            </div>
                                                                            <div class="icons"><svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width="24" height="24"
                                                                                    viewBox="0 0 24 24" fill="none"
                                                                                    stroke="currentColor" stroke-width="2"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    class="feather feather-chevron-down">
                                                                                    <polyline points="6 9 12 15 18 9">
                                                                                    </polyline>
                                                                                </svg></div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                                <div :id="'Accordion' + experiencia.codigo" class="collapse"
                                                                    aria-labelledby="headingOne1"
                                                                    data-parent="#toggleAccordion">
                                                                    <div class="card-body">
                                                                        <div class="row">
                                                                            <div class="col-6">
                                                                                <p style="margin-bottom: 2px"
                                                                                    class="card-text"><i
                                                                                        class="fa fa-briefcase"></i>
                                                                                    {{ experiencia.cargo_descricao }}</p>
                                                                            </div>
                                                                            <div class="col-6">
                                                                                <p style="margin-bottom: 2px"
                                                                                    class="card-text"><i
                                                                                        class="fa fa-desktop"></i>
                                                                                    {{ experiencia.tipo_emprego_descricao }}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-6">
                                                                                <p style="margin-bottom: 2px"
                                                                                    class="card-text"><i
                                                                                        class="fa fa-location-dot"></i>
                                                                                    {{ experiencia.localidade }}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <br>
                                                                                <p style="margin-bottom: 2px"
                                                                                    class="card-text"
                                                                                    v-html="experiencia.descricao"></p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row ">

                                                                            <div class="col-12 text-right"><small>
                                                                                    <i style="cursor:pointer;"
                                                                                        class="fas fa-edit fa-lg text-primary"
                                                                                        @click="openModalExperiencia(null, experiencia)"
                                                                                        v-tippy="'Editar'"></i>
                                                                                    &nbsp;&nbsp;
                                                                                    <i style="cursor:pointer;"
                                                                                        @click="deleteExperiencia(experiencia)"
                                                                                        class="fas fa-trash fa-lg text-danger"
                                                                                        v-tippy="'Excluir'"></i>
                                                                                </small></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="!experiencia.length" class="alert alert-info col-md-12">
                                                            Nenhuma experiência cadastrada</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="tab-pane fade" id="formacoes" role="tabpanel"
                                                aria-labelledby="formacoes-tab">
                                                <div class="col-md-12">
                                                    <div class="row ">
                                                        <div id="toggleAccordion"
                                                            class="col-xl-12 col-md-12 col-sm-12 col-xs-12">
                                                            <div v-for="formacao in formacao" :key="formacao.codigo"
                                                                class="card ">
                                                                <div class="card-header" id="headingOne1">
                                                                    <section class="mb-0 mt-0">
                                                                        <div role="menu" class="collapsed col-flex"
                                                                            data-toggle="collapse"
                                                                            :data-target="'#Accordion' + formacao.codigo"
                                                                            aria-expanded="true"
                                                                            aria-controls="defaultAccordionOne">
                                                                            <div class="row">
                                                                                <div class="col-8">
                                                                                    {{ formacao.titulo }}
                                                                                </div>
                                                                                <div v-if="formacao.ind_cursando"
                                                                                    class="col-4"><small>Cursando</small>
                                                                                </div>
                                                                                <div v-else class="col-4"><small>Finalizado
                                                                                        em
                                                                                        {{
                                                                                            moment(formacao.ano_formacao).format('MM/YYYY')
                                                                                        }}</small>
                                                                                </div>

                                                                            </div>
                                                                            <div class="icons"><svg
                                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                                    width="24" height="24"
                                                                                    viewBox="0 0 24 24" fill="none"
                                                                                    stroke="currentColor" stroke-width="2"
                                                                                    stroke-linecap="round"
                                                                                    stroke-linejoin="round"
                                                                                    class="feather feather-chevron-down">
                                                                                    <polyline points="6 9 12 15 18 9">
                                                                                    </polyline>
                                                                                </svg></div>
                                                                        </div>
                                                                    </section>
                                                                </div>
                                                                <div :id="'Accordion' + formacao.codigo" class="collapse"
                                                                    aria-labelledby="headingOne1"
                                                                    data-parent="#toggleAccordion">
                                                                    <div class="card-body">
                                                                        <div class="row mb-3">
                                                                            <div class="col-6">
                                                                                <p style="margin-bottom: 2px"
                                                                                    class="card-text"><i
                                                                                        class="fa fa-briefcase"></i>
                                                                                    {{ formacao.titulo }}</p>
                                                                            </div>
                                                                            <div class="col-6">
                                                                                <p style="margin-bottom: 2px"
                                                                                    class="card-text"><i
                                                                                        class="fa fa-desktop"></i>
                                                                                    {{ formacao.instituicao }}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row ">

                                                                            <div style="font-size: 0.7rem" class="col-4"
                                                                                v-show="!file.deleted"
                                                                                v-for=" file in formacao.files"
                                                                                :key="file.id">
                                                                                <h v-if="file && file.link">{{ file.name
                                                                                }}&nbsp; ;&nbsp;</h>
                                                                                <h v-else>{{ file.nome_original_documento
                                                                                }} &nbsp;&nbsp;</h>
                                                                                <h v-if="file && file.link">
                                                                                    <a :href="file.link.href"
                                                                                        :download="file.name">
                                                                                        <i style="cursor:pointer;"
                                                                                            class="fas fa-download fa-lg text-primary"
                                                                                            v-tippy="'Download'"></i>
                                                                                    </a>
                                                                                </h>
                                                                                <h v-else>
                                                                                    <i @click="downloadFileFormacao(formacao_edit_new, file)"
                                                                                        style="cursor:pointer;"
                                                                                        class="fas fa-download fa-lg text-primary"
                                                                                        v-tippy="'Download'"></i>
                                                                                </h>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row ">

                                                                            <div class="col-12 text-right"><small>
                                                                                    <i style="cursor:pointer;"
                                                                                        class="fas fa-edit fa-lg text-primary"
                                                                                        @click="openModalFormacao(null, formacao)"
                                                                                        v-tippy="'Editar'"></i>
                                                                                    &nbsp;&nbsp;
                                                                                    <i style="cursor:pointer;"
                                                                                        @click="deleteFormacao(formacao)"
                                                                                        class="fas fa-trash fa-lg text-danger"
                                                                                        v-tippy="'Excluir'"></i>
                                                                                </small></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="!formacao.length" class="alert alert-info col-md-12">
                                                            Nenhuma formação cadastrada</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div class="modal fade" id="modalExperiencia" tabindex="-1" role="dialog"
                                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLabel">Experiência</h5>
                                                    <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close">
                                                        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                            width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="feather feather-x">
                                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="col-12">
                                                        <div class="row">
                                                            <div class="col-xl-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div class="form-group">
                                                                    <label>Cargo <small
                                                                            class="text-danger">*</small></label>
                                                                    <Select2 :settings="{ width: '100%' }" id="cargo_id"
                                                                        v-model="experiencia_edit_new.cargo_id"
                                                                        :options="lista_cargos" />
                                                                    <ComponenteValidacao
                                                                        :errorsValidation="v$.experiencia_edit_new.cargo_id.$errors" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div class="form-group">
                                                                    <label>Tipo de emprego <small
                                                                            class="text-danger">*</small></label>
                                                                    <Select2 :settings="{ width: '100%' }"
                                                                        v-model="experiencia_edit_new.tipo_emprego_id"
                                                                        :options="lista_tipo_empregos" />
                                                                    <ComponenteValidacao
                                                                        :errorsValidation="v$.experiencia_edit_new.tipo_emprego_id.$errors" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div class="form-group">
                                                                    <label>Empresa <small maxlength="98"
                                                                            class="text-danger">*</small></label>
                                                                    <input type="text" class="form-control form-control-sm"
                                                                        v-model="experiencia_edit_new.empresa">
                                                                    <ComponenteValidacao
                                                                        :errorsValidation="v$.experiencia_edit_new.empresa.$errors" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div class="form-group">
                                                                    <label>Localidade <small maxlength="250"
                                                                            class="text-danger">*</small></label>
                                                                    <input type="text" class="form-control form-control-sm"
                                                                        v-model="experiencia_edit_new.localidade">
                                                                    <ComponenteValidacao
                                                                        :errorsValidation="v$.experiencia_edit_new.localidade.$errors" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-5 col-md-5 col-sm-5 col-xs-5">
                                                                <div class="form-group">
                                                                    <label>Início <small
                                                                            class="text-danger">*</small></label>
                                                                    <input type="month" class="form-control form-control-sm"
                                                                        v-model="experiencia_edit_new.periodo_inicio">
                                                                    <ComponenteValidacao
                                                                        :errorsValidation="v$.experiencia_edit_new.periodo_inicio.$errors" />
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-2 col-md-2 col-sm-2 col-xs-2">
                                                                <br>
                                                                <br>
                                                                até
                                                            </div>
                                                            <div class="col-xl-5 col-md-5 col-sm-5 col-xs-5">
                                                                <div class="form-group">
                                                                    <label>Fim <small class="text-danger">*</small></label>
                                                                    <input type="month" class="form-control form-control-sm"
                                                                        :disabled="experiencia_edit_new.experiencia_atual"
                                                                        v-model="experiencia_edit_new.periodo_fim">
                                                                    <ComponenteValidacao
                                                                        :errorsValidation="v$.experiencia_edit_new.periodo_fim.$errors" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-2 col-md-2 col-sm-2 col-xs-2">
                                                                <div class="n-chk">
                                                                    <label
                                                                        class="new-control new-checkbox checkbox-primary">
                                                                        <input type="checkbox" class="new-control-input"
                                                                            v-model="experiencia_edit_new.experiencia_atual"
                                                                            checked="false">
                                                                        <span class="new-control-indicator"></span>Atual

                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <br>
                                                                <div class="form-group">
                                                                    <label>Descrição <small
                                                                            class="text-danger">*</small></label>
                                                                    <QuillEditor ref="descricao_experiencia"
                                                                        v-model:content="experiencia_edit_new.descricao"
                                                                        contentType="html" style="min-height: 150px;"
                                                                        theme="snow" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <button class="btn" data-dismiss="modal"><i
                                                            class="flaticon-cancel-12"></i> Cancelar</button>
                                                    <button type="button" @click="newEditExperiencia()"
                                                        class="btn btn-primary">Salvar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="modal fade" id="modalFormacao" tabindex="-1" role="dialog"
                                        aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div class="modal-dialog" role="document">
                                            <div class="modal-content">
                                                <div class="modal-header">
                                                    <h5 class="modal-title" id="exampleModalLabel">Formação</h5>
                                                    <button type="button" class="close" data-dismiss="modal"
                                                        aria-label="Close">
                                                        <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                            width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                                            stroke-linejoin="round" class="feather feather-x">
                                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                                        </svg>
                                                    </button>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="col-12">
                                                        <div class="row">
                                                            <div class="col-xl-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div class="form-group">
                                                                    <label>Curso <small maxlength="100"
                                                                            class="text-danger">*</small></label>
                                                                    <input type="text" class="form-control form-control-sm"
                                                                        v-model="formacao_edit_new.titulo">
                                                                    <ComponenteValidacao
                                                                        :errorsValidation="v$.formacao_edit_new.titulo.$errors" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-12 col-md-12 col-sm-12 col-xs-12">
                                                                <div class="form-group">
                                                                    <label>Instituição <small maxlength="180"
                                                                            class="text-danger">*</small></label>
                                                                    <input type="text" class="form-control form-control-sm"
                                                                        v-model="formacao_edit_new.instituicao">
                                                                    <ComponenteValidacao
                                                                        :errorsValidation="v$.formacao_edit_new.instituicao.$errors" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-5 col-md-5 col-sm-5 col-xs-5">
                                                                <div class="form-group">
                                                                    <label>Ano Formação <small
                                                                            class="text-danger">*</small></label>
                                                                    <input type="month" class="form-control form-control-sm"
                                                                        :disabled="formacao_edit_new.ind_cursando"
                                                                        v-model="formacao_edit_new.ano_formacao">
                                                                    <ComponenteValidacao
                                                                        :errorsValidation="v$.formacao_edit_new.ano_formacao.$errors" />
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-5 col-md-5 col-sm-5 col-xs-5">
                                                                <br><br>
                                                                <div class="n-chk">
                                                                    <label
                                                                        class="new-control new-checkbox checkbox-primary">
                                                                        <input type="checkbox" class="new-control-input"
                                                                            v-model="formacao_edit_new.ind_cursando"
                                                                            checked="false">
                                                                        <span class="new-control-indicator"></span>Cursando
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <label>Certificados <small
                                                                            class="text-danger">*</small></label>
                                                                    <input type="file" @change="carregarImagem()" multiple
                                                                        class="form-control form-control-sm"
                                                                        ref="files_formacao">

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div v-if="formacao_edit_new.files"
                                                                    class="table-responsive">
                                                                    <table class="table table-sm table-bordered mb-4">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Arquivo (s)</th>
                                                                                <th></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr v-show="!file.deleted"
                                                                                v-for=" file in formacao_edit_new.files"
                                                                                :key="file.id">
                                                                                <td v-if="file && file.link">{{ file.name }}
                                                                                </td>
                                                                                <td v-else>{{ file.nome_original_documento
                                                                                }}</td>

                                                                                <td v-if="file && file.link">
                                                                                    <a :href="file.link.href"
                                                                                        :download="file.name">
                                                                                        <i style="cursor:pointer;"
                                                                                            class="fas fa-download fa-lg text-primary"
                                                                                            v-tippy="'Download'"></i>
                                                                                    </a> &nbsp;&nbsp; &nbsp;
                                                                                    <i style="cursor:pointer;"
                                                                                        @click="deleteFileFormacao(formacao_edit_new, file)"
                                                                                        class="fas fa-trash fa-lg text-danger"
                                                                                        v-tippy="'Excluir'"></i>


                                                                                </td>
                                                                                <td v-else>

                                                                                    <i @click="downloadFileFormacao(formacao_edit_new, file)"
                                                                                        style="cursor:pointer;"
                                                                                        class="fas fa-download fa-lg text-primary"
                                                                                        v-tippy="'Download'"></i>
                                                                                    &nbsp;&nbsp; &nbsp;
                                                                                    <i style="cursor:pointer;"
                                                                                        @click="deleteFileFormacao(formacao_edit_new, file)"
                                                                                        class="fas fa-trash fa-lg text-danger"
                                                                                        v-tippy="'Excluir'"></i>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="modal-footer">
                                                    <button class="btn" data-dismiss="modal"><i
                                                            class="flaticon-cancel-12"></i> Cancelar</button>
                                                    <button type="button" @click="newEditFormacao()"
                                                        class="btn btn-primary">Salvar</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <br>
                            <div class="row mb-2">
                                <div class="col d-flex justify-content-start">
                                    <router-link style="width: 150px;" class="btn btn-danger "
                                        :to="{ path: '/coach/dashboard' }">Cancelar</router-link>
                                </div>
                                <div class="col d-flex justify-content-end">
                                    <button style="width: 150px;" @click="updateCoach"
                                        class="btn btn-success">Salvar</button>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>

                <br><br><br>
                <ComponentFooter />
            </div>
        </div>
    </div>
</template>
  
<script>

import ComponentMenu from "@/modules/coach/components/menu/ComponentMenu.vue";
import ComponentNavBar from "@/modules/coach/components/navbar/ComponentNavBar.vue";
import ComponentNavBar2 from "@/modules/coach/components/navbar/ComponentNavBar2.vue";
import ComponentFooter from "@/modules/coach/components/footer/ComponentFooter.vue";
import ComponentInfoCoach from "../components/perfil/ComponentInfoCoach.vue";
import ComponentDadosGerais from "../components/perfil/ComponentDadosGerais.vue"


//import { mapState, mapGetters } from "vuex";

import moment from 'moment'

import {
    getEnderecoCEP, getUF, getCidades, getCodigoIGBEEstadoSigla, getSiglaEstadoCodigo, getPais,
    getCargos, getTipoEmpregos, updateCoach, getPerfil, downloadFile
} from '../coachController';

import {setAuthLocalStorageUser, getUserLocalStorage} from "@/modules/auth/authController"

import Select2 from 'vue3-select2-component';



// Validação
import { useVuelidate } from "@vuelidate/core";
import { required, helpers, requiredIf } from "@vuelidate/validators";
import ComponenteValidacao from "@/components/validacaoInput/ComponentValidacao";
import mensagem from "@/components/validacaoInput/mensagem";

import { toast, alert } from "@/components/sweetAlert/index";

// Loading
import ComponentLoadingPage from '@/components/loading/ComponentLoadingPage.vue'

import { uuid } from 'vue-uuid'

//import store from '@/store'

import("@/assets/scss/app.css");
import("@/assets/scss/profile.css");


export default {
    name: "MeuPerfilView",

    setup() {
        return { v$: useVuelidate() };
    },

    validations: {

        experiencia_edit_new: {
            cargo_id: {
                required: helpers.withMessage(mensagem.required, required),
                lazy: true
            },
            tipo_emprego_id: {
                required: helpers.withMessage(mensagem.required, required),
                lazy: true
            },
            empresa: {
                required: helpers.withMessage(mensagem.required, required),
                lazy: true
            },
            localidade: {
                required: helpers.withMessage(mensagem.required, required),
            },
            periodo_inicio: {
                required: helpers.withMessage(mensagem.required, required),
            },
            periodo_fim: {
                required: helpers.withMessage(mensagem.required,
                    requiredIf(function () {
                        return !this.experiencia_edit_new.experiencia_atual
                    })
                )
            },
        },

        formacao_edit_new: {
            ano_formacao: {
                required: helpers.withMessage(mensagem.required,
                    requiredIf(function () {
                        return !this.formacao_edit_new.ind_cursando
                    })
                )
            },
            instituicao: {
                required: helpers.withMessage(mensagem.required, required),
            },
            titulo: {
                required: helpers.withMessage(mensagem.required, required),
            },
        },

        coach: {
            nome: {
                required: helpers.withMessage(mensagem.required, required),
            },
            nascimento: {
                required: helpers.withMessage(mensagem.required, required),
            },
            cpf_cnpj: {
                required: helpers.withMessage(mensagem.required, required),
            },
            endereco_rua: {
                required: helpers.withMessage(mensagem.required, required),
            },
            endereco_numero: {
                required: helpers.withMessage(mensagem.required, required),
            },
            endereco_bairro: {
                required: helpers.withMessage(mensagem.required, required),
            },
            endereco_uf: {
                required: helpers.withMessage(mensagem.required, required),
            },
            endereco_cidade_ibge: {
                required: helpers.withMessage(mensagem.required, required),
            },
            endereco_pais: {
                required: helpers.withMessage(mensagem.required, required),
            },
            telefone: {
                required: helpers.withMessage(mensagem.required, required),
            },
        }
    },

    mounted() {

    },

    created() {
        this.init();
    },

    methods: {

        async downloadFileFormacao(formacao, file) {
            const documento = {}
            documento.documento_codigo = file.documento_codigo;
            documento.destino = 'coach_formacao';
            documento.tipo_documento_codigo = formacao.codigo;
            documento.tipo_documento = 'coach_formacao';
            documento.nome_documento = file.nome_original_documento;
            this.isLoading = true;
            await downloadFile(documento);
            this.isLoading = false;
        },

        async newEditExperiencia() {
            const result = await this.v$.experiencia_edit_new.$validate();
            if (!result) {
                return;
            }
            this.experiencia_edit_new.cargo_descricao = this.lista_cargos.find((cargo) => {
                return cargo.id == this.experiencia_edit_new.cargo_id;
            }).text;
            this.experiencia_edit_new.tipo_emprego_descricao = this.lista_tipo_empregos.find((tipo_emprego) => {
                return tipo_emprego.id == this.experiencia_edit_new.tipo_emprego_id;
            }).text;
            if (this.control_action == 'edit') {
                if (!this.experiencia_edit_new.inserted) {
                    this.experiencia_edit_new.updated = 1;
                    this.experiencia_edit_new = null;
                    toast('Alterado', 'success');
                }
            } else {
                this.experiencia_edit_new.codigo = uuid.v1();
                this.experiencia_edit_new.inserted = 1;
                this.experiencia.push(this.experiencia_edit_new);
                toast('Adicionado', 'success');
            }
            // eslint-disable-next-line
            $('#modalExperiencia').modal('hide');
        },

        async newEditFormacao() {
            const result = await this.v$.formacao_edit_new.$validate();
            if (!result) {
                return;
            }

            if (this.control_action == 'edit') {
                if (!this.formacao_edit_new.inserted) {
                    this.formacao_edit_new.updated = 1;
                    //this.formacao_edit_new = null;
                    toast('Alterado', 'success');
                    console.log(this.formacao_edit_new);
                }
            } else {
                this.formacao_edit_new.codigo = uuid.v1();
                this.formacao_edit_new.inserted = 1;
                this.formacao.push(this.formacao_edit_new);
                toast('Adicionado', 'success');
            }
            // eslint-disable-next-line
            $('#modalFormacao').modal('hide');
        },

        deleteExperiencia(experiencia) {
            var index = this.experiencia.findIndex(x => x.codigo === experiencia.codigo);
            if (this.experiencia[index].inserted) {
                this.experiencia.splice(index, 1);
            } else {
                this.experiencia[index].deleted = 1;
                this.experiencia_delete.push(this.experiencia[index]);
                this.experiencia.splice(index, 1);
            }
            toast('Removido', 'success');
        },

        deleteFormacao(formacao) {
            var index = this.formacao.findIndex(x => x.codigo === formacao.codigo);
            if (this.formacao[index].inserted) {
                this.formacao.splice(index, 1);
            } else {
                this.formacao[index].deleted = 1;
                this.formacao_delete.push(this.formacao[index]);
                this.formacao.splice(index, 1);
            }
            toast('Removido', 'success');
        },

        deleteFileFormacao(formacao, file) {
            var index = formacao.files.findIndex(x => x.id === file.id);
            if (formacao.inserted) {
                formacao.files.splice(index, 1);
            } else {
                file.deleted = 1;
            }
            toast('Removido', 'success');
        },

        openModalExperiencia(action, experiencia) {

            if (action == 'new') {
                this.control_action = 'new';
                this.v$.$reset();
                this.$refs.descricao_experiencia.setContents('');
                this.experiencia_edit_new = Object.assign({}, this.experiencia_base);
                // eslint-disable-next-line
                $('#modalExperiencia').modal('show');
            } else {
                this.control_action = 'edit';
                (experiencia.experiencia_atual == 1) ? experiencia.experiencia_atual = true : experiencia.experiencia_atual = false;
                this.experiencia_edit_new = experiencia;
                // eslint-disable-next-line
                $('#modalExperiencia').modal('show');
            }
        },

        openModalFormacao(action, formacao) {

            if (action == 'new') {
                this.formacao_edit_new = null;
                this.formacao_edit_new = Object.assign({}, this.formacao_base);
                this.control_action = 'new';
                this.v$.$reset();
                // eslint-disable-next-line
                $('#modalFormacao').modal('show');
            } else {
                if (!formacao.updated) {
                    if (formacao.files) {
                        formacao.files.forEach(element => {
                            element.deleted = 0;
                        });
                    }
                }
                this.control_action = 'edit';
                (formacao.ind_cursando == 1) ? formacao.ind_cursando = true : formacao.ind_cursando = false;
                this.formacao_edit_new = formacao;
              
                // eslint-disable-next-line
                $('#modalFormacao').modal('show');
            }
        },

        exibirImagemTempPerfil() {
            var image_perfil = this.$refs.foto_perfil;
            if (image_perfil.files && image_perfil.files[0]) {
                var file = image_perfil.files[0]
                if (file.size > 204800) {
                    toast('Imagem maior que 200 Kb', 'error')
                    this.$refs.foto_perfil.value = null;
                } else {
                    if (!['image/png', 'image/jpg', 'image/jpeg', 'image/bmp'].includes(file.type)) {
                        var msg = 'Arquivo ' + file.name + ' não permitido. É permitido apenas imagem. \n\n'
                        toast(msg, 'error')
                    } else {
                        this.coach.foto_perfil = file;
                        file = new FileReader();
                        file.onload = function (e) {
                            document.getElementById("perfil_temp").src = e.target.result;
                            this.foto_perfil_temp_local = e.target.result;                 
                        };
                        file.readAsDataURL(image_perfil.files[0]);
                        this.exibe_foto_perfil_temp = true;
                        this.exibe_foto_perfil_base64 = false;
                    }
                }
            } else {
                this.coach.foto_perfil_temp = 0;
            }
        },

        carregarImagem() {
            var arquivos = this.$refs.files_formacao;
            if (arquivos.files) {
                var erro = false;
                var msg = '';
                this.isLoading = true;
                for (const file of arquivos.files) {
                    if (this.formacao_edit_new.files && this.formacao_edit_new.files.length >= 3) {
                        erro = true; msg = ' É possível adicionar no máximo 3 documentos.';
                    } else {
                        if (file.size > 2097152) {
                            msg += 'Arquivo ' + file.name + ' maior que 2 Mb. \n\n'
                            erro = true;
                        } else {
                            if (!['image/png', 'image/jpg', 'image/jpeg', 'image/bmp', 'application/pdf'].includes(file.type)) {
                                msg += 'Arquivo ' + file.name + ' não permitido. É permitido apenas PDF ou imagem. \n\n'
                                erro = true
                            } else {
                                file.id = uuid.v1();
                                const link = document.createElement('a');
                                link.href = URL.createObjectURL(file); // Define o URL do arquivo
                                link.download = file.name; // Define o nome do arquivo
                                // Adicione o texto do link e o link de download à página HTML
                                link.textContent = `<i class="fa fa-check">${file.name}</i>`;
                                file.link = link;
                                (!this.formacao_edit_new.files) ? this.formacao_edit_new.files = [] : null;
                                this.formacao_edit_new.files.push(file)
                            }
                        }
                    }
                }
                this.$refs.files_formacao.value = null;
                this.isLoading = false;
                if (erro) {
                    toast(msg, 'error')
                }
                arquivos = null
            }
        },

        async updateCoach() {
            const result = await this.v$.coach.$validate();
            if (!result) {
                alert('Campos obrigatórios.', 'Existe um ou mais campos não preenchidos. \n\n Verifique e tente novamente', 'error')
                return;
            }
            this.isLoading = true;
            var newCoach = Object.assign({}, this.coach);
            newCoach.perfil_base_64 = '';
            newCoach.rede_social.facebook == null ? newCoach.rede_social.facebook = '' : '';
            newCoach.rede_social.instagram == null ? newCoach.rede_social.instagram = '' : '';
            newCoach.rede_social.twitter == null ? newCoach.rede_social.twitter = '' : '';
            newCoach.rede_social.linkedin == null ? newCoach.rede_social.linkedin = '' : '';
            newCoach.rede_social.youtube == null ? newCoach.rede_social.youtube = '' : '';
            newCoach.formacao = this.formacao;
            newCoach.experiencia = this.experiencia;
            newCoach.experiencia_delete = this.experiencia_delete;
            newCoach.formacao_delete = this.formacao_delete;
            const response = await updateCoach(newCoach);
            if (response && response.response && response.response.data) {
                toast(response.response.data.message, 'error');
                this.isLoading = false;
                return
            }

            this.$refs.foto_perfil.value = null;
            this.isLoading = false;
            this.$swal.fire({
                title: 'Perfil atualizado com sucesso',
                text: "\n\n\n\n",
                icon: 'success',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Continuar',
                allowOutsideClick: false
            }).then((result) => {
                if (result.isConfirmed) {
                    // Pegar o SRC da imagem e coloca no local storage, porém só se a imagem for Base64
                    var src = document.getElementById("perfil_temp").src;
                    if(src && src.includes('data:image/') ){
                        var local_user = getUserLocalStorage();
                        local_user.img_perfil = src;
                        setAuthLocalStorageUser(local_user);
                    }
                    location.reload();
                }
            })
        },

        setSelectCidade({ id, text }) {
            this.coach.endereco_cidade_ibge = id;
            this.coach.endereco_cidade = text;
        },

        async init() {
            this.isLoading = true;
            const perfil = await getPerfil();
            this.exibe_foto_perfil_base64 = true;
            this.exibe_foto_perfil_temp = false;
            if (perfil && perfil.data && perfil.data.coach) {
                const coach = perfil.data.coach;
                this.coach.nome = coach.nome;
                this.coach.tipo = coach.tipo_coach;
                this.coach.cpf_cnpj = coach.cpf_cnpj;
                this.coach.email = coach.email;
                this.coach.telefone = coach.telefone;
                this.coach.razao_social = coach.razao_social;
                (coach.perfil_base_64) ? this.exibe_foto_perfil_base64 = true : this.exibe_foto_perfil_base64 = false;
                (coach.perfil_base_64) ? this.exibe_foto_perfil_temp = false : this.exibe_foto_perfil_temp = true;
                this.coach.perfil_base_64 = coach.perfil_base_64;
            }
            if (perfil && perfil.data && perfil.data.experiencia) {
                this.experiencia = perfil.data.experiencia;
            }
            if (perfil && perfil.data && perfil.data.formacao) {
                this.formacao = perfil.data.formacao;
            }
            const uf = await getUF();
            this.lista_uf = uf;
            const pais = await getPais();
            this.lista_pais = pais;
            if (perfil && perfil.data && perfil.data.complementar) {
                const coach = perfil.data.complementar;           
                this.coach.nascimento = coach.data_nascimento;
                this.coach.endereco_bairro = coach.endereco_bairro;
                this.coach.endereco_rua = coach.endereco_rua;
                this.coach.endereco_numero = coach.endereco_numero;
                this.coach.endereco_cep = coach.endereco_cep;
                this.coach.endereco_uf = coach.endereco_uf;
                this.coach.endereco_uf_sigla = getSiglaEstadoCodigo(coach.endereco_uf);
                setTimeout(() => {
                    this.coach.endereco_cidade_ibge = coach.endereco_cidade_ibge;
                    this.getCidade({ id: coach.endereco_uf.toString() }).then(() => {
                        var cidade = this.lista_cidades.find((x) => {
                            return x.id == this.coach.endereco_cidade_ibge;
                        });
                        this.coach.endereco_cidade = (cidade && cidade.text) ? cidade.text : null;
                    });
                    this.coach.endereco_cidade_ibge = coach.endereco_cidade_ibge;
                }, 500);
                this.coach.endereco_pais = coach.endereco_pais;
                this.coach.configuracoes = JSON.parse(coach.configuracoes);
                this.coach.idioma = JSON.parse(coach.idioma);
                this.coach.rede_social = JSON.parse(coach.rede_social);
                this.coach.codigo_afiliado = coach.codigo_afiliado;
            }
            this.lista_cargos = await getCargos();
            this.lista_tipo_empregos = await getTipoEmpregos();
            this.isLoading = false;
        },

        async getCEP() {
            if (!this.coach.endereco_cep) {
                return;
            }
            this.isLoading = true;
            const response = await getEnderecoCEP(this.coach.endereco_cep);
            if (response && response.data && response.data.erro) {
                toast('CEP não localizado', 'warning')
                this.isLoading = false;
                return
            }
            if (response && response.data && response.status == 200) {
                this.coach.endereco_bairro = response.data.bairro;
                this.coach.endereco_cidade = response.data.localidade;
                this.coach.endereco_cidade_ibge = response.data.ibge;
                this.coach.endereco_rua = response.data.logradouro;
                this.coach.endereco_uf = getCodigoIGBEEstadoSigla(response.data.uf);
                this.coach.endereco_cep = response.data.cep;
                this.coach.endereco_uf_sigla = response.data.uf;
                setTimeout(() => {
                    this.getCidade({ id: this.coach.endereco_uf.toString() });
                }, 500);
                this.coach.endereco_pais = 1;
            }
            this.isLoading = false;
        },

        async getCidade({ id }) {
            this.isLoading = true;
            this.lista_cidades = await getCidades(id);
            this.coach.endereco_uf_sigla = getSiglaEstadoCodigo(id);
            this.isLoading = false;
        },
    },

    computed: {
    },
    data() {

        return {
            local: "Coach | Meu Perfil",
            moment: moment,
            exibe_foto_perfil_temp: true,
            exibe_foto_perfil_base64: false,
            perfil_base_64: null,
             foto_perfil_temp_local : null,
            coach: {           
                tipo: '',
                nome: '',
                email: '',
                telefone: '',
                cpf_cnpj: '',
                nascimento: '',
                endereco_bairro: '',
                endereco_rua: '',
                endereco_numero: '',
                endereco_cep: '',
                endereco_uf: '',
                endereco_cidade: '',
                endereco_pais: '',
                endereco_cidade_ibge: '',
                endereco_uf_sigla: '',
                configuracoes: {
                    aceita_cartao: false,
                    coaching_presencial: false,
                    desconto_a_vista: false,
                    coaching_online: false,
                    atuacao: {
                        coaching_empresarial: false,
                        life_coaching: false
                    }
                },
                idioma: {
                    portugues: true,
                    ingles: false,
                    espanhol: false,
                    alemao: false,
                    italiano: false
                },
                rede_social: {
                    facebook: '',
                    instagram: '',
                    twitter: '',
                    linkedin: '',
                    youtube: ''
                },
                codigo_afiliado: '',
                video_apresentacao: '',
                link_site: '',
                foto_perfil: null,
                foto_perfil_temp: null,

            },
            alertas: {

            },
            formacao: [],
            formacao_delete: [],
            experiencia: [],
            experiencia_delete: [],
            lista_uf: [],
            lista_cidades: [],
            lista_pais: [],
            lista_cargos: [],
            lista_tipo_empregos: [],
            isLoading: false,
            experiencia_edit_new: {

            },
            control_action: null,
            experiencia_base: {
                inserted: false,
                updated: false,
                codigo: '',
                cargo_id: '',
                tipo_emprego_id: '',
                empresa: '',
                periodo_inicio: '',
                periodo_fim: '',
                experiencia_atual: false,
                descricao: '',
                localidade: ''
            },
            formacao_edit_new: {

            },
            formacao_base: {
                inserted: false,
                updated: false,
                codigo: '',
                titulo: '',
                instituicao: '',
                ano_formacao: '',
                ind_cursando: false,
                files: null
            },

        };
    },

    components: {
        ComponentMenu,
        ComponentNavBar,
        ComponentNavBar2,
        ComponentFooter,
        Select2,
        ComponentLoadingPage,
        ComponenteValidacao,
        ComponentInfoCoach,
        ComponentDadosGerais
    },
};
</script>
  