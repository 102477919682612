<template>
  <div class="col-12">
    <div class="footer-wrapper" >
      <div class="footer-section f-section-1">
        <p class="">
          Copyright © 2023 - All rights reserved. | Coded with EWTecnologia | Ver.1.0
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ComponentFooter",
//style="position: fixed; bottom: 0"
  mounted() {},
  computed: {},
  data() {
    return {};
  },
  components: {},
};
</script>