import http from '@/components/http';

async function getConfiguracoes() {
    try {
        const response = await http.get('/coach/config')
        console.log(response)
        if (response && response.data && response.status == 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

async function updateConfiguracoes(data) {
    try {
        const response = await http.post('/coach/config', data)
        if (response && response.data && response.status == 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}
export {
    getConfiguracoes,
    updateConfiguracoes
}