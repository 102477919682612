<template>
    <div>
        <div v-if="this.empresa" class="row">
            <div class="col-12">
                <div style="padding-bottom: 10px;" class="w-numeric-value">
                    <div class="w-content">
                        <span class="w-value">Empresa </span>
                    </div>
                </div>
            </div>

        </div>
        <div v-if="this.empresa" class="row">
            <div class="col-12">
                <div class=" widget-card-one">
                    <div class="widget-content">
                        <div class="media">
                            <div class="w-img">
                                <img src="assets/img/90x90.jpg" alt="avatar">
                            </div>
                            <div class="media-body">
                                <h6 class="text-primary">{{  empresa.nome  }}</h6>
                                <div class="header-body" style="line-height: 0.8; margin-top:2px">
                                    <small>{{ empresa.telefone  }} </small><br>
                                    <small>{{  empresa.email }}</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-12 text-right mb-2">
                <button v-if="this.tipo_processo != 'I'" @click="openAdicionarCliente" class="btn btn-primary"> <i class="fa fa-user"> </i> Adicionar
                    Cliente</button>
            </div>
        </div>

        <div class="row">
            <div v-for="cliente in cliente" :key="cliente.codigo" class="col-xl-4 col-lg-6 col-md-6 col-sm-12">
                <div class=" widget-card-one">
                    <div class="widget-content">
                        <div class="media">
                            <div class="w-img">
                                <img src="assets/img/90x90.jpg" alt="avatar">
                            </div>
                            <div class="media-body">
                                <h6 class="text-primary">{{ cliente.nome }}</h6>
                                <div class="header-body" style="line-height: 0.8; margin-top:2px">
                                    <small>{{ cliente.telefone }} </small><br>
                                    <small>{{ cliente.email }}</small> <small><i v-if="this.tipo_processo_prop != 'I'" @click="remove(cliente)"
                                            style=" margin-left: 50px;" v-tippy="'Excluir'"
                                            class="text-danger  btn btn-rounded fa fa-trash"></i></small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row pl-3 pr-2">
            <div class="col-xl-6 col-md-6 col-12  mb-1">
                <div class="form-group">
                    <label>Anotações do início do processo</label>
                    <textarea class="form-control" rows="6" @blur="updateProcesso('inicio_processo')"
                        v-model="anotacao.anotacao_inicio_processo"></textarea>
                </div>
            </div>
            <div class="col-xl-6 col-md-6  col-12 ">
                <div class="form-group ">
                    <label>Devolutiva do Processo</label>
                    <textarea class="form-control" @blur="updateProcesso('devolutiva')"
                        v-model="anotacao.devolutiva_processo" rows="6"></textarea>
                </div>
            </div>
        </div>

        <div v-if="this.tipo_processo_prop != 'I'" class="modal" role="dialog" id="modalCliente" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class=" modal-dialog  modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Adicionar Clientes
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                                aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Cliente</label>
                                    <Select2 @select="verificarClienteAdicionado" ref="select2_cliente"
                                        :settings="{ width: '100%' }" v-model="this.novo_cliente"
                                        :options="lista_cliente" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button @click="adicionarCliente" class="btn btn-primary btn-block">
                            <i class="flaticon-cancel-12"></i> Adicionar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.widget-card-one {
    padding: 10px 0;
}

.widget-card-one .widget-content .media {
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 7px;
    padding-bottom: 15px;
    border-bottom: 1px dashed #e0e6ed;
}
</style>

<script>
import { updateProcesso, removerCliente, adicionarCliente } from '../../processoController';

import { useRoute } from 'vue-router';
import { toast, alert } from "@/components/sweetAlert/index";
import Swal from "sweetalert2";

export default {
    name: "ComponentClientes",

    methods: {

        async updateProcesso(campo) {

            let dados = {};
            switch (campo) {
                case 'inicio_processo':
                    (this.anotacao.anotacao_inicio_processo != this.anotacao.anotacao_inicio_processo_bk) ? dados = { codigo: this.route.params.codigo, anotacao_inicio: this.anotacao.anotacao_inicio_processo } : null;
                    break;
                case 'devolutiva':
                    (this.anotacao.devolutiva_processo != this.anotacao.devolutiva_bk) ? dados = { codigo: this.route.params.codigo, devolutiva: this.anotacao.devolutiva_processo } : null;
                    break;
            }
            if (Object.keys(dados).length != 0) {
                const response = await updateProcesso(dados);
                if ((response && response.data && response.data.erro) || response.status == 'error' || (response && response.response && response.status != 200)) {
                    toast(response.response.data.message || response.message, "error");
                    return
                }
                this.anotacao.devolutiva_bk = this.anotacao.devolutiva_processo;
                this.anotacao.anotacao_inicio_processo_bk = this.anotacao.anotacao_inicio_processo;
                toast(response.message, "success");
            }
        },

        remove(cliente_remover) {
            Swal.fire({
                title: "Deseja remover o cliente do processo?",
                text: "",
                icon: "question",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonColor: "#3085d6",
                reverseButtons: true,
                confirmButtonText: 'Remover',
                allowOutsideClick: false,
            }).then(async (result) => {
                if (result.isConfirmed) {

                    this.$emit('updateIsLoading', true);
                    const dados = {
                        cliente: cliente_remover.cliente_id,
                        processo: cliente_remover.processo_id,
                    }

                    const response = await removerCliente(dados);
                    if ((response && response.data && response.data.erro) || response.status == 'error' || (response && response.response && response.status != 200)) {
                        toast(response.response.data.message || response.message, "error");
                        this.$emit('updateIsLoading', false);
                        return
                    }

                    let index = this.cliente.findIndex((s) => {
                        return s.cliente_id == dados.cliente
                    });

                    (index != -1) ? this.cliente.splice(index, 1) : null
                    this.$emit('atualizaIndicadores', false);
                    this.$emit('updateIsLoading', false);
                    alert('Removido com sucesso', '', 'success');
                }
            });
        },

        openAdicionarCliente() {
            this.novo_cliente = null;
            // eslint-disable-next-line
            $("#modalCliente").modal("show");
        },

        verificarClienteAdicionado({ id }) {
            let cliente = this.cliente.find((c) => {
                return c.cliente_id == id;
            });
            if (cliente) {
                toast('Cliente ' + cliente.nome + ' já adicionado nesse processo', 'error');
                setTimeout(() => {
                    this.novo_cliente = null;
                }, 200)

            }
        },

        async adicionarCliente() {

            if (!this.novo_cliente) {
                toast('Selecione o cliente', 'error');
            }

            this.$emit('updateIsLoading', true);

            const dados = {
                cliente: this.novo_cliente,
                processo: this.processo_id,
            }

            const response = await adicionarCliente(dados);
            if ((response && response.data && response.data.erro) || response.status == 'error' || (response && response.response && response.status != 200)) {
                toast(response.response.data.message || response.message, "error");
                this.$emit('updateIsLoading', false);
                return
            }

            this.$emit('updateIsLoading', false);
            // eslint-disable-next-line
            $("#modalCliente").modal("hide");
            this.novo_cliente = null;

            if(response.data && response.data.cliente_codigo){
                this.cliente.push(response.data);
                this.$emit('atualizaIndicadores');
            }

            alert('Cliente adicionado com sucesso', '', 'success');
        }

    },

    watch: {
        cliente_prop: {
            deep: true,
            handler(newCliente) {
                this.cliente = newCliente;
            }
        },
        lista_cliente_prop: {
            deep: true,
            handler(newCliente) {
                this.lista_cliente = newCliente;
            }
        },
        anotacao_prop: {
            deep: true,
            handler(newAnotacao) {
                this.anotacao = newAnotacao;
            }
        },
        processo_id_prop: {
            deep: true,
            handler(processo_id) {
                this.processo_id = processo_id;
            }
        },
        empresa_prop: {
            deep: true,
            handler(new_empresa) {
                console.log(new_empresa)
                this.empresa = new_empresa;
                
            }
        }
    },
    props: ['cliente_prop', 'anotacao_prop', 'lista_cliente_prop', 'processo_id_prop','tipo_processo_prop','empresa_prop'],
    data() {
        return {
            cliente: this.cliente_prop,
            anotacao: this.anotacao_prop,
            processo_id: this.processo_id_prop,
            route: useRoute(),
            lista_cliente: [],
            novo_cliente: null,
            tipo_processo: this.tipo_processo_prop,
            empresa : this.empresa_prop
        };
    },

}

</script>