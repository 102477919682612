<template>
  <div>
    <ComponentLoadingPage :isLoading="this.isLoading" />
    <ComponentNavBar />
    <!--  BEGIN NAVBAR  -->
    <ComponentNavBar2 :local="this.local" />
    <!--  END NAVBAR  -->

    <!--  BEGIN MAIN CONTAINER  -->
    <div class="main-container" id="container">
      <div class="overlay"></div>
      <div class="search-overlay"></div>

      <ComponentMenu />

      <!--  BEGIN CONTENT AREA  -->
      <div id="content" class="main-content">
        <div class="layout-px-spacing">
          <div class="row" id="cancel-row">
            <div class="col-lg-12 layout-spacing layout-top-spacing">
              <div class="statbox widget box box-shadow">
                <div style="padding-left: 15px; padding-right: 15px" class="widget-header">
                  <div class="row">
                    <div class="col-12">
                      <div style="padding-bottom: 10px;" class="w-numeric-value">
                        <div class="w-content">
                          <span v-if="this.processo && this.processo.tipo_codigo && this.processo.tipo_codigo == 'I'" class="w-value">Processo Individual </span>
                          <span v-else class="w-value">Processo em Grupo </span>
                        </div>
                      </div>
                    </div>

                  </div>
                  <ComponentCabecalho :cabecalho_prop="this.cabecalho" />
                  <ComponentClientes
                  :empresa_prop="(this.processo && this.processo.empresa) ? this.processo.empresa : null"
                   :lista_cliente_prop="this.lista_cliente"
                    :tipo_processo_prop="(this.processo && this.processo.tipo_codigo) ? this.processo.tipo_codigo : 'I' "   :processo_id_prop="(this.processo && this.processo.id) ? this.processo.id : null"
                    :cliente_prop="(this.processo && this.processo.cliente) ? this.processo.cliente : null"
                    :anotacao_prop="this.anotacao" @updateIsLoading="isLoading = $event"
                    @atualizaIndicadores="atualizaIndicadores" />
                  <ComponentSessoes @updateIsLoading="isLoading = $event" @atualizaIndicadores="atualizaIndicadores"
                    :sessao_prop="(this.processo && this.processo.sessao) ? this.processo.sessao : null" />
                  <ComponentLembretes @updateIsLoading="isLoading = $event"  :lembrete_prop="(this.processo && this.processo.lembrete) ? this.processo.lembrete : null" />
                </div>
                <br><br><br><br>
              </div>
            </div>
          </div>

        </div>


        <ComponentFooter />

      </div>
    </div>
  </div>
</template>


<script>
import ComponentMenu from "@/modules/coach/components/menu/ComponentMenu.vue";
import ComponentNavBar from "@/modules/coach/components/navbar/ComponentNavBar.vue";
import ComponentNavBar2 from "@/modules/coach/components/navbar/ComponentNavBar2.vue";
import ComponentFooter from "@/modules/coach/components/footer/ComponentFooter.vue";

import moment from "moment";

// Loading
import ComponentLoadingPage from "@/components/loading/ComponentLoadingPage.vue";
import ComponentClientes from "../components/show/ComponentClientes.vue";
import ComponentSessoes from "../components/show/ComponentSessoes.vue"
import ComponentLembretes from "../components/show/ComponentLembretes.vue";
import ComponentCabecalho from "../components/show/ComponentCabecalho.vue";
// Componentes

// import mensagem from "@/components/validacaoInput/mensagem";
import Swal from "sweetalert2";
import { toast } from "@/components/sweetAlert/index";
import { useRouter, useRoute } from 'vue-router'
import { getShowProcesso } from "../processoController";
import { getCliente } from "../../cliente/clienteController";

import("@/assets/scss/app.css");

//import { FormWizard, TabContent } from "vue3-form-wizard";
import "vue3-form-wizard/dist/style.css";


import('../css/index.css')


export default {
  name: "CadastroClienteView",

  created() {
    this.getProcesso();
  },

  methods: {

    async getCliente(filtro = null) {
      const response = await getCliente(filtro);
      if (response && response.response && response.response.data) {
        toast(response.response.data.message, "error");
        this.isLoading = false;
        return;
      }
      this.lista_cliente = response.data.cliente;

    },

    async getProcesso() {
      if (!this.route.params.codigo) {
        this.$router.push({ name: 'coach-processo' });
        return
      }

      this.isLoading = true;
      const response = await getShowProcesso({ codigo: this.route.params.codigo });
      if ((response && response.data && response.data.erro) || response.status == 'error' || (response && response.response && response.status != 200)) {
        toast(response.response.data.message || response.message, "error");
        this.isLoading = false;

        Swal.fire({
          title: "Processo não localizado",
          html: "Este processo não foi encontrado em nossa base de dados. <br> Verifique as informações e tente novamente",
          icon: "error",

          confirmButtonColor: "#3085d6",
          reverseButtons: true,
          confirmButtonText: 'OK',
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: 'coach-processo' });
           
          }
        });
        return
      }
      
      this.processo = response.data.processo
      if (this.processo.sessao) {
        this.processo.sessao.forEach((x) => {
          x.ind_liberado = (x.ind_liberado == 1) ? true : false;
        })
      }
      this.anotacao = {
        anotacao_inicio_processo: this.processo.anotacao_inicio_processo,
        anotacao_inicio_processo_bk: this.processo.anotacao_inicio_processo,
        devolutiva_processo: this.processo.devolutiva_processo,
        devolutiva_bk: this.processo.devolutiva_processo,
      }

      this.anotacao.devolutiva_processo = this.processo.devolutiva_processo;
      this.cabecalho.total_cliente = this.processo.cliente.length;
      this.atualizaIndicadores();


      if (this.processo.tipo_codigo != 'I') {
        let filtro = {
          ativo: 1,
          tipo_query: 'select'
        };
        (this.processo.empresa_id != 0) ? filtro.empresa_id = this.processo.empresa_id : null;
        await this.getCliente(filtro);
      }
      this.isLoading = false;
    },

    atualizaIndicadores() {
      this.calcularPercentFinalizados();
      this.cabecalho.total_cliente = this.processo.cliente.length;
    },

    calcularPercentFinalizados() {
      let total_processos = this.processo.sessao.length;
      let total_processos_finalizados = 0;
      this.processo.sessao.forEach(element => {
        (element.ind_finalizado == 1) ? total_processos_finalizados++ : null;
      });

      if (total_processos > 0) {
        if (total_processos_finalizados > 0) {
          total_processos_finalizados = (total_processos_finalizados * 100) / total_processos;
        }
      }
      total_processos_finalizados = total_processos_finalizados.toFixed(1);
      total_processos_finalizados = total_processos_finalizados.replace('.', ',');
      this.cabecalho.processo_finalizado = total_processos_finalizados
      return total_processos_finalizados
    }

  },



  data() {

    return {
      local: "Coach | Visualizar Processo ",
      moment,

      cabecalho: {
        processo_finalizado: 0,
        total_cliente: 0
      },

      anotacao: {
        devolutiva: '',
        anotacao_inicio: ''
      },

      isLoading: false,
      processo: null,
      router: useRouter(),
      route: useRoute(),
      lista_cliente: []
    }


  },



  components: {
    ComponentMenu,
    ComponentNavBar,
    ComponentNavBar2,
    ComponentFooter,
    ComponentLoadingPage,
    ComponentClientes,
    ComponentSessoes,
    ComponentLembretes,
    ComponentCabecalho
  },
};
</script>
  