import axios from 'axios';
import http from '@/components/http';
import { getUser, getToken } from './getters';

// Realiza a autenticação do Coach
export const ActionAuth = async(context, payload) => {
    try {
        const response = await axios.post('/auth/coach', payload);
        return response; // retorna o resultado da chamada axios
    } catch (error) {
        return error;
    }
}

export const ActionLoadAuth = async() => {
    try {
        const response = await http.post('/auth/checkSession');
        return response; // retorna o resultado da chamada axios
    } catch (error) {
        return error;
    }
}

export const ActionSetUser = ({ commit }, payload) => {
    commit('setUserStore', payload)
}

export const ActionSetToken = ({ commit }, payload) => {
    commit('setTokenStore', payload);
}

export const ActionGetToken = () => {
    return getToken();
}

export const ActionGetUser = () => {
    return getUser();
}