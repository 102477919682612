<template>
  <div class="statbox widget box box-shadow">
    <div style="padding-left: 15px; padding-right: 15px" class="widget-header">
      <div class="row">
        <div class="col-12 mb-2">
          <h4 style="
                          padding: 0px;
                          margin-left: 20px;
                          margin-top: 20px;
                        ">
            Meus processos ativos
          </h4>
          <hr style="margin-bottom: 0px" />
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-12 mb-2">
          <button @click="openModalNovoProcesso" style="margin-top: 7px; margin-right: 10px" class="btn btn-primary">
            <i class="fa fa-box" aria-hidden="true"></i>&nbsp;&nbsp; Novo
            Processo
          </button>
          <button @click="openModalLocalizar" style="margin-top: 7px; margin-right: 10px" class="btn btn btn-info">
            <i class="fa fa-search" aria-hidden="true"></i>&nbsp;&nbsp;
            Localizar
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <input type="text" maxlength="250" placeholder="Localizar na tabela..."
            class="form-control form-control-sm input-search-dataTable" v-model="search_value_table" />
          <div class="table-responsive mb-4">
            <easy-data-table :loading="table_loading" :language="customLanguage" :search-value="search_value_table"
              :search-field="searchField" :headers="headers" :items="lista_processo" :key="lista_processo.codigo"
              buttons-pagination>
              <template #item-data_inicio="item">
                {{ moment(item.data_inicio).format('DD/MM/YYYY H:mm') }}
              </template>
              <template #item-frequencia_codigo="item">
                {{ this.getFrequenciaDescricao(item.frequencia_codigo) }}
              </template>
              <template #item-tipo="item">
                <span :class="item.class_tipo"> {{ item.tipo }} </span>
              </template>
              <template #item-situacao="item">
                <span :class="item.class_situacao"> {{ item.situacao }} </span>
              </template>
              <template #item-operation="item">
                <div class="operation-wrapper">
                  <router-link style="cursor: pointer; font-size: 0.6rem"  :to="`/coach/processo/${item.codigo}`"
                                class="badge badge-classic badge-info">Abrir</router-link>&nbsp; &nbsp; 

                  <i style="cursor: pointer" @click="remove(item.codigo)" class="fas fa-trash fa-md text-danger"
                    v-tippy="'Excluir'"></i>
                </div>
              </template>

            </easy-data-table>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade fade-right" id="modalFiltro" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-right modal-dialog  modal-md" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Localizar Processos
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                aria-hidden="true">&times;</span></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label>Nome do processo</label>
                  <input type="text" v-model="this.filtro.nome" class="form-control form-control-sm" />
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label>Data início do processo</label>
                  <input type="date" v-model="this.filtro.data_inicio" class="form-control form-control-sm" />
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label></label>
                  <input type="date" v-model="this.filtro.data_fim" class="form-control form-control-sm" />
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label>Tipo</label>
                  <select v-model="this.filtro.tipo" class="form-control form-control-sm">
                    <option value="T">Todos</option>
                    <option value="I">Individual</option>
                    <option value="G">Em grupo</option>
                  </select>
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                <div class="form-group">
                  <label>Status</label>
                  <select v-model="this.filtro.status" class="form-control form-control-sm">
                    <option value="T">Todos</option>
                    <option value="0">Em processo</option>
                    <option value="1">Finalizado</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button @click="newLocalizar" class="btn btn-primary btn-block">
              <i class="flaticon-cancel-12"></i> Localizar
            </button>
            <button @click="clearFiltro" class="btn  btn-block">
              <i class="flaticon-cancel-12"></i> Limpar
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import { getProcesso, getFrequencia } from "../../processoController"
import { toast } from "@/components/sweetAlert/index";
import Swal from "sweetalert2";

import moment from "moment";
import("@/assets/scss/modalRight.css");

export default {
  name: "ComponentTabela",

  setup() {
    return {

    };
  },

  mounted() {
    this.getProcesso(
      {
        limit: 200
      }
    );
  },

  created() {
    this.filtro_inicial = { ...this.filtro };
  },

  props: ['isLoadingProp'],

  methods: {
    openModalNovoProcesso() {
      // Emite o evento para chamar a função no componente pai
      this.$emit('openModalNovoProcesso');
    },

    openModalLocalizar() {

      // eslint-disable-next-line
      $("#modalFiltro").modal("show");
    },
    getFrequenciaDescricao(codigo) {
      let f = this.frequencia.find(x => {
        return x.id == codigo;
      });
      return (f.text) ? f.text : 'Não encontrado'
    },

    clearFiltro() {
      this.filtro = { ...this.filtro_inicial };
    },

    async getProcesso(filtro) {
      this.table_loading = true;
      let response = await getProcesso(filtro);

      if ((response && response.data && response.data.erro) || response.status == 'error' || (response && response.response && response.status != 200)) {
        toast(response.response.data.message || response.message, "error");
        this.isLoading = false;
        return;
      }
      this.table_loading = false;
      this.lista_processo = response.data.processos
    },

    async newLocalizar() {
      // eslint-disable-next-line
      $("#modalFiltro").modal("hide");
      console.log(this.filtro)
      await this.getProcesso(this.filtro)
    },

    remove(processo) {
      Swal.fire({
        title: "Deseja remover esse processo?",
        text: "Ao remover, não será mais possível acessá-lo ou recuperar as informações.",
        icon: "question",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonColor: "#3085d6",
        reverseButtons: true,
        confirmButtonText: 'Excluir',
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          
          console.log(processo)
        }
      });
    },


  },

  data() {

    return {
      moment: moment,
      name: 'tableProcesso',
      search_value_table: "",
      table_loading: false,
      searchField: ["cliente", "nome", "tipo", "situacao"],
      headers: [
        { text: "", value: "operation", width: 90 },
        { text: "Alertas", value: "avisos", width: 70 },
        { text: "Cliente", value: "cliente", sortable: true },
        { text: "Nome", value: "nome", sortable: true },
        { text: "Tipo", value: "tipo", sortable: true },
        { text: "Frequência", value: "frequencia_codigo", sortable: true },
        { text: "Encontros", value: "sessoes", sortable: true },
        { text: "Realizados", value: "realizados", sortable: true },
        {
          text: "Data início", value: "data_inicio", sortable: true,
          formatter: (value) => {
            return moment(value).format('DD/MM/YYYY H:mm');
          }
        },
        { text: "Situação", value: "situacao", sortable: true }
      ],
      lista_processo: [],
      frequencia: getFrequencia(),

      filtro: {
        nome: '',
        data_inicio: null,
        data_fim: null,
        tipo: 'T',
        status: 'T',
      },
      filtro_inicial: {},



    }
  }

}



</script>