<template>
    <div>
        <h v-for="error of errorsValidation" :key="error.$uid" role="alert">{{ error.$message }}</h>
    </div>
</template>

<script>

import('../validacaoInput/index.css');

export default {
    name: "ComponenteValidacao",
    props: {
        errorsValidation: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
        }
    }
}
</script>

