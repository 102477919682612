export default {
    coach : {
        financeiro : {},
        config : {},
        alertas : {},
        mensagens : {},
        complementar : {},
        experiencias : {},
        formacao : {}
    }
    
}