
export default {

    setUserStore (state, payload){
        state.user = payload
    },

    setTokenStore (state, payload){
        state.token = payload
    }
    
}