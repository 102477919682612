import http from '@/components/http';



async function liberarBloquear(dados) {
    try {
        const response = await http.post('/sessao/status', dados);
        if (response && response.data && response.status == 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

async function remover(dados) {
    try {
        const response = await http.post('/sessao/delete', dados);
        if (response && response.data && response.status == 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

async function adicionar(dados) {
    try {
        const response = await http.post('/sessao/adicionar', dados);
        if (response && response.data && response.status == 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

export {
    liberarBloquear,
    remover,
    adicionar
}