<template>
  <div>
    <ComponentLoadingPage v-bind:isLoading="isLoading" />
    <ComponentNavBar />
    <!--  BEGIN NAVBAR  -->
    <ComponentNavBar2 :local="local" />
    <!--  END NAVBAR  -->

    <!--  BEGIN MAIN CONTAINER  -->
    <div class="main-container" id="container">
      <div class="overlay"></div>
      <div class="search-overlay"></div>

      <ComponentMenu />

      <!--  BEGIN CONTENT AREA  -->
      <div id="content" class="main-content">
        <div class="layout-px-spacing">
          <div class="row" id="cancel-row">
            <div class="col-lg-12 layout-spacing layout-top-spacing">
              <ComponentIndicador ref="indicador_processo"  />
              <ComponentTabela ref="tabela_processo"  @openModalNovoProcesso="openModalNovoProcesso" :isLoadingProp="isLoading" :lista_processo_prop="lista_processo" />
            </div>
          </div>
        </div>
        <ComponentModalNovo  @atualizarIndicador="atualizarIndicador" @atualizarTabela="atualizarTabela" ref="componentModalNovo" :validation_prop="validations" :v_prop="v$" :processoProp="processo" :listaClientesProp="lista_clientes"
          :isLoadingProp="isLoading" />
        <br /><br /><br />

          <div class="modal" id="modalFiltro" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-md" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Localizar Cliente
              </h5>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group">
                    <label>Nome ou Razão Social</label>
                    <input type="text" class="form-control form-control-sm" />
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group">
                    <label>E-mail</label>
                    <input type="email" class="form-control form-control-sm"  />
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-6 col-xs-12">
                  <div class="form-group">
                    <label>CPF ou CNPJ</label>
                    <input type="text" maxlength="15" v-mask="'00000000000000'" class="form-control form-control-sm"
                      />
                    <small>Sem pontuação</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn" data-dismiss="modal">
                <i class="flaticon-cancel-12"></i> Cancelar
              </button>
              <button @click="newLocalizar" class="btn btn-primary">
                <i class="flaticon-cancel-12"></i> Localizar
              </button>
            </div>
          </div>
        </div>
      </div>
        <ComponentFooter />
      </div>
    </div>
  </div>
</template>


<script>
import ComponentMenu from "@/modules/coach/components/menu/ComponentMenu.vue";
import ComponentNavBar from "@/modules/coach/components/navbar/ComponentNavBar.vue";
import ComponentNavBar2 from "@/modules/coach/components/navbar/ComponentNavBar2.vue";
import ComponentFooter from "@/modules/coach/components/footer/ComponentFooter.vue";

import moment from "moment";

// Loading
import ComponentLoadingPage from "@/components/loading/ComponentLoadingPage.vue";

// Componentes
import ComponentIndicador from "../components/processo/ComponentIndicador.vue";
import ComponentTabela from "../components/processo/ComponentTabela.vue";
import ComponentModalNovo from "../components/novo/ComponentModalNovo.vue";


// import mensagem from "@/components/validacaoInput/mensagem";
//import Swal from "sweetalert2";
//import { toast } from "@/components/sweetAlert/index"; 

/* import {
  store,
  getCliente,
  getIndicadorCliente,
  updateAcesso,
} from "../clienteController"; */

import("@/assets/scss/app.css");

//import { FormWizard, TabContent } from "vue3-form-wizard";
import "vue3-form-wizard/dist/style.css";

import('../css/index.css')


export default {
  name: "CadastroClienteView",
  mounted() {

  },

  created() {

  },

  methods: {

    async atualizarTabela() {
      // Chamar método do Filho2
      await this.$refs.tabela_processo.getProcesso();
    },
    async atualizarIndicador() {
      // Chamar método do Filho2
      await this.$refs.indicador_processo.getIndicador();
    },

    openModalNovoProcesso() {
      this.$refs.componentModalNovo.novoProcesso();
    },



  },

  computed: {},
  data() {
    return {
      local: "Coach | Meus Processos",
      moment: moment,
      isLoading: false,
      processo: {
        tipo: 'I',
        clientes: [],
        nome: '',
        empresa: 0,
        objetivo: '',
        sessoes: '',
        data_inicio: '',
        frequencia: '',
        tipo_grupo: ''
      },
      processo_original: null,
      lista_processo : [],
      
    }
  },

  components: {
    ComponentMenu,
    ComponentNavBar,
    ComponentNavBar2,
    ComponentFooter,
    ComponentLoadingPage,
    ComponentIndicador,
    ComponentTabela,
    ComponentModalNovo

  },
};
</script>
  