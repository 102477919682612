<template>
  <div>
    <ComponentLoadingPage :message="message_loading" v-bind:isLoading="isLoading" />
    <div class="modal" role="dialog" id="novoTipoFerramenta" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Tipo de Ferramenta
            </h5>
          </div>
          <div class="modal-body">
            <div class="col-12">
              <div class="d-flex justify-content-around">
                <div class="infobox-1 col mb-1">
                  <div class="info-icon d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-list">
                      <line x1="8" y1="6" x2="21" y2="6"></line>
                      <line x1="8" y1="12" x2="21" y2="12"></line>
                      <line x1="8" y1="18" x2="21" y2="18"></line>
                      <line x1="3" y1="6" x2="3.01" y2="6"></line>
                      <line x1="3" y1="12" x2="3.01" y2="12"></line>
                      <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>
                    <h5 class="info-heading ml-3">Questionário</h5>
                  </div>
                  <a class="info-link cursor-pointer" @click="openModalTipoFerramentaStep2(1)">Continuar
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-arrow-right">
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline>
                    </svg></a>
                </div>
                <div class="infobox-1  col mb-1">
                  <div class="info-icon d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-pie-chart">
                      <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                      <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                    </svg>
                    <h5 class="info-heading ml-3">Rota</h5>
                  </div>
                  <a class="info-link cursor-pointer" @click="openModalTipoFerramentaStep2(2)">Continuar
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-arrow-right">
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline>
                    </svg></a>
                </div>
              </div>
              <div class="d-flex justify-content-around">
                <div class="infobox-1  col mb-1">
                  <div class="info-icon d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-layout">
                      <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                      <line x1="3" y1="9" x2="21" y2="9"></line>
                      <line x1="9" y1="21" x2="9" y2="9"></line>
                    </svg>
                    <h5 class="info-heading ml-3">Tabela</h5>
                  </div>
                  <a class="info-link" href="">Continuar
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-arrow-right">
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline>
                    </svg></a>
                </div>
                <div class="infobox-1  col mb-1">
                  <div class="info-icon d-flex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-triangle">
                      <path
                        d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z">
                      </path>
                    </svg>
                    <h5 class="info-heading ml-3">Prirâmide</h5>
                  </div>
                  <a class="info-link" href="">
                    Continuar<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                      fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                      class="feather feather-arrow-right">
                      <line x1="5" y1="12" x2="19" y2="12"></line>
                      <polyline points="12 5 19 12 12 19"></polyline>
                    </svg></a>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Cancelar
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal" role="dialog" id="novoTipoFerramentaStep2" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              Tipo de Ferramenta
            </h5>
          </div>
          <div class="modal-body">
            <div class="col-12" v-if="tipo_ferramenta.tipo == 1">
              <div class="row">
                <div class="col-12">
                  <div class="input-group">
                    <div class="form-group">
                      <label for="disabledSelect">Formato do Campo</label>
                      <select @change="selectTipoFerramenta" v-model="tipo_ferramenta.formato" style="width: 300px"
                        class="form-control">
                        <option :value="null">Selecione uma opção</option>
                        <option v-for="(formato, index) in lista_formato_questionario" :value="formato" :key="index">
                          {{ formato.text }}
                        </option>
                      </select>
                    </div>
                    <div class="input-group-append">
                      <button style="height: 42px; margin-top: 22px" @click="addCampoTipoFerramenta"
                        class="btn btn-success">
                        <span class="fa fa-plus"></span>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="dflex align-items-end"></div>
              </div>
              <div>
                <div v-for="(campo, index) in this.tipo_ferramenta.campos" :key="index" class="row mb-3">
                  <div class="col-12">
                    <div class="form-group pergunta">
                      <label>Pergunta do Campo {{ index + 1 }} - ({{
      campo.formato.text
    }})</label>
                      <textarea v-model="campo.pergunta" type="text" class="form-control form-control-sm" />
                    </div>
                  </div>
                  <div v-if="[3, 5, 6].includes(campo.formato.id)" class="col-xl-6 col-md-6 col-12">
                    <div v-if="[3].includes(campo.formato.id)" class="form-group">
                      <label>Múltiplas escolhas?
                        <small class="text-danger">*</small></label>
                      <Select2 :settings="settingsSelectTipo" v-model="campo.multiple"
                        :id="'multipla_escolha_' + index + '_' + generateUniqueId()"
                        :ref="'multipla_escolha_' + index + '_' + generateUniqueId()" :options="multiplo_select" />
                      <small>Se será possível selecionar mais de 1 opção
                        disponível.</small>
                    </div>
                  </div>
                  <div v-if="[3, 4, 5].includes(campo.formato.id)" class="col-12">
                    <div class="form-group">
                      <label v-if="[3].includes(campo.formato.id)">Opções do Select<small
                          class="text-danger"></small></label>
                      <label v-if="[5].includes(campo.formato.id)">Opções do Radio Button<small
                          class="text-danger"></small></label>
                      <label v-if="[4].includes(campo.formato.id)">Opções do CheckBox<small
                          class="text-danger"></small></label>
                      <Select2 :settings="{
      width: '100%',
      multiple: true,
      tags: true,
    }" v-model="campo.options" :id="'tipo_multipla_escolha_' + index + '_' + generateUniqueId()"
                        :ref="'tipo_multipla_escolha_' + index + '_' + generateUniqueId()" />
                      <small>Insira as opções desejadas no campo acima e pressione
                        'Enter' para adicionar.</small>
                    </div>
                  </div>
                  <div v-if="[9].includes(campo.formato.id)" class="col-xl-8 col-md-8 col-12">
                    <label>Informe o valor inicial e final
                      <small class="text-danger">*</small></label>
                    <div class="d-flex justify-content-center">
                      <input class="form-control form-control-sm" @blur="verificarRange(campo.config)"
                        v-model.number="campo.config.valor_inicial" v-mask="'000000'" maxlength="5" type="text">
                      <input class="form-control form-control-sm ml-2" @blur="verificarRange(campo.config)"
                        v-model.number="campo.config.valor_final" v-mask="'000000'" maxlength="5" type="text">
                    </div>

                    <small>Por exemplo, começa em 0 (zero) e termina em 100.</small><br>
                    <label>Identificador
                      <small class="text-danger">*</small></label>
                    <input class="form-control form-control-sm" v-model="campo.config.identificador" maxlength="15"
                      type="text">
                    <small>Que tipo de intervalo? Ex %, Qtd, R$, etc.</small>
                  </div>
                  <div v-if="[8].includes(campo.formato.id)" class="col-xl-4 col-6">
                    <label>Moeda
                      <small class="text-danger">*</small></label>
                    <input class="form-control form-control-sm" v-model="campo.config.identificador" maxlength="15"
                      type="text">
                    <small>Ex: R$, USD, Etc</small>
                  </div>
                  <div class="col-12">
                    <button @click="removeCampoTipoFerramenta(index)" class="btn btn-danger btn-sm btn-block">
                      <span class="fa fa-trash"></span> Remover
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12" v-if="tipo_ferramenta.tipo == 2">
            <div class="col-12">
              <div class="form-group pergunta">
                <label>Informações adicionais</label>
                <textarea v-model="tipo_ferramenta.config.descricao" type="text" class="form-control form-control-sm" />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group pergunta">
                <label>Quantidade de Campos</label>
                <input class="form-control form-control-sm" v-model="tipo_ferramenta.config.quantidade_campos"
                  maxlength="2" type="number">
              </div>
            </div>
            <div class="col-12 mt-2 mb-2"><button class="btn btn-block btn-success"
                @click="adicionarCamposRoda()">Adicionar Campos</button></div>
            <div class="row">
              <div class="col-xl-6 col-12">
                <div class="col-12 mt-2 mb-2" v-for="(campo, index) in  tipo_ferramenta.config.campos" :key="index">
                  <input class="form-control form-control-sm" @blur="atualizarLabels"
                    v-model="tipo_ferramenta.config.campos[index]" type="text">
                </div>
              </div>
              <div class="col-xl-6 col-12">
                <apexchart width="400" type="polarArea" ref="chartPolarArea" :options="chartOptions"
                  :series="chartOptions.series"></apexchart>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button class="btn" data-dismiss="modal">
              <i class="flaticon-cancel-12"></i> Cancelar
            </button>
            <button @click="adicionarTipoFerramenta" class="btn btn-success">
              <i class="flaticon-cancel-12"></i> Adicionar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.info-link {
  cursor: pointer;
}

.info-icon {
  align-items: center;
}

.select2-container {
  min-width: 180px !important;
}

.pergunta {
  margin-bottom: 1px !important;
}

.infobox-1 {
  border: 1px solid #e0e6ed;
  width: auto;
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0px 2px 10px 1px rgba(31, 45, 61, 0.1);
  margin-right: 2px;
  margin-left: 2px;
}
</style>

<script>
// Loading
import ComponentLoadingPage from "@/components/loading/ComponentLoadingPage.vue";
import "vue3-form-wizard/dist/style.css";
// Validação
//import { useVuelidate } from "@vuelidate/core";
//import { required, helpers, requiredIf, between, integer } from "@vuelidate/validators";
//import ComponenteValidacao from "@/components/validacaoInput/ComponentValidacao";
//import mensagem from "@/components/validacaoInput/mensagem";
import { toast } from "@/components/sweetAlert/index";
import moment from "moment";
import { Apexchart } from 'vue3-apexcharts';
import { getCamposQuestionario, storeTipoFerramenta } from '../../ferramentaController';
export default {
  name: "ComponentModalNovo",

  props: {
    ferramentaProp: {
      type: Object,
      required: true,
    },
  },

  created() {
    this.init();
  },



  methods: {

    async init() {

      this.getFerramentaCampos();

    },
    async getFerramentaCampos() {

      this.isLoading = true;
      const response = await getCamposQuestionario();

      if (response && response.response && response.response.data) {
        toast(response.response.data.message, "error");
        this.isLoading = false;
        return;
      }

      this.isLoading = false;
      this.lista_formato_questionario = response.data;

    },

    atualizarLabels() {
      // Modificar os rótulos conforme necessário
      this.chartOptions.labels = this.tipo_ferramenta.config.campos;
      this.$refs.chartPolarArea.updateOptions({
        labels: this.chartOptions.labels
      });
    },

    adicionarCamposRoda() {
      if (this.tipo_ferramenta.config.quantidade_campos <= '0' || this.tipo_ferramenta.config.quantidade_campos > 20) {
        toast("A quantidade deve ser entre 1 e 20"); return
      }
      let bk_campos = [...this.tipo_ferramenta.config.campos];

      this.tipo_ferramenta.config.campos = [];
      this.chartOptions.series = [];


      for (let i = 1; i <= this.tipo_ferramenta.config.quantidade_campos; i++) {
        this.tipo_ferramenta.config.campos.push('');
        this.chartOptions.series.push(Math.floor(Math.random() * 101));
      }

      if (bk_campos.length) {
        bk_campos = bk_campos.filter(elemento => elemento !== '');
        for (let i = 0; i < bk_campos.length; i++) {
          if (i < this.tipo_ferramenta.config.campos.length) {
            this.tipo_ferramenta.config.campos[i] = bk_campos[i];
          } else {
            break;
          }
        }
      }
    },

    progressBarCount($progressCount) {
      var elements = document.querySelectorAll($progressCount);
      for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener('input', function () {
          let getValueOfRangeSlider = this.value;
          let getParentElement = this.closest(".custom-progress").querySelector('.range-count-number');
          getParentElement.innerHTML = getValueOfRangeSlider;
          getParentElement.setAttribute("data-rangeCountNumber", getValueOfRangeSlider)
        });
      }
    },
    verificarRange(campo) {
      if (campo.valor_inicial && campo.valor_final) {
        if (campo.valor_final <= campo.valor_inicial) {
          campo.valor_final = null;
          toast("Valor final deve ser maior que o valor inicial", "error");
        }
      }
    },

    generateUniqueId() {
      return moment().format('YYYYMMDDHHmmssSSS');
    },
    novoTipoFerramentaStep1() {

      this.tipo_ferramenta = null;
      this.tipo_ferramenta = { ...this.config_tipo_ferramenta };
      this.tipo_ferramenta.campos = [];

      // eslint-disable-next-line
      $("#novoTipoFerramenta").modal("show");
    },
    openModalTipoFerramentaStep2(tipo) {

      this.tipo_ferramenta.tipo = tipo;
      if (tipo == 2) {
        this.tipo_ferramenta.config = { ...this.config_roda }
        this.tipo_ferramenta.config.campos = [];
        this.chartOptions.series = [];
        this.chartOptions.labels = [];
      }


      // eslint-disable-next-line
      $("#novoTipoFerramenta").modal("hide");
      // eslint-disable-next-line
      $("#novoTipoFerramentaStep2").modal("show");
    },

    addCampoTipoFerramenta() {

      if (this.tipo_ferramenta.campos.length) {
        return
      }
      if (this.tipo_ferramenta.formato == null) {
        toast("Informe um tipo de campo válido", "error");
      } else {
        let config = { ...this.config_questionario };
        config.options = [];
        config.formato = { ...this.tipo_ferramenta.formato };
        // Range
        if (config.formato.id == 9) {
          config.config = {
            valor_inicial: null,
            valor_final: null,
            identificador: null
          }
        }
        if (config.formato.id == 8) {
          config.config = {
            identificador: null
          }
        }
        this.tipo_ferramenta.campos.push(config);
      }
    },

    removeCampoTipoFerramenta(index) {
      this.tipo_ferramenta.campos.splice(index, 1);
    },

    selectFormatoQuestionaro() {
      if (this.tipo_ferramenta.formato.id == 3) {
        //this.tipo_ferramenta.config.multiple = null;
      }
    },

    getOrdemAtual() {
      let ordem = 0;
      this.ferramenta.tipo.forEach(tipo => {
        if (tipo.tipo == 1) {
          if (tipo.ordem > ordem) {
            ordem = tipo.ordem;
          }
        }
        if (tipo.tipo == 2) {
          if (tipo.config.ordem > ordem) {
            ordem = tipo.config.ordem;
          }
        }
      });

      return ordem;
    },


    async adicionarTipoFerramenta() {

      if (this.tipo_ferramenta.tipo == 1) {
        if (this.tipo_ferramenta.campos.length) {

          let tipo_copy = { ...this.tipo_ferramenta }
          tipo_copy.campo = {};
          delete tipo_copy.campos;

          let campo = this.tipo_ferramenta.campos[0];

          if (campo.options.length) {
            campo.options_object = [];
            campo.options.forEach((opt) => {
              campo.options_object.push({
                id: opt
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
                  .replace(/[^\w\s]/gi, "")
                  .replace(/\s+/g, ""), // remove os acentros e os espaços
                text: opt,
              });
            });
          }

          if (campo.id == 10) {
            campo.config = {
              range: [campo.valor_inicial, campo.valor_final]
            }
          }

          let novo_tipo = { ...tipo_copy }
          novo_tipo.ordem = this.getOrdemAtual() + 1;
          novo_tipo.campo = campo;
          novo_tipo.ativo = 1;

          // prepara o objeto para ser inserido
          let dados_insert = { ...novo_tipo.campo };
          dados_insert.ferramenta_id = this.ferramenta.id;
          dados_insert.ferramenta_codigo = this.ferramenta.codigo;
          dados_insert.ordem = novo_tipo.ordem;
          dados_insert.tipo = this.tipo_ferramenta.tipo;
          dados_insert.formato_id = this.tipo_ferramenta.formato.id;
          dados_insert.estrutura1 = (dados_insert.estrutura1) ? JSON.stringify(dados_insert.estrutura1) : null;
          dados_insert.estrutura1 = (dados_insert.estrutura2) ? JSON.stringify(dados_insert.estrutura2) : null;
          delete dados_insert.formato;
          delete dados_insert.options
          dados_insert.options = (campo.options_object) ? JSON.stringify(campo.options_object) : null;
          dados_insert.config = (campo.config) ? JSON.stringify(campo.config) : null;
          delete dados_insert.options_object;
          dados_insert.titulo = dados_insert.pergunta;
          this.isLoading = true;

          const response = await storeTipoFerramenta(dados_insert);
          if (response && response.response && response.response.data) {
            toast(response.response.data.message, "error");
            this.isLoading = false;
            return;
          }
          this.isLoading = false;
          novo_tipo.codigo = response.data.codigo;
          novo_tipo.id = response.data.id;

          this.ferramenta.tipo.push(novo_tipo);

        }

      } else if (this.tipo_ferramenta.tipo == 2) {
        this.tipo_ferramenta.chartOptions = { ...this.chartOptions };
        this.tipo_ferramenta.ordem = this.getOrdemAtual() + 1;
        this.tipo_ferramenta.ativo = 1;
        // prepara o objeto para ser inserido
        let dados_insert = { ...this.tipo_ferramenta };
        dados_insert.ferramenta_id = this.ferramenta.id;
        dados_insert.ferramenta_codigo = this.ferramenta.codigo;
   
        dados_insert.formato_id = null;
        dados_insert.estrutura1 = (dados_insert.estrutura1) ? JSON.stringify(dados_insert.estrutura1) : null;
        dados_insert.estrutura1 = (dados_insert.estrutura2) ? JSON.stringify(dados_insert.estrutura2) : null;
        delete dados_insert.formato;
        delete dados_insert.options
        dados_insert.titulo = dados_insert.config.descricao;
        dados_insert.options =  null;
        let campos = dados_insert.config.campos;
        dados_insert.config = {
            campos : campos,
        }
        dados_insert.estrutura2 = null;
        dados_insert.config = JSON.stringify(dados_insert.config);
        delete dados_insert.chartOptions;
        delete dados_insert.campos;
        dados_insert.versao = 1;
        dados_insert.options_object == null;
        this.isLoading = true;
        const response = await storeTipoFerramenta(dados_insert);
        this.isLoading = true;
        if (response && response.response && response.response.data) {
          toast(response.response.data.message, "error");
          this.isLoading = false;
          return;
        }
        this.tipo_ferramenta.codigo = response.data.codigo;
        this.tipo_ferramenta.id = response.data.id;
        this.isLoading = false;

        this.ferramenta.tipo.push(this.tipo_ferramenta);
      } else {
        toast("Tipo de ferramenta inválida", "error");
      }
      // eslint-disable-next-line
      $("#novoTipoFerramentaStep2").modal("hide");
      toast("Tipo de Ferramenta adicionada com sucesso", "success");

      this.tipo_ferramenta = {};
      setTimeout(() => {
        this.progressBarCount('.progress-range-counter');
      }, 500);

    },
  },

  data() {
    return {
      moment,
      message_loading: "Aguarde...",
      tipo_ferramenta: {},
      ferramenta: this.ferramentaProp,
      isLoading: false,
      chartOptions: {
        series: [],
        chart: {
          type: 'polarArea',
        },
        labels: [],
        stroke: {
          colors: ['#fff']
        },
        fill: {
          opacity: 0.8
        },
        legend: {
          position: 'bottom'
        },
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
      config_tipo_ferramenta: {
        tipo: null,
        campos: [],
        config: null,
      },
      multiplo_select: [
        {
          id: 1,
          text: "Sim",
        },
        {
          id: 2,
          text: "Não",
        },
      ],
      settingsSelectTipo: {
        width: "100%",
      },
      config_questionario: {
        mascara: null,
        operador: null,
        pergunta: null,
        obrigatorio: null,
        options: null,
        multiple: null,
        ordem: null,
        config: null,
        versao: 1,
        max_length: null,
        formato: null,
        options_object: null,
      },
      config_roda: {
        codigo: null,
        ordem: null,
        descricao: null,
        quantidade_campos: null,
        campos: []
      },
      lista_formato_questionario: [],
    };
  },
  components: {
    ComponentLoadingPage,
    apexchart: Apexchart
  },
};
</script>
