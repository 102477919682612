<template>
  <div class="home">
    <!--  BEGIN MAIN CONTAINER  -->
    <div class="main-container d-flex justify-content-center align-items-center min-vh-100" id="container">
        <!--  BEGIN CONTENT AREA  -->
        <div id="content" class=" main-content d-flex justify-content-center">
          <div class="card p-4">
            <router-link
              :to="{ path: '/coach/dashboard' }"
              data-toggle="collapse"
              aria-expanded="false"
              class="btn btn-primary"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-home"
              >
                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                <polyline points="9 22 9 12 15 12 15 22"></polyline>
              </svg>
              Login
            </router-link>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data() {
    return {};
  },
  mounted() {},
  computed: {},
  components: {}
};
</script>

<style scoped>
.main-container {
  background-color: #f8f9fa; /* Cor de fundo clara */
}
.card {
  max-width: 400px; /* Largura máxima do card */
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075); /* Sombra suave */
}
</style>
