<template>
    <div>
        <div class="row mb-3">
            <div class="col-6">
                <div style="padding-bottom: 10px;" class="w-numeric-value">
                    <div class="w-content">
                        <span class="w-value">Lembretes </span>
                    </div>
                </div>
            </div>
            <div class="col-6 text-right mt-1">
                <button @click="openNovoLembrete" class="btn btn-info mr-1"><i class="fa fa-comment"></i> Novo
                    lembrete</button>
            </div>
        </div>
        <div class="row mb-3">

            <div  v-if="!this.lembrete" class="col-12">
                <div  class="alert alert-info">Nenhum lembrete cadastrado</div>
            </div>

            <div v-for="lembrete in this.lembrete" :key="lembrete.codigo" class="col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-1">
                <div class="widget-table-one">
                    <div class="widget-content">
                        <div class="transactions-list">
                            <div class="t-item">
                                <div class="t-company-name">
                                    <div class="t-icon">
                                        <div class="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                                                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                                stroke-linecap="round" stroke-linejoin="round"
                                                class="feather feather-feather">
                                                <path d="M20.24 12.24a6 6 0 0 0-8.49-8.49L5 10.5V19h8.5z"></path>
                                                <line x1="16" y1="8" x2="2" y2="22"></line>
                                                <line x1="17.5" y1="15" x2="9" y2="15"></line>
                                            </svg>
                                        </div>
                                    </div>
                                    <div class="t-name">
                                        <h4 style="padding: 0;">{{ lembrete.descricao }}</h4>
                                        <p class="meta-date">{{ moment(lembrete.created_at).format('DD/MM/YYYY H:mm') }}
                                        </p>
                                    </div>

                                </div>
                                <div class="t-rate rate-dec">
                                    <i style="cursor: pointer;" @click="remove(lembrete)" class=""><i
                                            class="fa fa-trash text-danger"></i></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="modal" role="dialog" id="modalNovoLembrete" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class=" modal-dialog  modal-md" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            Novo Lembrete
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                                aria-hidden="true">&times;</span></button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Descrição</label>
                                    <textarea class="form-control" rows="6"
                                        v-model="this.novo_lembrete.descricao"></textarea>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="modal-footer">
                        <button @click="this.adicionarLembrete" class="btn btn-primary btn-block">
                            <i class="flaticon-cancel-12"></i> Adicionar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped></style>

<script>
import moment from "moment";
import { adicionarLembrete, removerLembrete } from '@/modules/coach/processo/processoController'

import { useRoute } from 'vue-router';
import { toast, alert } from "@/components/sweetAlert/index";
import Swal from "sweetalert2";

export default {
    name: "ComponentLembretes",

    methods: {
        openNovoLembrete() {
            this.novo_lembrete.descricao = null;
            // eslint-disable-next-line
            $("#modalNovoLembrete").modal("show");
        },
        async adicionarLembrete() {

            this.$emit('updateIsLoading', true);

            const dados = {
                descricao: this.novo_lembrete.descricao,
                processo_codigo: this.route.params.codigo,
            }

            const response = await adicionarLembrete(dados);
            if ((response && response.data && response.data.erro) || response.status == 'error' || (response && response.response && response.status != 200)) {
                toast(response.response.data.message || response.message, "error");
                this.$emit('updateIsLoading', false);
                return
            }

            this.$emit('updateIsLoading', false);
            // eslint-disable-next-line
            $("#modalNovoLembrete").modal("hide");

            this.lembrete = response.data;
            alert('Lembrete adicionado com sucesso', '', 'success');
        },

        remove(lembrete_remover) {
            Swal.fire({
                title: "Deseja remover esse lembrete?",
                text: "",
                icon: "question",
                showCancelButton: true,
                cancelButtonText: "Cancelar",
                confirmButtonColor: "#3085d6",
                reverseButtons: true,
                confirmButtonText: 'Remover',
                allowOutsideClick: false,
            }).then(async (result) => {

                if (result.isConfirmed) {

                    this.$emit('updateIsLoading', true);
                    const dados = {
                        codigo: lembrete_remover.codigo,
                        processo: this.route.params.codigo

                    }
                    const response = await removerLembrete(dados);
                    if ((response && response.data && response.data.erro) || response.status == 'error' || (response && response.response && response.status != 200)) {
                        toast(response.response.data.message || response.message, "error");
                        this.$emit('updateIsLoading', true);
                        return
                    }

                    let index = this.lembrete.findIndex((s) => {
                        return s.codigo == dados.codigo
                    });

                    (index != -1) ? this.lembrete.splice(index, 1) : null

                    this.$emit('updateIsLoading', false);
                    alert('Removido com sucesso', '', 'success');
                }
            });
        }

    },

    lembrete_prop: {
        deep: true,
        handler(newLembrete) {
            this.lembrete = newLembrete;
        }
    },

    props: ['lembrete_prop'],
    data() {
        return {
            moment: moment,
            lembrete: this.lembrete_prop,
            novo_lembrete: {
                descricao: null
            },
            route: useRoute(),

        };
    },

}

</script>