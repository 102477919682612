import AuthCoachView from './view/LoginCoachView';

const routes = [{
        path: '/coach/login',
        name: 'coach-login',
        component: AuthCoachView,
        meta: {
            requiresAuth: false
        },
    },

]

export default routes;