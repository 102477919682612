<template>
  <div>
    <ComponentLoadingPage :message="messageLoading" v-bind:isLoading="isLoading" />
    <ComponentNavBar />
    <!--  BEGIN NAVBAR  -->
    <ComponentNavBar2 :local="local" />
    <!--  END NAVBAR  -->

    <!--  BEGIN MAIN CONTAINER  -->
    <div class="main-container" id="container">
      <div class="overlay"></div>
      <div class="search-overlay"></div>
      <ComponentMenu />
      <!--  BEGIN CONTENT AREA  -->
      <div id="content" class="main-content">
        <div class="layout-px-spacing">
          <div class="row" id="cancel-row">
            <div class="col-lg-12 layout-spacing layout-top-spacing">
              <div class="statbox widget box box-shadow">
                <ComponentTab :config_prop="config" :validator_prop="validations" :v_prop="v$" />
                <div class="col-lg-12 layout-spacing layout-top-spacing">
                  <button @click="update" class="btn btn-success">Salvar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
    <ComponentFooter />
  </div>
</template>

<script>
import ComponentMenu from "@/modules/coach/components/menu/ComponentMenu.vue";
import ComponentNavBar from "@/modules/coach/components/navbar/ComponentNavBar.vue";
import ComponentNavBar2 from "@/modules/coach/components/navbar/ComponentNavBar2.vue";
import ComponentFooter from "@/modules/coach/components/footer/ComponentFooter.vue";

import moment from "moment";

// Validação
//import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import mensagem from "@/components/validacaoInput/mensagem";

// Loading
import ComponentLoadingPage from "@/components/loading/ComponentLoadingPage.vue";

// Componentes


import { getConfiguracoes, updateConfiguracoes } from "../configController";

// import mensagem from "@/components/validacaoInput/mensagem";
//import Swal from "sweetalert2";
import { toast, alert } from "@/components/sweetAlert/index";

import ComponentTab from "../components/ComponentTab.vue";

import("@/assets/scss/app.css");

import("../css/index.css");

import { useVuelidate } from "@vuelidate/core";
export default {
  name: "ConfigView",


  created() {
    this.init();
  },

  setup() {
    return { v$: useVuelidate() };
  },

  methods: {

    async update() {

      const result = await this.v$.config.$validate();
      if (!result) {
        return;
      }

      this.messageLoading = 'Atualizando...'
      this.isLoading = true;
      let encontrado = false;
      for (const element of this.config.dias_semana) {
        if (element.ativo) {
          encontrado = !encontrado;
          break;
        }
      }

      if (!encontrado) {
        toast('Informe pelo menos 1 dia da semana', "error");
        this.isLoading = false;
        return;
      }

      let data = {
          dias_semana : JSON. stringify(this.config.dias_semana),
          tempo_sessao : this.config.tempo_sessao
      }

      let response = await updateConfiguracoes(data);
      if ((response && response.data && response.data.erro) || response.status == 'error' ) {
        toast(response.response.data.message, "error");
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
      alert(response.message, '', 'success');
    },

    async init() {
      this.isLoading = true;
      let response = await getConfiguracoes();
      if (response && response.data && response.data.erro) {
        toast("Erro a localizar as configurações", "warning");
        this.isLoading = false;
        return;
      }
      try {
        let dias_semana = JSON.parse(response.data.config.atendimento_semana);
        this.config.dias_semana = dias_semana;
      } catch (e) {
        toast("Erro ao carregar informações dos dias da semana", "warning");
        this.isLoading = false;
      }
      this.config.tempo_sessao = response.data.config.tempo_sessao_min;
      this.isLoading = false;
    },
  },

  validations: {
    config: {
      tempo_sessao: {
        required: helpers.withMessage(mensagem.required, required),
        lazy: true
      }
    },
  },

  data() {
    return {
      messageLoading: "Localizando configurações...",
      local: "Coach | Configurações",
      moment: moment,
      isLoading: false,
      config: {
        tempo_sessao: '',
      },
      v_prop: this.v$,
      validations: this.validations
    };
  },

  components: {
    ComponentMenu,
    ComponentNavBar,
    ComponentNavBar2,
    ComponentFooter,
    ComponentLoadingPage,
    ComponentTab,
  },
};
</script>
  