import store from '@/store'
import http from '@/components/http';
import axios from 'axios';


async function getAlertasCoach() {
    try {
        const response = await http.post('/coach/alertas');
        if (response && response.status && response.status == 200) {
            store.dispatch('coach/ActionSetAlerta', response.data.data);
        }
        return true;
    } catch (error) {
        return error;
    }
}

async function getEnderecoCEP(cep) {
    if (!cep) {
        return
    }
    try {
        const response = await axios('https://viacep.com.br/ws/' + cep + '/json/');
        return response;
    } catch (error) {
        return error;
    }
}

async function getUF() {
    try {
        const response = await http.get('/endereco/estados');
        if (response && response.data && response.data.data && response.status == 200) {
            return response.data.data;
        }
    } catch (error) {
        return error;
    }
}

async function getCidades(uf) {
    if (!uf) {
        return [];
    }
    try {
        var data = {
            'uf': uf
        }
        const response = await http.post('/endereco/cidades', data);
        if (response && response.data && response.data.data && response.status == 200) {
            return response.data.data;
        } else {
            return [];
        }
    } catch (error) {
        return error;
    }
}

function getCodigoIGBEEstadoSigla(sigla) {
    const ibgeEstados = {
        'AC': 12,
        'AL': 27,
        'AM': 13,
        'AP': 16,
        'BA': 29,
        'CE': 23,
        'DF': 53,
        'ES': 32,
        'GO': 52,
        'MA': 21,
        'MG': 31,
        'MS': 50,
        'MT': 51,
        'PA': 15,
        'PB': 25,
        'PE': 26,
        'PI': 22,
        'PR': 41,
        'RJ': 33,
        'RN': 24,
        'RO': 11,
        'RR': 14,
        'RS': 43,
        'SC': 42,
        'SE': 28,
        'SP': 35,
        'TO': 17,
        '': ''
    };
    return ibgeEstados[sigla.toUpperCase()] || null;
}

function getSiglaEstadoCodigo(codigo) {
    const ibgeEstados = {
        11: 'RO',
        12: 'AC',
        13: 'AM',
        14: 'RR',
        15: 'PA',
        16: 'AP',
        17: 'TO',
        21: 'MA',
        22: 'PI',
        23: 'CE',
        24: 'RN',
        25: 'PB',
        26: 'PE',
        27: 'AL',
        28: 'SE',
        29: 'BA',
        31: 'MG',
        32: 'ES',
        33: 'RJ',
        35: 'SP',
        41: 'PR',
        42: 'SC',
        43: 'RS',
        50: 'MS',
        51: 'MT',
        52: 'GO',
        53: 'DF',
        '': '',
        null: null
    };
    return ibgeEstados[codigo] || null;
}

async function getPais() {
    try {
        const response = await http.get('/endereco/pais');
        if (response && response.data && response.data.data && response.status == 200) {
            return response.data.data;
        }
    } catch (error) {
        return error;
    }
}

async function getCargos() {
    try {
        const response = await http.get('/cargo');
        if (response && response.data && response.data.data && response.status == 200) {
            return response.data.data;
        }
    } catch (error) {
        return error;
    }
}

async function getTipoEmpregos() {
    try {
        const response = await http.get('/tipo-emprego');
        if (response && response.data && response.data.data && response.status == 200) {
            return response.data.data;
        }
    } catch (error) {
        return error;
    }
}

async function updateCoach(coach) {
    try {

        const response = await http.post('/coach/update-perfil', coach, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (response && response.data && response.status == 200) {
            return response.data;
        }

    } catch (error) {
        return error;
    }
}

function getLocalStorage(destino) {
    var array = {
        'coach_formacao': 'app/coach/formacao'
    }
    return array[destino]
}


async function downloadFile(documento) {
    try {
        await http({
                url: 'documento/download?documento_codigo=' + documento.documento_codigo +
                    '&destino=' + getLocalStorage(documento.destino) +
                    '&tipo_documento_codigo=' + documento.tipo_documento_codigo +
                    '&perfil=coach' +
                    '&tipo_documento=' + documento.tipo_documento,

                responseType: 'blob' // Especifica o tipo de resposta como blob
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', documento.nome_documento);
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => {
                console.error(error);
            });
    } catch (error) {
        return error;
    }
}

async function getPerfil() {

    try {
        const response = await http.get('/coach/get-perfil')
        if (response && response.data && response.status == 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}

async function newCoach(coach) {
    try {
        const response = await http.post('/register/coach', coach)
        if (response && response.data && response.status == 200) {
            return response.data;
        }
    } catch (error) {
        return error;
    }
}




export {
    getAlertasCoach,
    getEnderecoCEP,
    getUF,
    getCidades,
    getCodigoIGBEEstadoSigla,
    getPais,
    getCargos,
    getTipoEmpregos,
    updateCoach,
    getPerfil,
    getSiglaEstadoCodigo,
    downloadFile,
    newCoach

}