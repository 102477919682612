<template>
    <div>

        <div class="row mb-3 mt-3">
            <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                <div class=" widget-card-four">
                    <div class="widget-content">
                        <div class="w-content">
                            <div class="">
                                <div class="w-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-user-check">
                                        <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                                        <circle cx="8.5" cy="7" r="4"></circle>
                                        <polyline points="17 11 19 13 23 9"></polyline>
                                    </svg>
                                </div>
                            </div>
                            <div class="w-info">
                                <h6 class="value">{{ cabecalho.total_cliente }}</h6>
                                <p class="">Cliente(s)</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-md-6 col-sm-12 mb-2">
                <div class="widget-card-four">
                    <div class="widget-content">
                        <div class="w-content">
                            <div class="">
                                <div class="w-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-refresh-cw">
                                        <polyline points="23 4 23 10 17 10"></polyline>
                                        <polyline points="1 20 1 14 7 14"></polyline>
                                        <path d="M3.51 9a9 9 0 0 1 14.85-3.36L23 10M1 14l4.64 4.36A9 9 0 0 0 20.49 15">
                                        </path>
                                    </svg>
                                </div>
                            </div>
                            <div class="w-info">
                                <h6 class="value">{{ cabecalho.processo_finalizado }} %</h6>
                                <p class="">Finalizados</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-md-6 col-sm-12 mb-2 text-right align-items-center">
                <button class="btn btn-success"> <i class="fa fa-check"> </i> Finalizar</button>
            </div>
          
        </div>

    </div>
</template>

<script>

export default {
    name: "ComponentCabecalho",
    watch: {
        cabecalho_prop: {
            deep: true,
            handler(newCabecalho) {
                this.cabecalho = newCabecalho;
            }
        }
    },
    methods:{
        
    },  
    props: ['cabecalho_prop'],
    data() {
        return {
            cabecalho: this.cabecalho_prop,
        };
    },


}

</script>