import Swal from 'sweetalert2';

function alert(title, text, type) {
    Swal.fire({
        title: title,
        text: text,
        icon: type,
        confirmButtonText: 'Continuar'
    });
}


function toast(title, type) {
    Swal.fire({
        showConfirmButton: false,
        timerProgressBar: true,
        animation: true,
        title: title,
        toast: true,
        timer: 3000,
        width: '320',
        position: 'top-right',
        icon: type,
        didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
}

export { toast, alert }