<template>
    <div class="col-12">
        <div class="row mb-3">
            Redes Sociais
        </div>
        <div class="row">
            <div class="col-xl-6 col-md-12 col-sm-6 col-xs-12 mb-1">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="">
                            <li class="list-inline-item">
                                <div class="social-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-instagram">
                                        <rect x="2" y="2" width="20" height="20" rx="5" ry="5">
                                        </rect>
                                        <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z">
                                        </path>
                                        <line x1="17.5" y1="6.5" x2="17.51" y2="6.5">
                                        </line>
                                    </svg>
                                </div>
                            </li>
                        </span>
                    </div>
                    <input type="text" class="form-control form-control-sm" v-model="localCoach.rede_social.instagram"
                        placeholder="Instagram">
                </div>
            </div>
            <div class="col-xl-6 col-md-12 col-sm-6 col-xs-12 mb-1">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <li class="list-inline-item">
                                <div class="social-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-facebook">
                                        <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z">
                                        </path>
                                    </svg>
                                </div>
                            </li>
                        </span>
                    </div>
                    <input type="text" placeholder="Facebook" class="form-control form-control-sm"
                        v-model="localCoach.rede_social.facebook">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-6 col-md-12 col-sm-6 col-xs-12 mb-1">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="">
                            <li class="list-inline-item">

                                <div class="social-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-linkedin">
                                        <path
                                            d="M16 8a6 6 0 0 1 6 6v7h-4v-7a2 2 0 0 0-2-2 2 2 0 0 0-2 2v7h-4v-7a6 6 0 0 1 6-6z">
                                        </path>
                                        <rect x="2" y="9" width="4" height="12"></rect>
                                        <circle cx="4" cy="4" r="2"></circle>
                                    </svg>
                                </div>

                            </li>
                        </span>
                    </div>
                    <input type="text" class="form-control form-control-sm" v-model="localCoach.rede_social.linkedin"
                        placeholder="Linkedin">
                </div>
            </div>
            <div class="col-xl-6 col-md-12 col-sm-6 col-xs-12 mb-1">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text">
                            <li class="list-inline-item">
                                <div class="social-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-twitter">
                                        <path
                                            d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z">
                                        </path>
                                    </svg>
                                </div>
                            </li>
                        </span>
                    </div>
                    <input type="text" placeholder="Twitter" class="form-control form-control-sm"
                        v-model="localCoach.rede_social.twitter">
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-xl-6 col-md-12 col-sm-6 col-xs-12 mb-1">
                <div class="input-group">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="">
                            <li class="list-inline-item">
                                <div class="social-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" class="feather feather-youtube">
                                        <path
                                            d="M22.54 6.42a2.78 2.78 0 0 0-1.94-2C18.88 4 12 4 12 4s-6.88 0-8.6.46a2.78 2.78 0 0 0-1.94 2A29 29 0 0 0 1 11.75a29 29 0 0 0 .46 5.33A2.78 2.78 0 0 0 3.4 19c1.72.46 8.6.46 8.6.46s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-2 29 29 0 0 0 .46-5.25 29 29 0 0 0-.46-5.33z">
                                        </path>
                                        <polygon points="9.75 15.02 15.5 11.75 9.75 8.48 9.75 15.02">
                                        </polygon>
                                    </svg>
                                </div>
                            </li>
                        </span>
                    </div>
                    <input type="text" class="form-control form-control-sm" v-model="localCoach.rede_social.youtube"
                        placeholder="Youtube">
                </div>
            </div>
        </div>
        <br>
        <div class="row mb-2">
            Configurações
        </div>
        <div class="row">
            <div class="col-xl-4 col-md-6 col-sm-6 col-xs-6  mb-2">
                <div class="n-chk">
                    <label class="new-control new-checkbox checkbox-primary">
                        <input type="checkbox" class="new-control-input" v-model="localCoach.configuracoes.aceita_cartao"
                            checked="false">
                        <span class="new-control-indicator"></span>Aceita cartão
                    </label>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-6 col-xs-6  mb-2">
                <div class="n-chk">
                    <label class="new-control new-checkbox checkbox-primary">
                        <input type="checkbox" class="new-control-input" v-model="localCoach.configuracoes.desconto_a_vista"
                            checked="false">
                        <span class="new-control-indicator"></span>Desconto à vista
                    </label>
                </div>
            </div>
            <div class="col-xl-4 col-md-6 col-sm-6 col-xs-6 mb-2">
                <div class="n-chk">
                    <label class="new-control new-checkbox checkbox-primary">
                        <input type="checkbox" class="new-control-input"
                            v-model="localCoach.configuracoes.coaching_presencial" checked="false">
                        <span class="new-control-indicator"></span>Coaching Presencial
                    </label>
                </div>
            </div>

            <div class="col-xl-4 col-md-6 col-sm-6 col-xs-6  mb-2">
                <div class="n-chk">
                    <label class="new-control new-checkbox checkbox-primary">
                        <input type="checkbox" class="new-control-input" v-model="localCoach.configuracoes.coaching_online"
                            checked="false">
                        <span class="new-control-indicator"></span>Coaching On-line
                    </label>
                </div>
            </div>
        </div>
        <br>
        <div class="row mb-2">
            Idiomas
        </div>
        <div class="row">
            <div class="col-2 mb-2">
                <div class="n-chk">
                    <label class="new-control new-checkbox checkbox-primary">
                        <input type="checkbox" class="new-control-input" v-model="localCoach.idioma.portugues"
                            checked="true">
                        <span class="new-control-indicator"></span><img src="assets/img/idioma/idioma_portugues.png"
                            class="flag-width" style="height: 22px" alt="">
                    </label>
                </div>
            </div>
            <div class="col-2 mb-2">
                <div class="n-chk">
                    <label class="new-control new-checkbox checkbox-primary">
                        <input type="checkbox" class="new-control-input" v-model="localCoach.idioma.ingles" checked="false">
                        <span class="new-control-indicator"></span><img src="assets/img/idioma/idioma_ingles.png"
                            class="flag-width" style="height: 22px" alt="">
                    </label>
                </div>
            </div>
            <div class="col-2 mb-2">
                <div class="n-chk">
                    <label class="new-control new-checkbox checkbox-primary">
                        <input type="checkbox" class="new-control-input" v-model="localCoach.idioma.espanhol"
                            checked="false">
                        <span class="new-control-indicator"></span><img src="assets/img/idioma/idioma_espanhol.png"
                            class="flag-width" style="height: 22px" alt="">
                    </label>
                </div>
            </div>
            <div class="col-2 mb-2">
                <div class="n-chk">
                    <label class="new-control new-checkbox checkbox-primary">
                        <input type="checkbox" class="new-control-input" v-model="localCoach.idioma.italiano"
                            checked="false">
                        <span class="new-control-indicator"></span><img src="assets/img/idioma/idioma_italiano.png"
                            class="flag-width" style="height: 22px" alt="">
                    </label>
                </div>
            </div>
            <div class="col-2 mb-2">
                <div class="n-chk">
                    <label class="new-control new-checkbox checkbox-primary">
                        <input type="checkbox" class="new-control-input" v-model="localCoach.idioma.alemao" checked="false">
                        <span class="new-control-indicator"></span><img src="assets/img/idioma/idioma_alemao.png"
                            class="flag-width" style="height: 22px" alt="">
                    </label>
                </div>
            </div>


        </div>



    </div>
</template>

<script>

import moment from 'moment'
export default {
    name: "ComponentDadosGerais",

    mounted() { },
    computed: {},
    props:
        ['coach'],

    data() {
        return {
            moment: moment,
            localCoach: this.coach

        };
    },
    components: {},
};
</script>