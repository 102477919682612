import { createApp } from 'vue';

import VueMask from '@devindex/vue-mask'; // <-- ADD THIS LINE

import App from './App.vue';
import router from './router';
import store from './store';

// Axios
import axios from 'axios';
import VueAxios from 'vue-axios';

// Vuex
import Vuex from 'vuex';

// VueSwwt Alert
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

// Loading
import { LoadingPlugin } from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import './assets/scss/app.css';

import Select2 from 'vue3-select2-component';


var production = true;
// Define a URL padrão da API (tem a mesma configuração o http.js dos componentes )
(production) ? axios.defaults.baseURL = 'https://servicestecnocoaching.com.br/index.php/api/v1/' : axios.defaults.baseURL = 'http://127.0.0.1:8000/api/v1/'

// DataTable 
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';


// Tooltip
import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'

// Editor
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import VueApexCharts from "vue3-apexcharts";


import Swal from 'sweetalert2';

import UUID from 'vue-uuid'

const app = createApp(App)
    .use(store)
    .use(router)
    .use(VueAxios, axios)
    .use(VueSweetalert2)
    .use(Vuex)
    .use(LoadingPlugin)
    .use(VueMask)
    .component('Select2', Select2)
    .use(VueApexCharts)
    //.component('apexchart', VueApexCharts)
    .use(
        VueTippy, {
        directive: 'tippy', // => v-tippy
    }
    ).component('QuillEditor', QuillEditor)
    .use(UUID)
    .component('EasyDataTable', Vue3EasyDataTable);

app.config.globalProperties.$swal = Swal

app.mount('#app')