import state from './state';

export const getUser = () => {
    return state.user;
}

export const getToken = () => {
    return state.token;
}


/* export function getUser(){
    return state.user;
}

export function getToken(){
    return state.token;
} */