import { toast } from "@/components/sweetAlert/index";
import { ActionAuth } from "./store/actions";
import router from '@/router';
import store from '@/store'


async function loginCoach(form) {


    const response = await ActionAuth('', form)
    if (response && response.status && response.status == 200) {

        setAuthLocalStorage(response.data);

        store.dispatch('auth/ActionSetUser', response.data.user);
        store.dispatch('auth/ActionSetToken', response.data.authorization.token);

        toast(`Bem vindo de volta, ${response.data.user.nome}`, 'success');
        router.push('/coach/dashboard')

    } else {
        if (response.response && response.response.data && response.response.data.status == 'error') {
            toast(response.response.data.message, response.response.data.status);

            return;
        } else {
            toast(response.message, 'error')

            return
        }
    }

}

const setAuthLocalStorage = (payload) => {
    window.localStorage.setItem('_App_Token', payload.authorization.token);
    window.localStorage.setItem('_App_User', (JSON.stringify(payload.user)));
}

const setAuthLocalStorageUser = (payload) => {
    window.localStorage.setItem('_App_User', (JSON.stringify(payload)));
}

const deleteLocalStorage = () => {
    window.localStorage.removeItem('_App_Token');
    window.localStorage.removeItem('_App_User');
}

const getTokenLocalStorage = () => {
    return window.localStorage.getItem('_App_Token');
}

const getUserLocalStorage = () => {
    return JSON.parse(window.localStorage.getItem('_App_User'));
}

export {
    loginCoach,
    deleteLocalStorage,
    getTokenLocalStorage,
    getUserLocalStorage,
    setAuthLocalStorageUser

}