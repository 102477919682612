import { ActionLoadAuth } from "@/modules/auth/store/actions";
import { getTokenLocalStorage, getUserLocalStorage } from '@/modules/auth/authController'

// Função que verifica o token é valido ou se é necessário login
export default async function beforeRouteEnterCoach(to, from, next) {

    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    // pega o local do Auth, se é Coah ou Coachee

    if (!getTokenLocalStorage() || !getUserLocalStorage()) {
        next("/coach/login")
    } else {
        const response = await ActionLoadAuth();
         if (requiresAuth && response.status == 200 && response.data.data) {
            next()
        } else {
            next("/coach/login")
        }
    }

}