<template>
  <div>
    <ComponentNavBar />
    <!--  BEGIN NAVBAR  -->
    <ComponentNavBar2 :local="local" />
    <!--  END NAVBAR  -->

    <!--  BEGIN MAIN CONTAINER  -->
    <div class="main-container" id="container">
      <div class="overlay"></div>
      <div class="search-overlay"></div>

      <ComponentMenu />

      <!--  BEGIN CONTENT AREA  -->
      <div id="content" class="main-content">
        <ComponentAlertaCadastro />
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4 col-xl-3  mb-3">
              <div class="infobox-3" style="width: 95%">
                <div class="info-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-box">
                    <path
                      d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z">
                    </path>
                    <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                    <line x1="12" y1="22.08" x2="12" y2="12"></line>
                  </svg>
                </div>
                <h5 class="info-heading">Processos</h5>

                <div class="row mb-3">
                  <div class="col-12 text-center">
                    <h1 class="font-weight-bold">{{processos}}</h1>
                    <h6>Ativos</h6>
                  </div>
                </div>
                <a class="info-link" href="">+ Informações </a>
              </div>
            </div>
            <div class="col-md-4 col-xl-3  mb-3">
              <div class="infobox-3" style="width: 95%">
                <div class="info-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-flag">
                    <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
                    <line x1="4" y1="22" x2="4" y2="15"></line>
                  </svg>
                </div>
                <h5 class="info-heading">Sessões</h5>

                <div class="row mb-3">
                  <div class="col-12 text-center">
                    <h1 class="font-weight-bold">{{ sessoes }}</h1>
                    <h6>Realizadas</h6>
                  </div>
                </div>
                <a class="info-link" href="">+ Informações </a>
              </div>
            </div>
            <div class="col-md-4 col-xl-3  mb-3">
              <div class="infobox-3" style="width: 95%">
                <div class="info-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-user-check">
                    <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="8.5" cy="7" r="4"></circle>
                    <polyline points="17 11 19 13 23 9"></polyline>
                  </svg>
                </div>
                <h5 class="info-heading">Clientes</h5>

                <div class="row mb-3">
                  <div class="col-12 text-center">
                    <h1 class="font-weight-bold">{{ clientes }}</h1>
                    <h6>Ativos</h6>
                  </div>
                </div>
                <a class="info-link" href="">+ Informações </a>
              </div>
            </div>
            <div class="col-md-4 col-xl-3 mb-3">
              <div class="infobox-3" style="width: 95%">
                <div class="info-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    class="feather feather-users">
                    <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="9" cy="7" r="4"></circle>
                    <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                    <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                  </svg>
                </div>
                <h5 class="info-heading">Proc. em Grupo</h5>

                <div class="row mb-3">
                  <div class="col-12 text-center">
                    <h1 class="font-weight-bold">{{proc_grupo}}</h1>
                    <h6>Realizadas</h6>
                  </div>
                </div>
                <a class="info-link" href="">+ Informações </a>
              </div>
            </div>
          </div>
          <div class="card col-12">
          <div class="row">
            <div class="card col-12"></div>
              <div class="card-body">
                <div class="progress-order">
                  <div class="progress-order-header">
                    <div class="row">
                      <div class="col-md-12 col-sm-12 col-12">
                        <h6>Meus processos ativos</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br><br><br>
        <ComponentFooter />
      </div>
    </div>
  </div>
</template>

<script>
import ComponentMenu from "@/modules/coach/components/menu/ComponentMenu.vue";
import ComponentNavBar from "@/modules/coach/components/navbar/ComponentNavBar.vue";
import ComponentNavBar2 from "@/modules/coach/components/navbar/ComponentNavBar2.vue";
import ComponentFooter from "@/modules/coach/components/footer/ComponentFooter.vue";
import ComponentAlertaCadastro from "@/modules/coach/components/alerta/ComponentAlertaCadastro.vue";

import("@/assets/scss/app.css");

export default {
  name: "DashboardView",
  mounted() { },
  computed: {},
  data() {
    return {
      local: "Dashboard",

      processos: 0,
      sessoes : 0,
      clientes : 0,
      proc_grupo : 0

    };
  },
  components: {
    ComponentMenu,
    ComponentNavBar,
    ComponentNavBar2,
    ComponentFooter,
    ComponentAlertaCadastro,
  },
};
</script>
