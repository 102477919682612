<template>
  <div class=" col-12  animated-underline-content">
    <ul class="nav nav-tabs mb-3" id="animateLine" role="tablist">
      <li class="nav-item">
        <a class="nav-link active" data-toggle="tab" href="#teste" role="tab" aria-controls="animated-underline-profile"
          aria-selected="false">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
            stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
            class="feather feather-award">
            <circle cx="12" cy="8" r="7"></circle>
            <polyline points="8.21 13.89 7 23 12 20 17 23 15.79 13.88"></polyline>
          </svg>
          Sessões
        </a>
      </li>
    </ul>

    <div class="tab-content" id="animateLineContent-4">
      <div class="tab-pane fade show active" id="teste" role="tabpanel" aria-labelledby="animated-underline-home-tab">
        <div class="col-12">
          <div class="row mb-2">
            <div class="col-12">
              <label>Dias da semana
                <small class="text-danger">*</small></label>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12">
              <p>
                Quais dias da semana será possível realizar o agendamento
                das sessões?
              </p>
            </div>
          </div>
          <div class="row mb-2">
            <div v-for="dia in this.config.dias_semana" :key="dia.codigo"
              class="col-xl-3 col-md-6 col-sm-6 col-xs-6 mb-2">
              <div class="n-chk">
                <label class="new-control new-checkbox checkbox-primary">
                  <input :checked="dia.ativo" type="checkbox" class="new-control-input" v-model="dia.ativo" />
                  <span class="new-control-indicator"></span>{{ dia.descricao }}
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-xl-4 col-md-6 col-sm-6 col-xs-6 mb-2">
              <div class="form-group">
                <label>Tempo de cada Sessão (em minutos)
                  <small class="text-danger">*</small></label>
                <input type="text" maxlength="3" class="form-control form-control-sm" v-mask="'000'"
                  v-model.number="this.config.tempo_sessao" />
                  <ComponenteValidacao :errorsValidation="this.v_prop.config.tempo_sessao.$errors" /> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <br />
  </div>
</template>
<script>

// Validação
import ComponenteValidacao from "@/components/validacaoInput/ComponentValidacao";

export default {
  name: "ComponentTab",
  watch: {
    config_prop: {
      deep: true,
      handler(newConfig) {
        this.config = newConfig;
      }
    }
  },
  props: ['config_prop', 'validator_prop', 'v_prop'],
  data() {
    return {
      config: this.config_prop,
      validations: this.validator_prop,
     
    };
  },
  components: {
    ComponenteValidacao
  },
};
</script>