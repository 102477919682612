import CadastroView from './coach/view/CadastroView';
import MeuPerfilView from './coach/view/MeuPerfilView';
import CoachConfigView from './config/view/ConfigView';

import CadastroClienteView from './cliente/view/CadastroClienteView';

import ProcessoView from './processo/view/ProcessoView';
import ProcessoShowView from './processo/view/ProcessoShowView';
import DashboardView from './dashboard/view/DashboardView';

import SessaoShoView from './sessao/view/SessaoShowView'
import FerramentaView from './ferramenta/view/FerramentaView'
import CadastroFerramentaView from './ferramenta/view/CadastroFerramentaView'


import beforeRouteEnterCoach from '@/router/beforeRoute'

const local = 'coach';

const routes = [

    //################### COACH ###############
    {
        path: '/coach/cadastro',
        name: 'coach-cadastro',
        component: CadastroView,
        meta: {
            requiresAuth: false,
            local: local
        }
    },
    {
        path: '/coach/dashboard',
        name: 'coach-dashboard',
        component: DashboardView,
        meta: {
            requiresAuth: true,
        },
        beforeEnter: beforeRouteEnterCoach
    },
    {
        path: '/coach/meu-perfil',
        name: 'coach-meu-perfil',
        component: MeuPerfilView,
        meta: {
            requiresAuth: true,
        },
        beforeEnter: beforeRouteEnterCoach
    },

    {
        path: '/coach/config',
        name: 'coach-config',
        component: CoachConfigView,
        meta: {
            requiresAuth: true,
        },
        beforeEnter: beforeRouteEnterCoach
    },



    //################### CLIENTE ###############

    {
        path: '/coach/cliente/cadastro',
        name: 'coach-cliente-cadastro',
        component: CadastroClienteView,
        meta: {
            requiresAuth: true,
        },
        beforeEnter: beforeRouteEnterCoach
    },



    //################### PROCESO ###############

    {
        path: '/coach/processo',
        name: 'coach-processo',
        component: ProcessoView,
        meta: {
            requiresAuth: true,
        },
        beforeEnter: beforeRouteEnterCoach
    },

    {
        path: '/coach/processo/:codigo',
        name: 'coach-processo-show',
        component: ProcessoShowView,
        meta: {
            requiresAuth: true,
        },
        beforeEnter: beforeRouteEnterCoach
    },

    //###############  SESSAO  ###################

    {
        path: '/coach/sessao/:sessao_codigo/:processo_codigo',
        name: 'coach-sessao-show',
        component: SessaoShoView,
        meta: {
            requiresAuth: true,
        },
        beforeEnter: beforeRouteEnterCoach
    },

     //###############  FERRAMENTA  ###################

    {
        path: '/coach/ferramenta/cadastro',
        name: 'ferramenta-cadastro',
        component: CadastroFerramentaView,
        meta: {
            requiresAuth: true,
        },
        beforeEnter: beforeRouteEnterCoach
    },
    {
        path: '/coach/ferramenta',
        name: 'ferramenta',
        component: FerramentaView,
        meta: {
            requiresAuth: true,
        },
        beforeEnter: beforeRouteEnterCoach
    }

    

]

export default routes;