<template>
  <div>
    <ComponentLoadingPage v-bind:isLoading="isLoading" />
    <ComponentNavBar />
    <!--  BEGIN NAVBAR  -->
    <ComponentNavBar2 :local="local" />
    <!--  END NAVBAR  -->

    <!--  BEGIN MAIN CONTAINER  -->
    <div class="main-container" id="container">
      <div class="overlay"></div>
      <div class="search-overlay"></div>

      <ComponentMenu />
      <ComponentModalNovo ref="componentModalNovo" :ferramentaProp="ferramenta" />
      <!--  BEGIN CONTENT AREA  -->
      <div id="content" class="main-content">
        <div class="layout-px-spacing">
          <div class="row" id="">
            <div class="col-lg-12 layout-spacing layout-top-spacing">
              <div class="statbox widget box box-shadow">
                <div class="widget-header" style="padding-left: 15px; padding-right: 15px">
                  <div class="row">
                    <div class="col-12 mb-2">
                      <h4 style="padding: 0px; margin-left: 20px; margin-top: 20px">
                        Nova Ferramenta
                      </h4>
                    </div>
                  </div>

                  <div class="col-12 mb-2">
                    <div class="row">
                      <div class="col-xl-4 col-md-6 col-sm-6 col-xs-12">
                        <div class="form-group">
                          <label>Título <small class="text-danger">*</small></label>
                          <input type="text" @blur="updateFerramenta('titulo')" v-model="this.ferramenta.titulo" class="form-control form-control-sm"
                            maxlength="200" />
                            <ComponenteValidacao :errorsValidation="v$.ferramenta.titulo.$errors" />
                        </div>
                      </div>
                      <div class="col-xl-8 col-md-6 col-sm-6 col-xs-12">
                        <div class="form-group">
                          <label>Subtítulo </label>
                          <input type="text" v-model="ferramenta.subtitulo" @blur="updateFerramenta('subtitulo')" class="form-control form-control-sm"
                            maxlength="200" />
                           
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12 mb-2">
                      <div class="col-12">
                        <div class="form-group">
                          <label>Descrição <small class="text-danger">*</small></label>
                          <textarea v-model="ferramenta.descricao"  @blur="updateFerramenta('descricao')" class="form-control form-control-sm"
                            maxlength="500"></textarea>
                           <ComponenteValidacao :errorsValidation="v$.ferramenta.descricao.$errors" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="ferramenta.codigo" class="row p-2 pb-3">
                    <button @click="openModalNovoTipoFerramenta" class="btn btn-success btn-block pt-2">
                      <i class="flaticon-cancel-12"></i> Adicionar Atividade
                    </button>
                  </div>
                  <div v-if="!ferramenta.codigo" class="row p-2 pb-3">
                    <button @click="storeFerramenta" class="btn btn-primary btn-block pt-2">
                      <i class="flaticon-cancel-12"></i> Criar Ferramenta
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ComponentLista ref="componetLista" :tipo="view" :ferramentaProp="ferramenta" />
        </div>
        <ComponentFooter />
      </div>
    </div>
  </div>
</template>

<script>
import ComponentMenu from "@/modules/coach/components/menu/ComponentMenu.vue";
import ComponentNavBar from "@/modules/coach/components/navbar/ComponentNavBar.vue";
import ComponentNavBar2 from "@/modules/coach/components/navbar/ComponentNavBar2.vue";
import ComponentFooter from "@/modules/coach/components/footer/ComponentFooter.vue";
import ComponentModalNovo from "@/modules/coach/ferramenta/components/novo/ComponentModalNovo.vue";
import ComponentLista from "@/modules/coach/ferramenta/components/lista/ComponentLista.vue";

import moment from "moment";

// Loading
import ComponentLoadingPage from "@/components/loading/ComponentLoadingPage.vue";
import("../css/index.css");
import("@/assets/scss/app.css");

// Validação
import { useVuelidate } from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import ComponenteValidacao from "@/components/validacaoInput/ComponentValidacao";
import mensagem from "@/components/validacaoInput/mensagem";

//import Swal from "sweetalert2";
import { toast } from "@/components/sweetAlert/index";

import "vue3-form-wizard/dist/style.css";
import {
  storeFerramenta,
  updateFerramenta
} from "../ferramentaController.js";

export default {
  name: "CadastroFerramentaView",

  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  mounted() {

  },

  validations: {
    ferramenta: {
      titulo: {
        required: helpers.withMessage(mensagem.required, required),
      },
      descricao: {
        required: helpers.withMessage(mensagem.required, required),
      },
    },
  },

  created() { },

  methods: {
    novaFerramenta(){
      
    },
    openModalNovoTipoFerramenta() {
      this.$refs.componentModalNovo.novoTipoFerramentaStep1();
    },

    async storeFerramenta(){
      const result = await this.v$.ferramenta.$validate();
      if (!result) {
        return;
      }

      
      this.isLoading = true;
      const response = await storeFerramenta(this.ferramenta);
      console.log(response)

      if (response && response.response && response.response.data) {
        toast(response.response.data.message, "error");
        this.isLoading = false;
        return;
      }
      this.backup = {
          titulo: this.ferramenta.titulo.slice(),
          subtitulo: this.ferramenta.subtitulo.slice(),
          descricao: this.ferramenta.descricao.slice()
    };

      this.isLoading = false;
      // eslint-disable-next-line

      if(response && response.data){
        this.ferramenta.codigo = response.data.codigo;
        this.ferramenta.id = response.data.id;
      }
    },

    

    
    async updateFerramenta(column){
     
      if(!this.ferramenta.codigo && !this.ferramenta.id){
        return
      }

      this.isLoading = true;
      let dados = {
        codigo: this.ferramenta.codigo,
        id: this.ferramenta.id,
        column: column,
        data: (column == 'titulo') ? this.ferramenta.titulo : (column == 'subtitulo') ? this.ferramenta.subtitulo : (column == 'descricao') ? this.ferramenta.descricao : null 
      }

      const response = await updateFerramenta(dados);
     
      if (response && response.response && response.response.data) {
        toast(response.response.data.message, "error");
        this.isLoading = false;
        this.ferramenta.titulo = this.backup.titulo;
        this.ferramenta.subtitulo = this.backup.subtitulo;
        this.ferramenta.descricao = this.backup.descricao;

        return;
      }


      this.backup = {
          titulo: this.ferramenta.titulo.slice(),
          subtitulo: this.ferramenta.subtitulo.slice(),
          descricao: this.ferramenta.descricao.slice()
    };

      this.isLoading = false;
      // eslint-disable-next-line

      if(response && response.data){
        this.ferramenta.codigo = response.data.codigo;
        this.ferramenta.id = response.data.id;
      }
    }
  },

  computed: {},
  data() {
    return {
      local: "Coach | Ferramenta",
      moment: moment,
      isLoading: false,
      backup:{
        titulo: '',
        subtitulo: '',
        descricao: '',
      },
      ferramenta: {
        id: null,
        codigo: null,
        titulo: '',
        subtitulo: '',
        descricao: '',
        tipo: []
      }

    };
  },

  components: {
    ComponentMenu,
    ComponentNavBar,
    ComponentNavBar2,
    ComponentFooter,
    ComponentLoadingPage,
    ComponentModalNovo,
    ComponentLista,
    ComponenteValidacao
  },
};
</script>
