import http from '@/components/http';

export const getAlertasCoach = async() => {
    try {
        const response = await http.post('/coach/alertas');
        return response
    } catch (error) {
        return false;
    }
}

export const ActionSetAlerta = ({ commit }, payload) => {
    commit('setAlertasStore', payload)
}